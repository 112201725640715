import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Icon from "@mui/material/Icon";
import MuiTooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { fetchGamifycationStatsPolicies } from "features/company/companiesActions";
import { getClonedTemplatesFromGlobal } from "features/company/templatesActions";
import { createNewTemplateClone } from "features/company/templatesActions";
import { useTheme } from "@mui/material/styles";

// PieChart configurations
import "./charts.css";

import configs from "./configs/policy";
import GamePieChart from "./GamePieChart";

import MDInput from "components/MDInput";
import { showMsg } from "utils/general";
import { createNewPolicy } from "features/company/policiesActions";

import * as Yup from "yup";
// formik components
import { Formik, Form, ErrorMessage, Field } from "formik";
import { Autocomplete } from "@mui/material";

const objInitialValues = {
  txtPolicyTitle: "",
  txtTemplate: { id: "", name: "No template", document: "" },
  txtTemplateTitle: "",
};

const AddNewPolicyFormJWTSchema = Yup.object().shape({
  txtPolicyTitle: Yup.string().required("The Title is required."),
  txtTemplate: Yup.object().shape({
    id: Yup.string(),
  }),
});

const AddNewTemplateFormJWTSchema = Yup.object().shape({
  txtTemplateTitle: Yup.string().required("The Name is required."),
});

function CreateNewPolicyDialog({ open, onClose, onSubmit, objUser, template }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isGlobalTemplate, setIsGlobalTemplate] = useState(false);
  const dispatch = useDispatch();

  const currentClient = useMemo(() => {
    if (objUser.user.role.name == "client_user") {
      return objUser.user.list_of_clients;
    } else {
      let current_client = objUser.user.list_of_clients.find((item) => {
        return item.id === objUser.user.current_client_id;
      });
      return current_client;
    }
  }, [objUser]);

  const onSubmitForm = async (values) => {
    const objPostData = {
      title: values.txtPolicyTitle,
      template: values.txtTemplate.id,
    };
    setIsLoading(true);
    dispatch(createNewPolicy({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          onSubmit(response.data);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
      });
  };

  const onSubmitCloneForm = async (values) => {
    const objPostData = {
      name: values.txtTemplateTitle,
      global_template_id: template.id,
      agreed_to_terms: true,
      acknowledged_copyright: true,
      acknowledged_disclaimer: true,
    };

    setIsLoading(true);
    dispatch(createNewTemplateClone({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg(
            "success",
            "Template imported successfully, now create a Policy"
          );
          objInitialValues.txtTemplate = response.data;

          fetchTemplates();
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError.response);
        showMsg("error", rejectedValueOrSerializedError.response.data.message);
      });
  };

  const fetchTemplates = () => {
    setIsLoadingTemplates(true);
    const templateId = template.id;
    dispatch(getClonedTemplatesFromGlobal({ templateId, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        response.data.templates = response.data.templates.filter((template) => {
          return (
            template.client_id === currentClient.id ||
            template.client_id == null
          );
        });
        if (response.data.templates.length === 0) {
          setIsGlobalTemplate(true);
        } else {
          setIsGlobalTemplate(false);
        }
        setTemplates(response.data.templates);
        setIsLoadingTemplates(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingTemplates(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  useEffect(() => {
    objInitialValues.txtTemplate.id = "";
    objInitialValues.txtTemplate.name = "No template";
    objInitialValues.txtTemplate.document = "";
    objInitialValues.txtTemplateTitle = template.name;

    fetchTemplates();
  }, [template]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      {isLoadingTemplates ? (
        <MDBox sx={{ p: 2 }}>
          <LoadingSpinner />
        </MDBox>
      ) : (
        <>
          {templates != null && !isGlobalTemplate && (
            <>
              <DialogTitle sx={{ pb: 0 }}>Add New Policy</DialogTitle>
              <Formik
                initialValues={objInitialValues}
                validationSchema={AddNewPolicyFormJWTSchema}
                onSubmit={onSubmitForm}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="AddNewPolicyForm" autoComplete="off">
                    <DialogContent>
                      <MDBox mb={0} mt={0}>
                        <Field
                          type="text"
                          label="Title"
                          name="txtPolicyTitle"
                          value={values.txtPolicyTitle}
                          placeholder={"Policy Title"}
                          error={
                            errors.txtPolicyTitle && touched.txtPolicyTitle
                          }
                          success={
                            values.txtPolicyTitle.length > 0 &&
                            !errors.txtPolicyTitle
                          }
                          as={MDInput}
                          variant="standard"
                          fullWidth
                          autoFocus
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="txtPolicyTitle" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>

                      <MDBox mb={0} mt={1}>
                        <Autocomplete
                          disableClearable
                          value={values.txtTemplate}
                          options={[
                            { id: "", name: "No template", document: "" },
                            ...templates,
                          ]}
                          getOptionLabel={(option) =>
                            option ? option.name : ""
                          }
                          renderInput={(params) => (
                            <MDInput
                              {...params}
                              variant="standard"
                              label="Template"
                              fullWidth
                            />
                          )}
                          onChange={(event, value) => {
                            if (value === null) {
                              setFieldValue("txtTemplate", "");
                            } else {
                              setFieldValue("txtTemplate", value);
                            }
                          }}
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="txtTemplate" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </DialogContent>
                    <DialogActions>
                      {isLoading === false ? (
                        <>
                          <MDButton
                            type="submit"
                            color="success"
                            sx={{ padding: "12px 12px" }}
                          >
                            Add
                          </MDButton>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="dark"
                            sx={{ padding: "12px 12px" }}
                            onClick={onClose}
                          >
                            Cancel
                          </MDButton>
                        </>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
          {templates != null && isGlobalTemplate && (
            <>
              <DialogTitle>Import Template to create Policy</DialogTitle>
              <Formik
                initialValues={objInitialValues}
                validationSchema={AddNewTemplateFormJWTSchema}
                onSubmit={onSubmitCloneForm}
              >
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                  <Form id="AddNewTemplateForm" autoComplete="off">
                    <DialogContent>
                      <MDBox mb={0} mt={0}>
                        <Field
                          type="text"
                          label="Name"
                          name="txtTemplateTitle"
                          value={values.txtTemplateTitle}
                          placeholder={"Import Template as"}
                          error={
                            errors.txtTemplateTitle && touched.txtTemplateTitle
                          }
                          success={
                            values.txtTemplateTitle.length > 0 &&
                            !errors.txtTemplateTitle
                          }
                          as={MDInput}
                          variant="standard"
                          fullWidth
                          autoFocus
                        />
                        <MDBox mt={0.75}>
                          <MDTypography
                            component="div"
                            variant="caption"
                            color="error"
                            fontWeight="regular"
                          >
                            <ErrorMessage name="txtTemplateTitle" />
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </DialogContent>
                    <DialogActions>
                      {isLoading === false ? (
                        <>
                          <MDButton
                            type="submit"
                            color="success"
                            sx={{ padding: "12px 12px" }}
                          >
                            Import
                          </MDButton>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="dark"
                            sx={{ padding: "12px 12px" }}
                            onClick={onClose}
                          >
                            Cancel
                          </MDButton>
                        </>
                      ) : (
                        <LoadingSpinner
                          subClass="text-center"
                          color="success"
                          size="lg"
                        />
                      )}
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}

// Selection modal for when Template has 2 or more Policies
function PolicySelectModal({ setTemplate, details, template, detailsAlign }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setTemplate();
  };
  const handleListItemClick = () => {};

  useEffect(() => {
    if (template.name) {
      setOpen(true);
    }
  }, [template]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle>{template.name} Policies</DialogTitle>
        <List sx={{ pt: 0, pb: 1 }}>
          {detailsAlign[template.id].map((policy) => (
            <Link to={`/policies/${policy}`}>
              <ListItem key={policy}>
                <ListItemButton onClick={() => handleListItemClick(policy)}>
                  <MDTypography
                    variant="text"
                    color="success"
                    sx={{ lineHeight: 0 }}
                  >
                    <ListItemText primary={details.policies[policy]} />
                  </MDTypography>
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Dialog>
    </>
  );
}

export function DetailsPopup({
  rmf,
  details,
  detailsAlign,
  detailsAuthenticate,
  detailsAdopt,
  detailsAssess,
  children,
  objUser,
}) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState(null);
  const [openNewPolicyDialog, setOpenNewPolicyDialog] = useState(false);
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitNewPolicyDialog = (policy) => {
    setOpenNewPolicyDialog(false);
    routeDictionary[policy.id] = policy.title;
    navigate(`/policies/${policy.id}`, {
      state: { routeDictionary },
    });
  };
  return (
    <>
      {children({ onClick: () => setOpen(true) })}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={false}
        maxWidth={"md"}
      >
        <DialogTitle>{rmf}'s Details</DialogTitle>
        <DialogContent>
          <table border={1} className="rmfChartDetails">
            <thead>
              <tr>
                <th style={{ padding: "5px 10px" }}>Template</th>
                <th style={{ padding: "5px 10px" }}>Alignment</th>
                <th style={{ padding: "5px 10px" }}>Authorization</th>
                <th style={{ padding: "5px 10px" }}>Adoption</th>
                <th style={{ padding: "5px 10px" }}>Assessment</th>
              </tr>
            </thead>
            <tbody>
              {details?.templates?.map((template) => (
                <tr key={template.id}>
                  <th
                    style={{
                      padding: "10px",
                      lineHeight: "1",
                    }}
                  >
                    {template.global_template_type === "Plus" &&
                    objUser.user.company.subscription_package !== "plus" ? (
                      <>
                        {template.name} <br />
                        <MDTypography
                          variant="text"
                          color="success"
                          sx={{ lineHeight: 0, fontSize: "12px" }}
                        >
                          <Link to={`/billing`}>
                            (Upgrade Subscription Level to Import)
                          </Link>
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        {!Array.isArray(detailsAlign[template.id]) ||
                        detailsAlign[template.id].length <= 0 ? (
                          <>
                            {template.templates.length > 0 &&
                            template.imported.length > 0 ? (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setTemplate(template);
                                  setOpenNewPolicyDialog(true);
                                }}
                              >
                                {template.name} <br />(
                                <MDTypography
                                  variant="text"
                                  color="success"
                                  sx={{ lineHeight: 0, fontSize: "12px" }}
                                >
                                  Create Policy From Template
                                </MDTypography>
                                )
                              </div>
                            ) : (
                              <>
                                {objUser.user.role.name !== "client_user" ? (
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setTemplate(template);
                                      setOpenNewPolicyDialog(true);
                                    }}
                                  >
                                    {template.name} <br />(
                                    <MDTypography
                                      variant="text"
                                      color="success"
                                      sx={{ lineHeight: 0, fontSize: "12px" }}
                                    >
                                      Import Document From Template
                                    </MDTypography>
                                    )
                                  </div>
                                ) : (
                                  <div style={{ cursor: "auto" }}>
                                    {template.name} <br />(
                                    <MDTypography
                                      variant="text"
                                      color="secondary"
                                      sx={{ lineHeight: 0, fontSize: "12px" }}
                                    >
                                      Please contact your MSP to import this
                                      policy
                                    </MDTypography>
                                    )
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {detailsAlign[template.id].length > 1 ? (
                              <span onClick={() => setTemplate(template)}>
                                {template.name} (
                                {detailsAlign[template.id].length})
                              </span>
                            ) : (
                              <>
                                <Link
                                  to={`/policies/${
                                    detailsAlign[template.id][0]
                                  }`}
                                  className="dashboard-rmf-template-link"
                                >
                                  {template.name}
                                </Link>
                              </>
                            )}
                          </>
                        )}{" "}
                      </>
                    )}
                  </th>
                  <td>
                    {!Array.isArray(detailsAlign[template.id]) ||
                    detailsAlign[template.id].length <= 0 ? (
                      <Icon color="error">cancel</Icon>
                    ) : (
                      <MuiTooltip
                        title={detailsAlign[template.id]
                          .map((id) => details?.policies[id] ?? "")
                          .join("<br>\n")}
                        placement="bottom"
                      >
                        <Icon color="success">checkmark</Icon>
                      </MuiTooltip>
                    )}
                  </td>
                  <td>
                    {!Array.isArray(detailsAuthenticate[template.id]) ||
                    detailsAuthenticate[template.id].length <= 0 ? (
                      <Icon color="error">cancel</Icon>
                    ) : (
                      <MuiTooltip
                        title={detailsAuthenticate[template.id]
                          .map((id) => details?.policies[id] ?? "")
                          .join("<br>\n")}
                        placement="bottom"
                      >
                        <Icon color="success">checkmark</Icon>
                      </MuiTooltip>
                    )}
                  </td>
                  <td>
                    {!Array.isArray(detailsAdopt[template.id]) ||
                    detailsAdopt[template.id].length <= 0 ? (
                      <Icon color="error">cancel</Icon>
                    ) : (
                      <MuiTooltip
                        title={detailsAdopt[template.id]
                          .map((id) => details?.policies[id] ?? "")
                          .join("<br>\n")}
                        placement="bottom"
                      >
                        <Icon color="success">checkmark</Icon>
                      </MuiTooltip>
                    )}
                  </td>
                  <td>
                    {!Array.isArray(detailsAssess[template.id]) ||
                    detailsAssess[template.id].length <= 0 ? (
                      <Icon color="error">cancel</Icon>
                    ) : (
                      <MuiTooltip
                        title={detailsAssess[template.id]
                          .map((id) => details?.policies[id] ?? "")
                          .join("<br>\n")}
                        placement="bottom"
                      >
                        <Icon color="success">checkmark</Icon>
                      </MuiTooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            sx={{ padding: "12px 12px" }}
            onClick={handleClose}
          >
            Close
          </MDButton>
        </DialogActions>
      </Dialog>
      {template && !openNewPolicyDialog && (
        <PolicySelectModal
          details={details}
          template={template}
          detailsAlign={detailsAlign}
          setTemplate={() => setTemplate(null)}
        />
      )}
      {openNewPolicyDialog && (
        <CreateNewPolicyDialog
          open={openNewPolicyDialog}
          onClose={() => {
            setTemplate(null);
            setOpenNewPolicyDialog(false);
          }}
          onSubmit={onSubmitNewPolicyDialog}
          objUser={objUser}
          template={template}
        />
      )}
    </>
  );
}

export default function PolicyCharts({ objUser }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const dispatch = useDispatch();
  const getStats = () => {
    setIsLoading(true);
    dispatch(fetchGamifycationStatsPolicies({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setObjGamifycationStats(originalPromiseResult.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getStats();
  }, []);

  const charts = useMemo(() => {
    const pies = [];
    objGamifycationStats?.forEach((objStat) => {
      const dataset = [
        (objStat.align[0] * 90) / objStat.align[1], // Alignment
        (objStat.authenticate[0] * 90) / objStat.authenticate[1], // Authorization
        (objStat.adopt[0] * 90) / objStat.adopt[1], // Adoption
        (objStat.assess[0] * 90) / objStat.assess[1], // Assessment
      ].map((value) => (isNaN(value) ? 0 : value));
      dataset.push(360 - dataset.reduce((acc, cur) => acc + cur, 0));
      const tooltip = [
        `Alignment: ${objStat.align[0]} policies out of ${objStat.align[1]} templates`,
        `Authorization: ${objStat.authenticate[0]} policies approved out of ${objStat.authenticate[1]}`,
        `Adoption: ${objStat.adopt[0]} policies adopted out of ${objStat.adopt[1]}`,
        `Assessment: ${objStat.assess[0]} policies with reminders out of ${objStat.assess[1]}`,
        `Uncompleted`,
      ];

      const { data, options } = configs(
        objStat.rmf,
        dataset,
        tooltip,
        theme.palette
      );
      pies.push(
        <DetailsPopup
          key={objStat.rmf + "_popup"}
          rmf={objStat.rmf}
          details={objStat.details}
          detailsAlign={objStat.align[2] ?? {}}
          detailsAuthenticate={objStat.authenticate[2] ?? {}}
          detailsAdopt={objStat.adopt[2] ?? {}}
          detailsAssess={objStat.assess[2] ?? {}}
          objUser={objUser}
        >
          {({ onClick }) => (
            <GamePieChart
              key={objStat.rmf}
              data={data}
              options={options}
              title={objStat.rmf}
              onClickTitle={onClick}
            />
          )}
        </DetailsPopup>
      );
    });
    return (
      <Grid container spacing={2} justifyContent="space-evenly">
        {pies}
      </Grid>
    );
  }, [objGamifycationStats, theme.palette]);

  return (
    <Card mb={2}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={0} pt={0}>
          <MDBox mt={0}>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="bold"
              variant="h5"
              color="dark"
            >
              Policy Scorecard
            </MDTypography>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="regular"
              color="text"
              variant="h6"
            >
              Click Each Framework Title to View More Details
            </MDTypography>
          </MDBox>
        </MDBox>
        {isLoading ? <LoadingSpinner /> : charts}
      </MDBox>
    </Card>
  );
}
