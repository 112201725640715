import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";

function DashboardButton({
  className,
  disabled,
  btnText,
  btnIcon,
  textColor,
  bgColor,
  component,
  ...rest
}) {
  return (
    <MDButton
      component={component}
      {...rest}
      sx={{ padding: "5px 15px", marginTop: "15px" }}
      color={bgColor}
      disabled={disabled}
      className={className}
    >
      <Icon style={{ transform: "scale(1.3)" }}>{btnIcon}</Icon>
      <MDTypography
        fontWeight="bold"
        sx={{ marginLeft: "0.75rem" }}
        color={textColor}
        variant="button"
      >
        {btnText}
      </MDTypography>
    </MDButton>
  );
}

export default DashboardButton;
