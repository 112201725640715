/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";
import Pagination from "@mui/material/Pagination";

// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";

import DefaultCell from "views/components/TableCells/DefaultCell";
import ClientContactActionBtnTr from "./ClientContactActionBtnTr";
import ClientContactsDataTableData from "./ClientContactsDataTableData";
import { Checkbox, TableSortLabel } from "@mui/material";
import StatusTr from "./StatusTr";

import "./TableEdit.css";

function ClientContactsDataByIdTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  totalCount,
  loadMoreClientContactsById,
  perPage,
  setPerPage,
  _page,
  setPage,
  objClientsContactsData,
  objfetchClientContactsByIdData,
  setObjTableData,
  editTheClientContact,
  deleteTheClientContact,
  isDeletingClientContact,
  setIsDeletingClientContact,
  objSelectedClientContact,
  addToAudienceContact,
  inviteContact,
  resendInvite,
  isInvitingClientContact,
  clickContactKBS,
  setContactsSelectedForBulkAction,
  contactsSelectedForBulkAction,
  isClientUser,
  firstCompanyContact,
  showInviteClientDialog,
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      // setSelectedRows([...selectedRows, row.id]);
      setContactsSelectedForBulkAction([
        ...contactsSelectedForBulkAction,
        row.original.element.id,
      ]);
    } else {
      //  setSelectedRows(selectedRows.filter((rowId) => rowId !== row.id));
      setContactsSelectedForBulkAction(
        contactsSelectedForBulkAction.filter(
          (contactId) => contactId !== row.original.element.id
        )
      );
    }
  };
  const objColumns = [
    ...ClientContactsDataTableData.columns,
    {
      Header: "status",
      accessor: "member",
      Cell: ({ value, column, row }) => (
        <StatusTr value={value} column={column} row={row} />
      ),
    },

    {
      Header: "action",
      accessor: "action",
      // Cell: ({ value, column, row }) => {
      //   return <div onClick={() => console.log(row.original)}>Click Me</div>;
      // },
      Cell: ({ value, column, row }) => (
        <ClientContactActionBtnTr
          value={value}
          column={column}
          row={row}
          objSelectedClientContact={objSelectedClientContact}
          editTheClientContact={editTheClientContact}
          deleteTheClientContact={deleteTheClientContact}
          isDeletingClientContact={isDeletingClientContact}
          setIsDeletingClientContact={setIsDeletingClientContact}
          addToAudienceContact={addToAudienceContact}
          inviteContact={inviteContact}
          resendInvite={resendInvite}
          showInviteClientDialog={showInviteClientDialog}
          isInvitingClientContact={isInvitingClientContact}
          clickContactKBS={clickContactKBS}
          isClientUser={isClientUser}
        />
      ),
    },
  ];
  // const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 25;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "25", "50", "100"];
  const columns = useMemo(
    () => objColumns,
    [
      table,
      isDeletingClientContact,
      objSelectedClientContact,
      isInvitingClientContact,
    ]
    // [table, isActivatingClient, objSelectedClient, isDeletingClient, isDeActivatingClient]
  );
  // const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(
    () => table.rows,
    [
      table,
      objClientsContactsData,
      objSelectedClientContact,
      objfetchClientContactsByIdData,
      isDeletingClientContact,
      isInvitingClientContact,
      // isDeActivatingClient,
    ]
  );

const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  useEffect(() => {
    // fetchData({ pageIndex, pageSize })
    const objRows = [];
    const objClientContactsList = objClientsContactsData;
    for (let index = 0; index < objClientContactsList.length; index++) {
      const element = objClientContactsList[index];
      const objTr = {};
      objTr.id = element.id;
      objTr.firstname = element.first_name;
      objTr.lastname = element.last_name;
      objTr.email = element.email;
      objTr.role =
        element.user !== null && typeof element.user !== undefined
          ? element.user.role.name
          : null;
      objTr.member =
        element.user !== null && typeof element.user !== undefined
          ? true
          : false;
      objTr.first_msp_contact = firstCompanyContact;
      objTr.element = element;
      objRows.push(objTr);
    }
    // setTotalCount(response.data.data.total);
    setObjTableData({
      ...ClientContactsDataTableData,
      rows: objRows,
    });
  }, [objClientsContactsData, objfetchClientContactsByIdData]);

  const onPageSelect = (pageNo) => {
    // alert(pageNo);
    setPage(pageNo);
    loadMoreClientContactsById(perPage, pageNo);
  };
  const handleChange = (event, value) => {
    setPage(value);
    loadMoreClientContactsById(perPage, value);
  };
  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 25), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => {
        //   onPageSelect(Number(option) + 1);
        gotoPage(Number(option));
      }}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  const entriesStart = pageIndex * pageSize + 1;
  const entriesEnd = Math.min((pageIndex + 1) * pageSize, totalCount);

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <MDBox display="flex" alignItems="center">
              <Autocomplete
                disableClearable
                value={pageSize.toString()}
                options={entries}
                onChange={(event, newValue) => {
                  setEntriesPerPage(parseInt(newValue, 10));
                }}
                size="small"
                sx={{ width: "5rem" }}
                renderInput={(params) => <MDInput {...params} />}
              />
              <MDTypography variant="caption" color="secondary">
                &nbsp;&nbsp;entries per page
              </MDTypography>
            </MDBox>
          )}
          {canSearch && (
            <MDBox width="12rem" ml="auto">
              <MDInput
                placeholder="Search..."
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </MDBox>
          )}
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              <DataTableHeadCell padding="checkbox" width="12%">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    indeterminate={
                      // selectedRows.length > 0 && selectedRows.length < rows.length
                      contactsSelectedForBulkAction.length > 0 &&
                      contactsSelectedForBulkAction.length < rows.length
                    }
                    // checked={selectedRows.length === rows.length}
                    checked={
                      contactsSelectedForBulkAction.length === rows.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        // setSelectedRows(rows.map((row) => row.id));
                        setContactsSelectedForBulkAction(
                          rows.map((row) => row.original.element.id)
                        );
                      } else {
                        // setSelectedRows([]);
                        setContactsSelectedForBulkAction([]);
                      }
                    }}
                    inputProps={{ "aria-label": "select all contacts" }}
                  />
                  <span>Select</span>
                </div>
              </DataTableHeadCell>
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}>
                <DataTableBodyCell
                  noBorder={noEndBorder && rows.length - 1 === key}
                >
                  <Checkbox
                    checked={contactsSelectedForBulkAction.includes(
                      row.original.element.id
                    )}
                    onChange={(event) => handleSelectRow(event, row)}
                  />
                </DataTableBodyCell>
                {row.cells.map((cell) => (
                  <>
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  </>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <MDBox mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </MDTypography>
          </MDBox>
        )}
        {/* <MDBox mb={{ xs: 12, sm: 0 }}>
          <Pagination
            count={Math.ceil(totalCount / perPage)}
            page={_page}
            onChange={handleChange}
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          />
        </MDBox> */}
        <MDBox
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "flex-start", sm: "center" }}
          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
        >
          {pageOptions.length > 1 && (
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {canPreviousPage && (
                <MDPagination item onClick={() => previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {renderPagination.length > 6 ? (
                <MDBox width="5rem" mx={1}>
                  <MDInput
                    inputProps={{
                      type: "number",
                      min: 1,
                      max: customizedPageOptions.length,
                    }}
                    value={customizedPageOptions[pageIndex]}
                    onChange={
                      (handleInputPagination, handleInputPaginationValue)
                    }
                  />
                </MDBox>
              ) : (
                renderPagination
              )}
              {canNextPage && (
                <MDPagination item onClick={() => nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          )}
        </MDBox>
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of ClientContactsDataByIdTable
ClientContactsDataByIdTable.defaultProps = {
  entriesPerPage: { defaultValue: 25, entries: [10, 25, 50, 100] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the ClientContactsDataByIdTable
// ClientContactsDataByIdTable.propTypes = {
//   entriesPerPage: PropTypes.oneOfType([
//     PropTypes.shape({
//       defaultValue: PropTypes.number,
//       entries: PropTypes.arrayOf(PropTypes.number),
//     }),
//     PropTypes.bool,
//   ]),
//   canSearch: PropTypes.bool,
//   showTotalEntries: PropTypes.bool,
//   table: PropTypes.objectOf(PropTypes.array).isRequired,
//   pagination: PropTypes.shape({
//     variant: PropTypes.oneOf(["contained", "gradient"]),
//     color: PropTypes.oneOf([
//       "primary",
//       "secondary",
//       "info",
//       "success",
//       "warning",
//       "error",
//       "dark",
//       "light",
//     ]),
//   }),
//   isSorted: PropTypes.bool,
//   noEndBorder: PropTypes.bool,
// };

export default ClientContactsDataByIdTable;
