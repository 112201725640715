import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import { AuthProvider } from "context/useAuth";
import { Provider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import store from "./features/storeConfig/store";

if (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://23f23067a1aa454f8aa4ea7fe5c2a265@o4504555555782656.ingest.sentry.io/4504555934318592",
    integrations: [new BrowserTracing()],
    attachStacktrace: true,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Auth0Provider
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
    cacheLocation="localstorage"
    useRefreshTokens={true}
    scope="idToken"
    responseType="token id_token"
  >
    <BrowserRouter>
      <AuthProvider>
        <MaterialUIControllerProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </MaterialUIControllerProvider>
      </AuthProvider>
    </BrowserRouter>
  </Auth0Provider>
);
