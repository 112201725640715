import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { getTemplates } from "features/company/templatesActions";
import { Fragment } from "react";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import dataTableData from "views/pages/Dashboard/Templates/components/TemplatesDataTableData";
import TemplatesDatatable from "./components/TemplatesDataTable";
import CreateNewTemplateDialog from "./components/createNewTemplateDialog";
import { showMsg } from "utils/general";
import DashboardButton from "views/components/DashboardButtton";

const Templates = (props) => {
  const { objUser } = props;
  let { clientId } = useParams();
  const [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  const [isLoadingClientTemplates, setIsLoadingClientTemplates] =
    useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickNewTemplate = () => {
    setOpenNewTemplateDialog(true);
  };

  const onCloseNewTemplateDialog = () => {
    setOpenNewTemplateDialog(false);
  };

  const onSubmitNewTemplateDialog = (templateId) => {
    setOpenNewTemplateDialog(false);
    showMsg("success", "New Template added successfully");
    navigate(`/clients/${clientId}/templates/${templateId}`, {
      state: { routeDictionary },
    });
  };

  useEffect(() => {
    fetchTemplates(clientId, perPage, page);
  }, []);

  const fetchTemplates = (_id, _per_page, _page) => {
    setIsLoadingClientTemplates(true);
    dispatch(getTemplates({ id: _id, _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientTemplates(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objClientTemplatesList = response.data.templates;
        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.name = element.name;
          objTr.created_by = element?.created_by;
          objTr.last_modified_at = new Date(
            element.last_modified_at
          ).toDateString();
          objTr.element = element;
          objRows.push(objTr);
        }

        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientTemplates(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateNewTemplateDialog
        open={openNewTemplateDialog}
        onClose={onCloseNewTemplateDialog}
        onSubmit={onSubmitNewTemplateDialog}
        clientId={clientId}
        objUser={props.objUser}
      />
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
          <DashboardActionButton
            action={btnOnClickNewTemplate}
            btnText="Add Template"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
          <DashboardButton
            component={Link}
            to={`/clients/${clientId}/audiences/`}
            state={{ routeDictionary }}
            btnText="Manage Audiences"
            btnIcon="peopleAlt"
            textColor="white"
            bgColor="dark"
          />
          <DashboardButton
            component={Link}
            to={`/clients/${clientId}/policies/`}
            state={{ routeDictionary }}
            btnText="Manage Policies"
            btnIcon="description"
            textColor="white"
            bgColor="dark"
          />
          <DashboardButton
            component={Link}
            to={`/clients/${clientId}/contacts/`}
            state={{ routeDictionary }}
            btnText="Manage Contacts"
            btnIcon="person"
            textColor="white"
            bgColor="dark"
          />
        </MDBox>
        <Card>
          {isLoadingClientTemplates === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <TemplatesDatatable
              id={clientId}
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isLoadingClientTemplates ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Client does not have any templates.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Templates;
