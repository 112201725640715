import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import {
  breakMSGraphContactSyncConnectionToDefault,
  getPreviousSyncInformation,
  saveSyncFrequencySettingsForMsGraphContacts,
} from "features/asset-management/msGraphActions";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import borders from "assets/theme/base/borders";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

// const SaveApiKeysFormJWTSchema = Yup.object().shape({
//   customer_id: Yup.string().required("Customer id is Required"),
//   client_id: Yup.string().required("Client id is Required"),
//   client_secrete: Yup.string().required("Client secrete is required"),
// });

const MSGraphAutoContactsImportConfigSection = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const dispatch = useDispatch();

  const [isLoadingSyncInfo, setIsLoadingSyncInfo] = useState(false);
  const [isSavingSyncSettings, setIsSavingSyncSettings] = useState(false);
  const [isBreakingSyncConnection, setIsBreakingSyncConnection] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [syncRecords, setSyncRecords] = useState(null);
  const [isFetchingAssets, setIsFetchingAssets] = useState(false);
  const [isImportingAssets, setIsImportingAssets] = useState(false);
  const [totalAssetsFetched, setTotalAssetsFetched] = useState(0);
  const [open, setOpen] = useState(false);

  const [assetData, setAssetData] = useState([]);

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchPreviousSyncInformation();
  }, []);

  const fetchPreviousSyncInformation = async () => {
    setIsLoadingSyncInfo(true);
    dispatch(getPreviousSyncInformation())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingSyncInfo(false);
        const response = originalPromiseResult;
        setSyncRecords(response.data);
        setSelectedValue(response.data.autoSyncSetting);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingSyncInfo(false);
        showMsg("error", "Recent sync records could not be fetched");
        console.error(rejectedValueOrSerializedError);
      });
  };

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const saveSyncSettings = async () => {
    setIsSavingSyncSettings(true);
    dispatch(
      saveSyncFrequencySettingsForMsGraphContacts({
        selectedValue,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsSavingSyncSettings(false);
        showMsg("success", "Sync frequency settings updated");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsSavingSyncSettings(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error saving changes");
        }
      });
  };

  const breakSyncConnection = async () => {
    setIsBreakingSyncConnection(true);
    dispatch(breakMSGraphContactSyncConnectionToDefault())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // const response = originalPromiseResult;
        // setSyncRecords(response.data);
        setIsBreakingSyncConnection(false);
        window.location.reload();
        showMsg("success", "Sync connection reset successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsBreakingSyncConnection(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error saving changes");
        }
      });
  };

  const importContactsFromMsGraph = () => {
    const msgraphOathState = JSON.stringify({ routeDictionary });
    const msgraphOathUrl =
      "https://login.microsoftonline.com/common/adminconsent" +
      `?client_id=${process.env.REACT_APP_MS_GRAPH_APP_ID}` +
      `&state=${encodeURI(msgraphOathState)}` +
      `&redirect_uri=${process.env.REACT_APP_MS_GRAPH_REDIRECT_URI}`;
    window.location.href = msgraphOathUrl;
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <DialogTitle sx={{ pb: 0 }}>Are you sure?</DialogTitle>
        <DialogContent>
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            This action will reset your MS Graph connection and your current
            sync records. You can reconnect again by re-granting access.
          </MDTypography>
        </DialogContent>
        <DialogActions>
          {isBreakingSyncConnection ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <MDButton
              onClick={breakSyncConnection}
              // variant="gradient"
              color="success"
              sx={{ padding: "12px 12px" }}
            >
              Proceed
            </MDButton>
          )}

          <MDButton
            type="button"
            variant="outlined"
            color="dark"
            sx={{ padding: "12px 12px" }}
            onClick={onClose}
          >
            Cancel
          </MDButton>
        </DialogActions>
      </Dialog>
      <Grid my={3} container width="100%" spacing={2}>
        <Grid item xs={12} lg={5}>
          <MDBox bgColor="success" borderRadius="lg" mb={1} textAlign="center">
            <MDTypography variant="h4" color="white">
              {syncRecords ? syncRecords.clientName : "--"} using MS Graph
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container width="100%" spacing={2}>
        <Grid item xs={12} lg={5}>
          <Card
            sx={{
              height: "100%",
              width: "100%",
              padding: "1rem",
              maxHeight: "600px",
            }}
          >
            <MDTypography variant="h6" color="success">
              Sync settings
            </MDTypography>
            <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="Weekly">Auto sync contacts</InputLabel>
                <Select
                  sx={{ height: "40px" }}
                  labelId="Autosync"
                  label="Auto sync contacts"
                  id="Autosync"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </MDBox>

            {/* <MDBox sx={{ width: "100%", marginTop: "1rem" }}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="Contactcreation">
                    Contact creation behaviour
                  </InputLabel>
                  <Select
                    sx={{ height: "40px" }}
                    labelId="Contactcreation"
                    label="  Contact creation behaviour"
                    id="Contactcreation"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <MenuItem value="add removed users">
                      Add removed users from M365 as archived contacts
                    </MenuItem>
                    <MenuItem value="do not add removed users">
                      Do not add removed users from M365
                    </MenuItem>
                  </Select>
                </FormControl>
              </MDBox> */}

            {isSavingSyncSettings || isLoadingSyncInfo ? (
              <MDBox mt={3}>
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              </MDBox>
            ) : (
              <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                <MDButton
                  type="submit"
                  // variant="gradient"
                  color="success"
                  disabled={syncRecords && syncRecords.lastSyncAt == null}
                  onClick={saveSyncSettings}
                  sx={{ padding: "12px 20px", marginTop: "1rem" }}
                >
                  {syncRecords && syncRecords.lastSyncAt == null
                    ? "Initial sync required"
                    : "Save Sync Settings"}
                </MDButton>

                {syncRecords && syncRecords.lastSyncAt == null ? (
                  ""
                ) : (
                  <MDButton
                    type="submit"
                    // variant="gradient"
                    color="warning"
                    onClick={() => setOpen(true)}
                    sx={{ padding: "12px 20px", marginTop: "1rem" }}
                  >
                    Break Sync Connection
                  </MDButton>
                )}
              </MDBox>
            )}

            {syncRecords ? (
              <>
                {" "}
                <MDTypography variant="h6" color="dark" mt={4}>
                  Last sync:{" "}
                  {syncRecords.lastSyncAt
                    ? syncRecords.lastSyncAt
                    : "No initial sync yet"}
                </MDTypography>
                <MDTypography variant="h6" color="dark" mt={1}>
                  {syncRecords.numberOfAssetsSynced
                    ? "Synced " +
                      syncRecords.numberOfAssetsSynced +
                      " new contacts"
                    : "No new contacts synced"}
                </MDTypography>
              </>
            ) : (
              ""
            )}
            <MDTypography
              variant="p"
              color="success"
              mt={3}
              sx={{ fontSize: "0.8rem" }}
            >
              Click on the Sync now button if you would like to import your
              contacts before the next automatic sync.
            </MDTypography>
            <MDTypography variant="h6" color="success" mt={1}>
              <MDButton
                onClick={() => importContactsFromMsGraph()}
                variant="gradient"
                color="dark"
              >
                Import & Sync now
              </MDButton>
            </MDTypography>
          </Card>
          <>
            {/* <MDBox>
                <LoadingSpinner subClass="text-center" color="success" size="lg" />
                <MDTypography sx={{ textAlign: "center" }}>
                  Loading Keys
                </MDTypography>
              </MDBox> */}
          </>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default MSGraphAutoContactsImportConfigSection;
