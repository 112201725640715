import { useState } from "react";
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import InviteAdminUsersDialog from "./components/InviteAdminUsersDialog/InviteAdminUsersDialog";
import UsersManagementListCard from "./components/UsersManagementListCard/UsersManagementListCard";
const MSPUsersManagement = (props) => {
  const [openInviteAdminUsersDialog, setOpenInviteAdminUsersDialog] =
    useState(false);

  const btnOnClickInviteAdminUser = () => {
    setOpenInviteAdminUsersDialog(true);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <MDButton
            sx={{ padding: "5px 15px", marginTop: "15px" }}
            color={"success"}
            onClick={() => btnOnClickInviteAdminUser()}
          >
            <Icon fontSize="large">add</Icon>
            <MDTypography
              fontWeight="bold"
              sx={{ marginLeft: "0.75rem" }}
              color={"white"}
              variant="button"
            >
              Invite New User
            </MDTypography>
          </MDButton>
        </MDBox>
        <UsersManagementListCard objUser={props.objUser} />
      </MDBox>
      <InviteAdminUsersDialog
        openInviteAdminUsersDialog={openInviteAdminUsersDialog}
        setOpenInviteAdminUsersDialog={setOpenInviteAdminUsersDialog}
      />
    </DashboardLayout>
  );
};

export default MSPUsersManagement;
