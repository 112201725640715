import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const checkConfiguredLockers = createAsyncThunk(
  "company/checkConfiguredLockers",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/check-for-configured-evidence-lockers`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchSharePointSiteId = createAsyncThunk(
  "company/fetchSharePointSiteId",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/sharepoint/site-id`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeOneDriveConnection = createAsyncThunk(
  "company/removeOneDriveConnection",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/one-drive/disconnect`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const removeSharePointConnection = createAsyncThunk(
  "company/removeSharePointConnection",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/sharepoint/disconnect`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
