/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";
import CustomerStatusBtnTr from "./CustomerStatusBtnTr";
import NameBtnTr from "./NameBtnTr";
const CustomerManagementDataTableData = {
  columns: [
    {
      Header: "name",
      accessor: "customerName",
      Cell: ({ value, column, row }) => (
        <NameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "Type",
      accessor: "clientType",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of policies",
      accessor: "numberOfPolicies",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "assets mapped",
      accessor: "assetsMappedCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of contacts",
      accessor: "numberOfContacts",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "number of users",
      accessor: "numberOfUsers",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "address",
      accessor: "address",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "city",
      accessor: "city",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "state",
      accessor: "state",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default CustomerManagementDataTableData;
