import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
// import { POLICIES_SERVER } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

// const createPolicyURL = (objUser, client_id) => {
//   return POLICIES_SERVER.replace("{companyId}", objUser.user.company_id).replace(
//     "{clientId}",
//     client_id
//   );
// };

export const adoptPolicy = createAsyncThunk(
  "policy/adopt",
  async (values, thunkApi) => {
    try {
      const {
        // objUser,
        companyId,
        clientId,
        contactId,
        campaignId,
        adoptionSecret,
      } = values;
      // const this_policies_server = createPolicyURL(objUser, clientId);
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/campaigns/${campaignId}/adopt`,
        { contact_id: contactId, secret: adoptionSecret }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicyDocumentWithContactIdAndAdoptionSecrete =
  createAsyncThunk("policy-document/get", async (values, thunkApi) => {
    try {
      const {
        // objUser,
        companyId,
        clientId,
        contactId,
        campaignId,
        adoptionSecret,
      } = values;
      // const this_policies_server = createPolicyURL(objUser, clientId);
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/campaigns/${campaignId}/policy-document?contact_id=${contactId}&secret=${adoptionSecret}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  });

export const getPolicyDocumentWithContactIdAndGroupedCampaignId =
  createAsyncThunk(
    "policy-adoption-campaigns/get",
    async (values, thunkApi) => {
      try {
        const { companyId, clientId, contactId, groupedCampaignId } = values;
        // const this_policies_server = createPolicyURL(objUser, clientId);
        const res = await axios.get(
          `${BACKEND_API_URL}${VERSION_SERVER}/companies/${companyId}/clients/${clientId}/grouped-campaigns/${groupedCampaignId}?contact_id=${contactId}`
        );
        return res;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );
