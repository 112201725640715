/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import SendIcon from "@mui/icons-material/Send";
import CheckIcon from "@mui/icons-material/Check";
import Cancel from "@mui/icons-material/Cancel";
import Delete from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const RMFActionBtnTr = (props) => {
  const { row, editRMF, deleteRMF } = props;

  const confirm = useConfirm();
  const btnOnClickEditRMF = (data) => {
    editRMF(data);
  };

  const btnOnClickDeleteRMF = (data) => {
    confirm({
      description: "Do you want to delete this framework?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteRMF(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <Fragment>
      <MDButton
        disabled={row.original.is_global == 1}
        onClick={() => btnOnClickEditRMF(row.original)}
      >
        <MDBox
          color="dark"
          display="flex"
          alignItems="center"
          sx={{
            opacity: row.original.is_global == 1 ? 0 : 1,
          }}
        >
          <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{
              lineHeight: 0,
              marginLeft: "0.5rem",
            }}
          >
            Edit
          </MDTypography>
        </MDBox>
      </MDButton>

      <MDButton
        disabled={row.original.is_global == 1}
        onClick={() => btnOnClickDeleteRMF(row.original)}
        variant="gradient"
        sx={{ marginLeft: "1rem" }}
      >
        <MDBox
          color="error"
          display="flex"
          alignItems="center"
          sx={{
            opacity: row.original.is_global == 1 ? 0 : 1,
          }}
        >
          <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="error"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            Delete
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default RMFActionBtnTr;
