// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { getSsps, _deleteSsp } from "features/ssp/sspActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import { standardDateFormat } from "utils/general";

import wispImageLogo from "assets/images/wisp-preview.png";
import DashboardActionButton from "views/components/DashboardActionButton";

import ManagementTable from "./components/SspsTable/ManagementTable";
import dataTableData from "./components/SspsTable/DataTableData";

const Index = (props) => {
  const navigate = useNavigate();
  const { objUser } = props;
  const dispatch = useDispatch();

  /**Wisp table */
  const [isDeletingWisp, setIsDeletingWisp] = useState(false);
  const [isLoadingWisps, setIsLoadingWisps] = useState(false);
  const [objSspsTableData, setobjSspsTableData] = useState(dataTableData);
  const [totalSspsCount, settotalSspsCount] = useState(0);
  const [perPageSsps, setPerPageSsps] = useState(10);
  const [PageSsp, setPageSsp] = useState(1);

  useEffect(() => {
    loadMore();
  }, []);

  const loadMore = (_per_page, _page) => {
    setIsLoadingWisps(true);
    dispatch(getSsps({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingWisps(false);
        populateSspTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingWisps(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  const populateSspTableData = (response) => {
    const objRows = [];
    const objList = response.data.ssps;

    for (let index = 0; index < objList.length; index++) {
      const element = objList[index];
      const objTr = {};
      objTr.id = element.id;
      objTr.name = element.name;
      objTr.created_by = element.creator.name;
      objTr.created_at = standardDateFormat(element.created_at);
      objRows.push(objTr);
    }
    settotalSspsCount(response.data.total);
    setobjSspsTableData({
      rows: objRows,
    });
  };

  const deleteSsp = async (dataFromActionBtn) => {
    setIsDeletingWisp(true);
    dispatch(_deleteSsp({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingWisp(false);
        const response = originalPromiseResult;
        if (response.status === 200 || response.status === 201) {
          showMsg("success", "CCA deleted");
          populateSspTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingWisp(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      });
  };

  const navigateToCreateNew = () => {
    navigate(`/assessments/CCA/new`);
  };

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container width="100%" spacing={2}>
          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              gap=".6rem"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
              mb={2}
            >
              <MDTypography variant="h4" sx={{ paddingTop: "1.2rem" }}>
                Compliance Control Assessment
              </MDTypography>
              <DashboardActionButton
                action={navigateToCreateNew}
                btnText="Create New CCA"
                btnIcon="add"
                textColor="white"
                bgColor="success"
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                minHeight: "300px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoadingWisps ? (
                <MDBox mt={2}>
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                </MDBox>
              ) : (
                <>
                  {totalSspsCount === 0 ? (
                    <>
                      <MDBox mb={2}>
                        <img src={wispImageLogo} width="90px" alt="" />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDTypography variant="p" fontWeight="regular">
                          You haven't added any CCAs yet
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          type="submit"
                          // variant="gradient"
                          color="success"
                          onClick={() => navigateToCreateNew()}
                          sx={{ padding: "12px 20px", margin: "0px 5px" }}
                        >
                          Create New CCA
                        </MDButton>
                      </MDBox>
                    </>
                  ) : (
                    <>
                      <ManagementTable
                        table={objSspsTableData}
                        entriesPerPage={true}
                        canSearch
                        totalCount={totalSspsCount}
                        loadMoreObj={loadMore}
                        perPage={perPageSsps}
                        setPerPage={setPerPageSsps}
                        _page={PageSsp}
                        setPage={setPageSsp}
                        isDeletingWisp={isDeletingWisp}
                        setobjSspsTableData={setobjSspsTableData}
                        deleteObj={deleteSsp}
                        objUser={objUser}
                      />
                    </>
                  )}
                </>
              )}
            </Card>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
};

export default Index;
