import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import Icon from "@mui/material/Icon";
import { Alert, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useLocation } from "react-router-dom";
import "./settings.css";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import SharepointLogo from "assets/images/logos/sharepoint.png";
import OneDriveLogo from "assets/images/logos/one-drive.png";
import DashboardButton from "views/components/DashboardButtton";
import { useEffect, useState } from "react";
import { checkConfiguredLockers } from "features/company/evidenceLockerActions";
import DashboardActionButton from "views/components/DashboardActionButton";
import { removeOneDriveConnection } from "features/company/evidenceLockerActions";
import { removeSharePointConnection } from "features/company/evidenceLockerActions";
import InfoIcon from "@mui/icons-material/Info";

const EvidenceLocker = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [evidenceLockers, setEvidenceLockers] = useState({});
  const dispatch = useDispatch();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const checkLockers = async () => {
    dispatch(checkConfiguredLockers({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setEvidenceLockers(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const disconnectOneDrive = async () => {
    setIsLoading(true);
    dispatch(removeOneDriveConnection({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        showMsg("success", "OneDrive disconnect successfully.");
        setEvidenceLockers({
          sharepoint: evidenceLockers.sharepoint,
          onedrive: false,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  const disconnectSharePoint = async () => {
    setIsLoading(true);
    dispatch(removeSharePointConnection({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // REFACTOR @BraLaryea
        const response = originalPromiseResult;
        showMsg("success", "SharePoint disconnect successfully.");
        setEvidenceLockers({
          sharepoint: false,
          onedrive: evidenceLockers.onedrive,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  useEffect(() => {
    checkLockers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <MDTypography fontWeight="bold" variant="h5">
            Evidence Locker
          </MDTypography>
          <MDBox>
            <Alert severity="success" icon={<InfoIcon fontSize="inherit" />}>
              If you have an active M365 session in this browser, the OneDrive
              and Sharepoint connections will default to that login when you
              attempt to configure.
            </Alert>
          </MDBox>
          <MDBox>
            <Grid spacing={2} container width="100%">
              <Grid item xs={12} lg={4}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="10px"
                      >
                        {evidenceLockers.onedrive ? (
                          <MDTypography variant="body2" color="success">
                            Configured
                          </MDTypography>
                        ) : (
                          <MDTypography variant="body2" color="warning">
                            Unconfigured
                          </MDTypography>
                        )}
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            // float: "right",
                            color: evidenceLockers.onedrive
                              ? "#38b54a"
                              : "orange",
                          }}
                        >
                          circle
                        </Icon>
                      </MDBox>
                      <Grid item xs={12} lg={2}>
                        <img src={OneDriveLogo} alt="OneDerive" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        One Drive
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        {/* Configure onedrive evidence locker */}
                      </MDTypography>
                      <MDBox display="flex" gap="10px">
                        <DashboardButton
                          component={Link}
                          to={`/evidence-locker/ms-graph-onedrive-connect/${objUser.user.current_client_id}`}
                          state={{ routeDictionary }}
                          btnText={
                            evidenceLockers.onedrive
                              ? "Reconfigure"
                              : "Configure"
                          }
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                        {evidenceLockers.onedrive && (
                          <DashboardActionButton
                            component={Link}
                            action={disconnectOneDrive}
                            btnText="Disconnect"
                            btnIcon="settings"
                            textColor="white"
                            bgColor="warning"
                          />
                        )}
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={4}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        height="10px"
                      >
                        {evidenceLockers.sharepoint ? (
                          <MDTypography variant="body2" color="success">
                            Configured
                          </MDTypography>
                        ) : (
                          <MDTypography variant="body2" color="warning">
                            Unconfigured
                          </MDTypography>
                        )}
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            color: evidenceLockers.sharepoint
                              ? "#38b54a"
                              : "orange",
                          }}
                        >
                          circle
                        </Icon>
                      </MDBox>
                      <Grid item xs={12} lg={2}>
                        <img src={SharepointLogo} alt="sharepoint" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Sharepoint
                      </MDTypography>
                      <MDBox display="flex" gap="10px">
                        <DashboardButton
                          component={Link}
                          to={`/evidence-locker/sharepoint`}
                          state={{ routeDictionary }}
                          btnText={
                            evidenceLockers.sharepoint
                              ? "Reconfigure"
                              : "Configure"
                          }
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                        {evidenceLockers.sharepoint && (
                          <DashboardActionButton
                            component={Link}
                            action={disconnectSharePoint}
                            btnText="Disconnect"
                            btnIcon="settings"
                            textColor="white"
                            bgColor="warning"
                          />
                        )}
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
    </DashboardLayout>
  );
};

export default EvidenceLocker;
