import { createSlice, current } from "@reduxjs/toolkit";
import {
  storeAddCustomerClient,
  listCustomerClients,
  clearCustomerClients,
  listConnectWiseAllClients,
  clearConnectWiseAllClients,
  activateConnectWiseClient,
  updateConnectWiseClient,
  deActivateConnectWiseClient,
  deleteConnectWiseClient,
  updateConnectWiseClientById,
  fetchConnectWiseClientById,
  deleteConnectWiseClientfromStore,
} from "./clientActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {},
  extraReducers: {
    // storeAddCustomerClient
    [storeAddCustomerClient.pending]: (state) => {
      state.loading = true;
    },
    [storeAddCustomerClient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientFormSuccess = payload;
    },
    [storeAddCustomerClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientFormSuccess = payload;
    },

    // listCustomerClients
    [listCustomerClients.pending]: (state) => {
      state.loading = true;
    },
    [listCustomerClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },
    [listCustomerClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },

    // listCustomerClients
    [listCustomerClients.pending]: (state) => {
      state.loading = true;
    },
    [listCustomerClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },
    [listCustomerClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },

    // clearCustomerClients
    [clearCustomerClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },
    [clearCustomerClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListClientData = payload;
    },

    // listConnectWiseAllClients
    [listConnectWiseAllClients.pending]: (state) => {
      state.loading = true;
    },
    [listConnectWiseAllClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWiseAllClientsData = payload;
    },
    [listConnectWiseAllClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWiseAllClientsData = payload;
    },

    // clearConnectWiseAllClients
    [clearConnectWiseAllClients.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWisePendingClientsData = payload;
    },
    [clearConnectWiseAllClients.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWisePendingClientsData = payload;
    },

    // activateConnectWiseClient
    [activateConnectWiseClient.pending]: (state) => {
      state.loading = true;
    },
    [activateConnectWiseClient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objActivateConnectWiseClientSuccess = payload;
    },
    [activateConnectWiseClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objActivateConnectWiseClientSuccess = payload;
    },

    // updateConnectWiseClient
    [updateConnectWiseClient.fulfilled]: (state, action) => {
      state.loading = false;
      let objCurrentClient = action.payload;
      let objClient =
        state.objListConnectWiseAllClientsData.data.data.data.find(
          (item) => item.id === objCurrentClient.id
        );
      if (objClient) {
        // objClient.status = 1;
        objClient.status = objCurrentClient.status;
      }
      objClient = objCurrentClient;
      // let currentState = current(state);
      // // let currentState = current(state);

      // let objCurrentClient = action.payload;
      // let objAllConnectWiseAllClients =
      //   currentState.objListConnectWiseAllClientsData.data.data.data;
      // // return {
      // //   ...currentState,
      // //   referrals: currentState.referrals.splice(payload, 1),
      // // };
      // let items = objAllConnectWiseAllClients.slice();
      // const itemIndex = items.findIndex((item) => item.id === objCurrentClient.id);

      // // add new one
      // if (itemIndex === -1) {
      //   // state.objListConnectWisePendingClientsData = payload;
      //   // return state;
      //   state = state;
      // } else {

      //   // update the existing one
      //   // let objAllConnectWiseAllClientsData =
      //   //   currentState.objListConnectWiseAllClientsData.data.data.data.slice();
      //   // let nextStateObjAllConnectWiseAllClientsData = objAllConnectWiseAllClientsData.map(
      //   //   (item) => {

      //   //     if (item.id !== objCurrentClient.id) {
      //   //       // not our item, return it as is
      //   //       return item;
      //   //     }
      //   //     // objCurrentClient.status = 1;

      //   //     // this is our relevant item, return a new copy of it with modified fields
      //   //     return {
      //   //       // ...item,
      //   //       ...objCurrentClient,
      //   //     };
      //   //   }
      //   // );

      //   // // items.objListConnectWiseAllClientsData.data.data.data =
      //   // //   nextStateObjAllConnectWiseAllClientsData;
      //   // const newState = {
      //   //   ...currentState,
      //   //   objListConnectWiseAllClientsData: {
      //   //     data: {
      //   //       ...currentState.objListConnectWiseAllClientsData.data,
      //   //       data: {
      //   //         ...currentState.objListConnectWiseAllClientsData.data.data,
      //   //         data: nextStateObjAllConnectWiseAllClientsData,
      //   //       },
      //   //     },
      //   //   },
      //   // };
      //   // state.objListConnectWisePendingClientsData.data.data.data =
      //   //   nextStateObjAllConnectWiseAllClientsData;

      //   // state.objListConnectWisePendingClientsData = newState;
      //   // return {
      //   //   ...state,
      //   //   objListConnectWisePendingClientsData: newState,
      //   // };
      // }
    },
    [updateConnectWiseClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWiseAllClientsData = payload;
    },

    // deleteConnectWiseClientfromStore
    [deleteConnectWiseClientfromStore.fulfilled]: (state, action) => {
      state.loading = false;

      let objCurrentClient = action.payload;

      const objNewClientsData =
        state.objListConnectWiseAllClientsData.data.data.data.filter(
          (item) => item.id !== objCurrentClient.id
        );

      state.objListConnectWiseAllClientsData.data.data.data = objNewClientsData;
    },
    [deleteConnectWiseClientfromStore.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objListConnectWiseAllClientsData = payload;
    },

    // deActivateConnectWiseClient
    [deActivateConnectWiseClient.pending]: (state) => {
      state.loading = true;
    },
    [deActivateConnectWiseClient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeActivateConnectWiseClientSuccess = payload;
    },
    [deActivateConnectWiseClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeActivateConnectWiseClientSuccess = payload;
    },

    // deleteConnectWiseClient
    [deleteConnectWiseClient.pending]: (state) => {
      state.loading = true;
    },
    [deleteConnectWiseClient.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteConnectWiseClientSuccess = payload;
    },
    [deleteConnectWiseClient.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteConnectWiseClientSuccess = payload;
    },

    // updateConnectWiseClientById
    [updateConnectWiseClientById.pending]: (state) => {
      state.loading = true;
    },
    [updateConnectWiseClientById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateConnectWiseClientByIdSuccess = payload;
    },
    [updateConnectWiseClientById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateConnectWiseClientByIdSuccess = payload;
    },

    // fetchConnectWiseClientById
    [fetchConnectWiseClientById.pending]: (state) => {
      state.loading = true;
    },
    [fetchConnectWiseClientById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchConnectWiseClientByIdData = payload;
    },
    [fetchConnectWiseClientById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchConnectWiseClientByIdData = payload;
    },
  },
});

// export const { logout } = clientSlice.actions;

export default clientSlice.reducer;
