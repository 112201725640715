import { createSlice } from "@reduxjs/toolkit";
import {
  inviteUserToCompany,
  validateUserInvitationToCompany,
  acceptUserInvitationToCompany,
  companyUsersList,
  clearCompanyUsersList,
  deleteCompanyUser,
  deleteCompanyUserFromStore,
} from "./companiesActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const companiesSlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // inviteUserToCompany
    [inviteUserToCompany.pending]: (state) => {
      state.loading = true;
    },
    [inviteUserToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objInviteUserToCompanyFormSuccess = payload;
    },
    [inviteUserToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objInviteUserToCompanyFormSuccess = payload;
    },

    // validateUserInvitationToCompany
    [validateUserInvitationToCompany.pending]: (state) => {
      state.loading = true;
    },
    [validateUserInvitationToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objValidateUserInvitationToCompanyFormSuccess = payload;
    },
    [validateUserInvitationToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objValidateUserInvitationToCompanyFormSuccess = payload;
    },

    // acceptUserInvitationToCompany
    [acceptUserInvitationToCompany.pending]: (state) => {
      state.loading = true;
    },
    [acceptUserInvitationToCompany.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objAcceptUserInvitationToCompanyFormSuccess = payload;
    },
    [acceptUserInvitationToCompany.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objAcceptUserInvitationToCompanyFormSuccess = payload;
    },

    //

    // companyUsersList
    [companyUsersList.pending]: (state) => {
      state.loading = true;
    },
    [companyUsersList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload.data.users;
    },
    [companyUsersList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },

    // clearCompanyUsersList
    [clearCompanyUsersList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },
    [clearCompanyUsersList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },

    // deleteCompanyUser
    [deleteCompanyUser.pending]: (state) => {
      state.loading = true;
    },
    [deleteCompanyUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteCompanyUserSuccess = payload;
    },
    [deleteCompanyUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteCompanyUserSuccess = payload;
    },

    // deleteCompanyUserFromStore
    [deleteCompanyUserFromStore.fulfilled]: (state, action) => {
      state.loading = false;

      let objCompanyUser = action.payload;
      const objNewCompanyUsersListData =
        state.objCompanyUsersListData.data.data.data.filter(
          (item) => item.id !== objCompanyUser.id
        );
      state.objCompanyUsersListData.data.data.data = objNewCompanyUsersListData;
    },
    [deleteCompanyUserFromStore.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objCompanyUsersListData = payload;
    },
  },
});

// export const { logout } = companiesSlice.actions;

export default companiesSlice.reducer;
