import * as React from "react";
import DialogContent from "@mui/material/DialogContent";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import InviteAdminUsersForm from "./InviteAdminUsersForm";

const InviteAdminUsersDialogBody = (props) => {
  const { setOpenInviteAdminUsersDialog } = props;
  return (
    <DialogContent>
      <MDBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <MDBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          p="3rem 1rem"
          pb="0"
        >
          <MDTypography
            fontSize={{ xs: "2rem", sm: "2rem" }}
            fontWeight="medium"
            color="secondary"
            mt={1}
            mb={0}
          >
            Invite Company User
          </MDTypography>
          <MDTypography
            fontSize={{ xs: "1rem", sm: "1rem" }}
            fontWeight="light"
            color="secondary"
            mt={0}
            mb={2}
          >
            Client users are invited from a client's contact page.
          </MDTypography>
        </MDBox>
      </MDBox>
      <InviteAdminUsersForm setOpenInviteAdminUsersDialog={setOpenInviteAdminUsersDialog} />
    </DialogContent>
  );
};

export default InviteAdminUsersDialogBody;
