import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { showMsg } from "utils/general";

import { useDispatch } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import {
  Link,
  useFetcher,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Container,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import brandDark from "assets/images/icons/logo.png";
import cis from "assets/images/icons/cis-v8.png";
import cmmc from "assets/images/icons/cmmc.png";
import cyber from "assets/images/icons/cyber-essentials.png";
import ftc from "assets/images/icons/ftc.png";
import pci from "assets/images/icons/pci.png";
import soc from "assets/images/icons/soc-2.png";

import { CardContent, Grid, Icon, List, ListItem } from "@mui/material";
import { IconButton, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { getPublicData } from "features/company/TrustCenterActions";
import { saveOverviewChanges } from "features/company/TrustCenterActions";
import DOMPurify from "dompurify";
import "./OpenedDocument.css";
import AssessmentCharts from "views/components/GameCharts/AssessmentCharts";
import DocumentItem from "../components/DocumentItem";

const PublicTrustCenter = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [nonExpiryKB, setNonExpiryKB] = useState({});
  const [documents, setDocuments] = useState([]);
  const [rmfs, setRmfs] = useState([]);
  const [overview, setOverview] = useState();
  const [client, setClient] = useState();
  const [contacts, setContacts] = useState([]);
  const [pointOfContacts, setPointOfContacts] = useState([]);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [logo, setLogo] = useState(brandDark);

  let { client_id } = useParams();

  const fetchPublicData = () => {
    setIsLoading(true);
    let clientId = client_id;
    dispatch(getPublicData({ clientId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setRmfs(originalPromiseResult.data.rmfs);
        setDocuments(originalPromiseResult.data.policies);
        setOverview(originalPromiseResult.data.overview);
        setClient(originalPromiseResult.data.client);
        setNonExpiryKB(originalPromiseResult.data.kbs);
        setContacts(originalPromiseResult.data.contacts);
        setPointOfContacts(originalPromiseResult.data.point_of_contacts);

        const kbs = originalPromiseResult.data.kbs;

        // Split the string by '/'
        const parts = kbs.split("/");

        // Assign values to variables
        const company_id = parts[2];
        const client_id = parts[3];
        const kbs_id = parts[4];
        const kbs_secret = parts[5];
        if (originalPromiseResult.data.logo) {
          setLogo(originalPromiseResult.data.logo);
        }
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  const sanitizedText = (text) => {
    return DOMPurify.sanitize(text ?? "");
  };

  const getLogo = (text) => {
    switch (text) {
      case "CIS":
        return cis;
      case "CMMC":
        return cmmc;
      case "Cyber Insurance":
        return cyber;
      case "FTC":
        return ftc;
      case "SOC2":
        return soc;
      case "PCI-DSS":
        return pci;
      default:
        return brandDark;
    }
  };

  const shortenedText = (text) => {
    return text.length > 20 ? text.slice(0, 20) + "..." : text;
  };

  const formattedCurrentContacts = useMemo(() => {
    return contacts.filter((contact) => {
      return pointOfContacts.includes(contact.id);
    });
  }, [contacts, pointOfContacts]);

  useEffect(() => {
    fetchPublicData();
  }, []);

  return (
    <MDBox pt={2} mb={5}>
      {!isLoading ? (
        <Container>
          <Grid container spacing={3} width="100%">
            {/* logo and title */}
            <Grid item xs={12}>
              <Card
                display="flex"
                sx={{
                  width: "100%",
                  padding: "0.7rem",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "3rem",
                  background: "transparent",
                  boxShadow: "none",
                  border: "none",
                }}
              >
                <MDBox sx={{ width: "20%" }}>
                  <img
                    src={logo}
                    style={{ maxWidth: "100%", height: "auto" }}
                    alt=""
                  />
                </MDBox>
                <MDTypography
                  variant="h2"
                  color="white"
                  sx={{
                    backgroundColor: "#4CAF50",
                    // color: "#4CAF50",
                    width: "80%",
                    textAlign: "center",
                    borderRadius: "12px",
                  }}
                  p={1}
                >
                  {client ? client + "'s " : ""}Trust Center
                </MDTypography>
              </Card>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Card sx={{ height: "100%" }}>
                <MDBox p={2}>
                  {/* <MDBox
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    alignItem="center"
                  >
                    <MDTypography variant="h5" fontWeight="bold">
                      Overview
                    </MDTypography>
                  </MDBox> */}

                  {/* <MDTypography
                    variant="p"
                    fontWeight="regular"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedText(overview),
                    }}
                  ></MDTypography> */}
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <MDTypography variant="h5" fontWeight="bold">
                        Overview
                      </MDTypography>
                      <MDTypography
                        variant="p"
                        fontWeight="regular"
                        dangerouslySetInnerHTML={{
                          __html: sanitizedText(overview),
                        }}
                      ></MDTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <MDTypography variant="h5" fontWeight="bold">
                        Points of Contact
                      </MDTypography>
                      <TableContainer
                        component={Paper}
                        style={{ width: "100%" }}
                      >
                        <TableRow>
                          <TableCell
                            style={{
                              width: "25%",
                            }}
                          >
                            <b>Name</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "25%",
                            }}
                          >
                            <b>Email</b>
                          </TableCell>
                          <TableCell
                            style={{
                              width: "25%",
                            }}
                          >
                            <b>Title</b>
                          </TableCell>
                        </TableRow>

                        <TableBody>
                          {formattedCurrentContacts ? (
                            <>
                              {formattedCurrentContacts.map((contact, id) => {
                                return (
                                  <TableRow
                                    key={id}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      style={{
                                        width: "5%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {contact.first_name} {contact.last_name}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "5%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {contact.email}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "5%",
                                        minWidth: "100%",
                                      }}
                                    >
                                      {contact.title}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Card>
                <MDBox p={2}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    alignItem="center"
                  >
                    <MDTypography variant="h5" fontWeight="bold">
                      Policy Scorecard
                      {/* <Tooltip
                        title="If switches are diabled, kindly enable them in Policy Scorecard > Manage RMF Packs."
                        placement="right-end"
                      >
                        <IconButton
                          sx={
                            // darkMode
                            //   ? { fontSize: "1.2rem" }
                            { opacity: "0.4", fontSize: "1.2rem" }
                          }
                          pb={2}
                        >
                          <HelpIcon color="icon" />
                        </IconButton>
                      </Tooltip> */}
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4}>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 500,
                        minHeight: 500,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      <Grid container spacing={2}>
                        {rmfs.map((rmf, i) => {
                          return (
                            <>
                              <Grid item xs={12} lg={4}>
                                <Card
                                  sx={{ height: 120, maxHeight: 120 }}
                                  variant="outlined"
                                >
                                  {/* <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      height: "100%",
                                    }}
                                  >
                                    <MDTypography
                                      fontSize="14px"
                                      variant="button"
                                    >
                                      {shortenedText(rmf.name)}
                                    </MDTypography>
                                    <MDBox
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Icon fontSize="medium" color="success">
                                        verified
                                      </Icon>
                                    </MDBox>
                                  </CardContent> */}
                                  <CardContent
                                    sx={{
                                      height: "100%",
                                    }}
                                  >
                                    <MDBox
                                      mt={1}
                                      sx={{ height: "60%" }}
                                      display="flex"
                                      justifyContent="center"
                                    >
                                      <img
                                        src={
                                          rmf.is_global == 1
                                            ? getLogo(rmf.name)
                                            : logo
                                        }
                                        style={{
                                          maxHeight: "100%",
                                          // height: "auto",
                                        }}
                                        alt=""
                                      />
                                    </MDBox>
                                    <MDBox
                                      display="flex"
                                      alignItem="center"
                                      justifyContent="center"
                                      mt={1}
                                      gap={1}
                                    >
                                      <MDBox
                                      // display="flex"
                                      // justifyContent="flex-end"
                                      >
                                        <Icon fontSize="small" color="success">
                                          verified
                                        </Icon>
                                      </MDBox>
                                      <MDTypography
                                        fontSize="14px"
                                        variant="button"
                                      >
                                        {shortenedText(rmf.name)}
                                      </MDTypography>
                                    </MDBox>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </>
                          );
                        })}
                      </Grid>
                    </List>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} lg={6}>
              <AssessmentCharts objUser={objUser} trustCenterPage={true} />
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ height: "100%" }}>
                <MDBox p={2} sx={{ height: "100%" }}>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    mb={2}
                    alignItem="center"
                  >
                    <MDTypography variant="h5" fontWeight="bold">
                      Documents
                    </MDTypography>
                    <MDBox display="flex" gap={2}>
                      <Link to={nonExpiryKB} target="_blank">
                        <MDButton type="button" color="success">
                          View Knowledge Base
                        </MDButton>
                      </Link>
                    </MDBox>
                  </MDBox>
                  <MDBox mt={4}>
                    <List
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                        position: "relative",
                        overflow: "auto",
                        maxHeight: 500,
                        minHeight: 500,
                        "& ul": { padding: 0 },
                      }}
                      subheader={<li />}
                    >
                      <Grid container spacing={2}>
                        {documents.map((document, i) => {
                          return (
                            <>
                              {/* <Grid item xs={12} lg={4}>
                                <Card
                                  sx={{ height: 120, maxHeight: 120 }}
                                  variant="outlined"
                                >
                                  <CardContent
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "space-between",
                                      height: "100%",
                                    }}
                                  >
                                    <MDTypography
                                      fontSize="14px"
                                      variant="button"
                                    >
                                      {shortenedText(document.title)}
                                    </MDTypography>
                                    <MDBox
                                      display="flex"
                                      justifyContent="flex-end"
                                    >
                                      <Icon fontSize="medium">article</Icon>
                                    </MDBox>
                                  </CardContent>
                                </Card>
                              </Grid> */}
                              <DocumentItem policy={document} kbs={nonExpiryKB} />
                            </>
                          );
                        })}
                      </Grid>
                    </List>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Grid item xs={12} lg={23}>
          <Card sx={{ marginTop: "16px" }}>
            <MDBox p={2}>
              <Grid>
                <MDTypography variant="p" fontWeight="regular">
                  <LoadingSpinner />
                </MDTypography>
              </Grid>
            </MDBox>
          </Card>
        </Grid>
      )}
    </MDBox>
  );
};

export default PublicTrustCenter;
