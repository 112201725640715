import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { getPolicies } from "features/company/policiesActions";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import dataTableData from "views/pages/Dashboard/Policies/components/PoliciesDataTableData";
import PoliciesDatatable from "../../../components/PoliciesDatatable";
import CreateNewPolicyDialog from "./components/createNewPolicyDialog";
import { standardDateFormat } from "utils/general";

const Policies = (props) => {
  const { objUser } = props;
  const [openNewPolicyDialog, setOpenNewPolicyDialog] = useState(false);
  const [isLoadingClientPolicy, setIsLoadingClientPolicy] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [templateList, setTemplateList] = useState([]);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickNewPolicy = () => {
    setOpenNewPolicyDialog(true);
  };

  const onCloseNewPolicyDialog = () => {
    setOpenNewPolicyDialog(false);
  };

  const onSubmitNewPolicyDialog = (policy) => {
    setOpenNewPolicyDialog(false);
    routeDictionary[policy.id] = policy.title;
    navigate(`/policies/${policy.id}`, {
      state: { routeDictionary },
    });
  };

  useEffect(() => {
    fetchPolicies(perPage, page);
  }, []);

  const getCurrentRMFList = (rmfsArray) => {
    return rmfsArray
      .filter((item) => {
        return item.selected === 1;
      })
      .map((item) => {
        return item.rmf.name;
      })
      .join(", ");
  };

  const fetchPolicies = (_per_page, _page) => {
    setIsLoadingClientPolicy(true);
    dispatch(getPolicies({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientPolicy(false);
        const response = originalPromiseResult;

        const objRows = [];
        const objPoliciesList = response.data.policies.filter((policy) => {
          return policy.assets_type_imported == null;
        });
        for (let index = 0; index < objPoliciesList.length; index++) {
          const element = objPoliciesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.obj_user = objUser;
          objTr.title = element.title;
          objTr.category = element.category
            ? element.category.display_name ?? ""
            : "";
          objTr.current_rmfs = getCurrentRMFList(
            element.riskmanagementframeworks
          );
          objTr.date_created = standardDateFormat(element.created_at);
          objTr.creator_name = element.creator
            ? element.creator.name + " " + element.creator.last_name ?? ""
            : "";
          objTr.status = element?.latest_document?.state;
          objTr.latest_doc_id = element?.latest_document?.id;
          objTr.element = element;
          objTr.user = props.objUser;
          objRows.push(objTr);
        }

        setTotalCount(objRows?.length);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
        setTemplateList(response.data.templates);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientPolicy(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateNewPolicyDialog
        open={openNewPolicyDialog}
        onClose={onCloseNewPolicyDialog}
        onSubmit={onSubmitNewPolicyDialog}
        objUser={props.objUser}
        templates={templateList}
      />
      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          alignItems="right"
          justifyContent="right"
          mb={2}
        >
          <DashboardActionButton
            action={btnOnClickNewPolicy}
            btnText="Add Policy"
            btnIcon="add"
            textColor="white"
            bgColor="success"
          />
        </MDBox>
        <Card>
          {isLoadingClientPolicy === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <PoliciesDatatable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchPolicies={fetchPolicies}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isLoadingClientPolicy ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Client does not have any policies.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default Policies;
