import Grid from "@mui/material/Grid";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import MDTypography from "components/MDTypography";
import { useMemo, memo } from "react";
import { Pie } from "react-chartjs-2";
import MDBox from "components/MDBox";
import Icon from "@mui/material/Icon";
Chart.register(ArcElement, Tooltip, Legend);

const GamePieChart = memo(({ data, options, title, onClickTitle }) => {
  const percent = useMemo(() => {
    const total = 360 - data.datasets[0].data[data.datasets[0].data.length - 1];
    return (total / 360) * 100;
  }, [data]);
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} pl={0} pt={0}>
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginBottom: "0.5rem",
            cursor: onClickTitle !== null ? "pointer" : "default",
            "&:hover": {
              textDecoration: onClickTitle !== null ? "underline" : "none",
            },
          }}
          onClick={onClickTitle ?? (() => {})}
        >
          <MDTypography
            sx={{
              marginRight: "5px",
            }}
            fontWeight="bold"
            variant="h5"
            textAlign="center"
            color="dark"
          >
            {title}
          </MDTypography>
          <Icon color="dark">link</Icon>
        </MDBox>
        <Pie data={data} options={options} />
        <MDTypography
          sx={{ marginBottom: "0.5rem" }}
          fontWeight="bold"
          variant="h6"
          textAlign="center"
          color="dark"
        >
          {percent.toFixed(2)}%
        </MDTypography>
      </Grid>
    </>
  );
});

GamePieChart.defaultProps = {
  onClickTitle: null,
};

export default GamePieChart;
