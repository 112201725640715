/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";
import AudienceNameBtnTr from "views/pages/Dashboard/Audience/AudienceManagement/components/AudienceManagementTable/AudienceNameBtnTr";

const dataTableData = {
  columns: [
    {
      Header: "audience name",
      accessor: "audienceName",
      Cell: ({ value, column, row }) => (
        <AudienceNameBtnTr value={value} column={column} row={row} />
      ),
    },
    {
      Header: "type",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export { dataTableData };
