import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import LogoutContainer from "./components/LogoutContainer";
function Logout(props) {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LogoutContainer />
    </DashboardLayout>
  );
}

export default Logout;
