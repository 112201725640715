import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const getPreviousSyncInformation = createAsyncThunk(
  "company/getPreviousSyncInformation",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/ms-graph/get-previous-sync-information`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveSyncFrequencySettingsForMsGraphContacts = createAsyncThunk(
  "company/saveSyncSettings",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/ms-graph/save-sync-settings`,
        {
          selectedSyncOption: values.selectedValue,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const breakMSGraphContactSyncConnectionToDefault = createAsyncThunk(
  "company/resetMSGraphContactSyncConnectionToDefault",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/ms-graph/break-ms-graph-contact-sync-connection`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
