import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardButton from "views/components/DashboardButtton";

// Data
import dataTableData from "views/pages/Dashboard/Audience/ShowContactsByAudience/components/ContactsByAudienceTable/ContactsByAudienceTableData";
import { useDispatch, useSelector } from "react-redux";
// import {  } from "features/company/clientContactsActions";
import { auth } from "../../../../../features/user/userActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContactsByAudienceTable from "./components/ContactsByAudienceTable/ContactsByAudienceTable";
import AddContactDialog from "./components/AddContactDialog";
import { showMsg } from "utils/general";
import {
  fetchContactsByAudience,
  removeContactFromAudience,
  bulkRemoveContactsFromAudience,
} from "features/company/clientAudienceActions";
import {
  fetchClientContactsById,
  inviteClientContact,
} from "features/company/clientContactsActions";
import { useConfirm } from "material-ui-confirm";
import DashboardActionButton from "views/components/DashboardActionButton";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
const ShowContactsByAudience = (props) => {
  const { objUser } = props;
  let { audience_id } = useParams();
  const [openAddContactsDialog, setOpenAddContactsDialog] = useState(false);
  const [isLoadingContact, setIsLoadingContact] = useState(false);
  const [isDeletingFromAudience, setIsDeletingFromAudience] = useState(false);
  const [isInvitingClientContact, setIsInvitingClientContact] = useState(false);
  const [objSelectedClientContact, setObjSelectedClientContact] = useState({});
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contactsURL = `/contacts`;

  /**bulk action vars */
  const [isBulkActionLoading, setIsBulkActionLoading] = useState(false);
  const [contactsSelectedForBulkAction, setContactsSelectedForBulkAction] =
    useState([]);
  const [bulkActionResultNotices, setBulkActionResultNotices] = useState([]);
  const confirm = useConfirm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    loadMoreContactsByAudience(audience_id, perPage, page);
  }, []);

  const loadMoreContactsByAudience = (_audience_id, _per_page, _page) => {
    setIsLoadingContact(true);
    dispatch(
      fetchContactsByAudience({
        audience_id: _audience_id,
        _per_page,
        _page,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingContact(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        const objRows = [];
        const objClientContactsList = response.data.contacts;
        for (let index = 0; index < objClientContactsList.length; index++) {
          const element = objClientContactsList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.firstname = element.first_name;
          objTr.lastname = element.last_name;
          objTr.email = element.email;
          objTr.element = element;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingContact(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const inviteContact = (objClientContact, values) => {
    // console.log("####inviteContact", objClientContact);
    setIsInvitingClientContact(true);
    dispatch(
      inviteClientContact({
        contactId: objClientContact.element.id,
        // assessment_access: values.txtAssessmentAccess,
        // risk_register_access: values.txtRoleRegisterAccess,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsInvitingClientContact(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        if (response.status === 201) {
          showMsg("success", "Contact invited.");
        } else if (response.status === 409) {
          showMsg("info", "Contact already invited.");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
        // navigate(`/clients/${id}`);
        loadMoreContactsByAudience(audience_id, perPage, page);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsInvitingClientContact(false);
        if (rejectedValueOrSerializedError.response.status === 409) {
          showMsg("info", "Contact already is a user in the system.");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  const openAddContactDialog = () => {
    setOpenAddContactsDialog(true);
  };

  const closeAddContactDialog = () => {
    setOpenAddContactsDialog(false);
  };

  const contactAdded = () => {
    setOpenAddContactsDialog(false);
    loadMoreContactsByAudience(audience_id, perPage, page);
  };

  const deleteFromAudience = (contact) => {
    setObjSelectedClientContact(contact);
    setIsDeletingFromAudience(true);
    dispatch(
      removeContactFromAudience({
        id: audience_id,
        contactId: contact.element.id,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "Contact removed.");
          // Remove this entry in the table
          const new_rows = objTableData.rows.filter(
            (el) => el.element.id != contact.element.id
          );
          setObjTableData({ ...objTableData, rows: new_rows });
        } else {
          showMsg("error", response.data.message);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        const response = rejectedValueOrSerializedError.response;
        if (response.data && response.data.message) {
          showMsg("error", response.data.message);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsDeletingFromAudience(false);
        setObjSelectedClientContact({});
      });
  };

  /**bulk actions */
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setBulkActionResultNotices([]);
  };

  const btnOnClickConfirmBulkRemove = () => {
    confirm({
      description:
        "Do you want to remove the selected contacts from this audience? ",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        removeSelectedContactsFromAudience();
      })
      .catch(() => {
        /* ... */
      });
  };

  const removeSelectedContactsFromAudience = async () => {
    setIsBulkActionLoading(true);
    dispatch(
      bulkRemoveContactsFromAudience({
        id: audience_id,
        contactsSelectedForBulkAction,
        objUser,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsBulkActionLoading(false);
        const response = originalPromiseResult;
        if (response.status === 200) {
          showMsg("success", "Selected contacts removed from the audience.");
          const notRemovedContactsArray = response.data.not_removed_contacts;
          setBulkActionResultNotices(response.data.not_removed_contacts);
          setContactsSelectedForBulkAction([]);
          if (notRemovedContactsArray.length > 0) {
            handleClickOpen();
          }
          const new_rows = objTableData.rows.filter(
            (el) => !contactsSelectedForBulkAction.includes(el.element.id)
          );
          setObjTableData({ ...objTableData, rows: new_rows });
          setObjTableData({ ...objTableData, rows: new_rows });
        } else {
          showMsg("error", response.data.message);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        const response = rejectedValueOrSerializedError.response;
        if (response.data && response.data.message) {
          showMsg("error", response.data.message);
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsDeletingFromAudience(false);
        setObjSelectedClientContact({});
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddContactDialog
        open={openAddContactsDialog}
        onClose={closeAddContactDialog}
        onSubmit={contactAdded}
        currentContacts={objTableData.rows}
        audienceId={audience_id}
        inviteContact={inviteContact}
        objUser={props.objUser}
      />
      {/* Not archived/added to audience contacts report dialog */}
      <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="lg">
        <DialogTitle sx={{ m: 0, p: 2 }}>Action Notices:</DialogTitle>
        <DialogContent dividers>
          {bulkActionResultNotices.map((result) => {
            return <p>{result}</p>;
          })}
        </DialogContent>
      </Dialog>
      {/* // */}
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={0}>
          <MDButton
            onClick={openAddContactDialog}
            type="button"
            variant="gradient"
            color="dark"
            sx={{ marginTop: "15px", padding: "5px 15px" }}
          >
            <Icon fontSize="large">add</Icon>
            <MDTypography
              fontWeight="bold"
              sx={{ marginLeft: "0.75rem" }}
              color="white"
              variant="button"
            >
              Add Contact
            </MDTypography>
          </MDButton>
          {isBulkActionLoading ? (
            <MDBox sx={{ paddingTop: "2.5rem" }}>
              <LoadingSpinner color="success" size="lg" />
            </MDBox>
          ) : (
            <>
              {contactsSelectedForBulkAction.length > 0 ? (
                <>
                  <DashboardActionButton
                    action={btnOnClickConfirmBulkRemove}
                    btnText="Remove from audience"
                    btnIcon="delete"
                    textColor="white"
                    bgColor="error"
                  />
                </>
              ) : (
                ""
              )}
            </>
          )}
        </MDBox>

        <MDBox mb={2}>
          <MDTypography
            fontWeight="bold"
            sx={{ marginLeft: "0.25rem" }}
            color="dark"
            variant="caption"
          >
            Create contacts in{" "}
            <a className="tooltip-link" href={contactsURL}>
              Contact Page
            </a>
          </MDTypography>
        </MDBox>
        <Card>
          {isLoadingContact === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <ContactsByAudienceTable
              audience_id={audience_id}
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              loadMoreContactsByAudience={loadMoreContactsByAudience}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
              isDeletingFromAudience={isDeletingFromAudience}
              deleteFromAudience={deleteFromAudience}
              objSelectedClientContact={objSelectedClientContact}
              setContactsSelectedForBulkAction={
                setContactsSelectedForBulkAction
              }
              contactsSelectedForBulkAction={contactsSelectedForBulkAction}
            />
          ) : (
            <Fragment>
              {isLoadingContact ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Audience does not have any contacts.
                </p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

ShowContactsByAudience.defaultProps = {
  addContactMode: false,
};

export default ShowContactsByAudience;
