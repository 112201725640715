import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

export const listAuditLogs = createAsyncThunk(
  "auditLogs/listAuditLogs",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/logs/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listNoAuth0AuditLogs = createAsyncThunk(
  "auditLogs/listNoAuth0AuditLogs",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/logs/no-auth0/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchLog = createAsyncThunk(
  "auditLogs/listAuditLogs",
  async (values, thunkApi) => {
    try {
      const { id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/logs/${id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
