import { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { Link, useNavigate } from "react-router-dom";
import AddNewCustomerForm from "./components/AddNewCustomerForm";
import DashboardButton from "views/components/DashboardButtton";

const AddNewCustomer = (props) => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Card> */}
      <DashboardButton
        component={Link}
        // action={importContactsFromConnectWise}
        to={`/clients/import-from-connectwise`}
        btnText="Import Clients from ConnectWise"
        btnIcon="add"
        textColor="white"
        bgColor="success"
      />
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <AddNewCustomerForm objUser={props.objUser} />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default AddNewCustomer;
