// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useConfirm } from "material-ui-confirm";
import { deleteTemplate } from "features/company/templatesActions";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import { useState } from "react";

const TemplateDeleteBtnTr = (props) => {
  const { value, column, row, reloadFunc, objUser } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  
  const deleteTemplateAction = async () => {
    try {
      await confirm({
        description:
          "Are you sure you want to remove the selected documents? ",
        confirmationButtonProps: {
          color: "success",
          variant: "contained",
          sx: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1b5e20",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        cancellationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            backgroundColor: "#d32f2f",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      });
    } catch (error) {
      return;
    }
    
    setIsDeleting(true);
    try {
      await dispatch(deleteTemplate({ objUser, templateId: row.original.id }));
      showMsg("success", "Document deleted successfully.");
      reloadFunc();
    } catch (error) {
      console.log(error);
      showMsg("error", "Error deleting document. Please, try again.");
    } finally {
      setIsDeleting(false);
    }
  };
  
  return isDeleting?
    (<LoadingSpinner
      subClass="text-center"
      color="success"
      size="sm"
    />):
    (<MDButton
      onClick={deleteTemplateAction}
      variant="gradient"
      sx={{ marginLeft: "1rem" }}
      >
      <MDBox color="error" display="flex" alignItems="center">
        <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
        <MDTypography
        variant="p"
        fontWeight="medium"
        color="error"
        sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
        >
        Delete
        </MDTypography>
      </MDBox>
    </MDButton>
    );
};
  
export default TemplateDeleteBtnTr;
  