import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/**Global templates */
export const fetchTemps = createAsyncThunk(
  "fetchTemps",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/templates/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**RMF */
export const getRMF = createAsyncThunk(
  "company/getRMF",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/rmfs`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addRMF = createAsyncThunk(
  "company/addRMF",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/rmfs`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRMFData = createAsyncThunk(
  "company/deleteRMFData",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/rmfs/${dataFromActionBtn.rmfId}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveRMFChanges = createAsyncThunk(
  "company/saveCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/rmfs/${objPostData.objPostData.id}`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**save policy packs changes */
export const savePolicyPackChanges = createAsyncThunk(
  "company/savePolicyPackChanges",
  async (values, thunkApi) => {
    try {
      const { modifiedTemplateData, currentSelectedRMF } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/policy-packs/packs/`,
        {
          updatedTemplates: modifiedTemplateData,
          currentSelectedRMF: currentSelectedRMF,
        }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
