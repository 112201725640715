// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Fragment } from "react";
import FormHelperText from "@mui/material/FormHelperText";

const SaveApiKeysFormFormikWrapper = (props) => {
  const { values, errors, touched, setFieldValue } = props;
  //console.log(values);
  return (
    <Fragment>
      <MDBox my={3} textAlign="left">
        <MDTypography
          px={5}
          textAlign="left"
          mb={1}
          variant="h5"
          fontWeight="bold"
        >
          Add Your ConnectSecure v3 API Keys
        </MDTypography>
        <MDBox mt={2}>
          <Grid container xs={12} justifyContent="space-between" px={5}>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Base URL (without a trailing slash) "
                name="url"
                value={values.url}
                placeholder={"ConnectSecure Base URL"}
                error={errors.url && touched.url}
                success={values.url.length > 0 && !errors.url}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your ConnectSecure customer id"
                name="customer_id"
                value={values.customer_id}
                placeholder={"ConnectSecure customer id"}
                error={errors.customer_id && touched.customer}
                success={values.customer_id.length > 0 && !errors.customer_id}
              />
            </Grid>
            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="text"
                label="Enter Your Client id"
                name="client_id"
                value={values.client_id}
                placeholder={"Client id"}
                error={errors.client_id && touched.client_id}
                success={values.client_id.length > 0 && !errors.client_id}
              />
            </Grid>

            <Grid item xs={12} md={12} spacing={3}>
              <FormField
                type="password"
                label="Enter Your Client secret"
                name="client_secrete"
                value={values.client_secrete}
                placeholder={"client_secret"}
                error={errors.client_secrete && touched.client_secrete}
                success={
                  values.client_secrete.length > 0 && !errors.client_secrete
                }
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Fragment>
  );
};

export default SaveApiKeysFormFormikWrapper;
