// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

import * as Yup from "yup";

import { useState, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";
import axios from "axios";
// Other authentication methods components
import Auth from "views/components/shared/auth";
// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/logo.png";
import EditConnectWiseClientFormFormikWrapper from "./EditConnectWiseClientFormFormikWrapper";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import EditConnectWiseClientFormErrorsBlock from "./EditConnectWiseClientFormErrorsBlock";
import { objUsStates } from "helpers/CountryHelpers/StateHelpers";
import { objAllCountries } from "helpers/CountryHelpers/CountryHelpers";
import {
  updateConnectWiseClientById,
  fetchConnectWiseClientById,
} from "features/company/clientActions";
// import { fetchConnectWiseClientById } from "features/company/clientActions";
import {
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Switch,
  Tooltip,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useMaterialUIController } from "context";
import { updateClientFeatureAccess } from "features/company/clientActions";

const _objInitialValues = {
  txtClientName: "",
  txtAddress1: "",
  txtAddress2: "",
  drpCountry: "",
  txtCity: "",
  drpState: "",
  txtZip: "",
};

const _objInitialFeaturesValues = {
  policyTemplatesAccess: "",
  assessmentScorecardAccess: "",
  assetScorecardAccess: "",
  policyScorecardAccess: "",
  wispScorecardAccess: "",
  insuranceScorecardAccess: "",
  auditorReportAccess: "",
  riskRegisterAccess: "",
  policyTemplatesAccess: "",
};

const EditConnectWiseClientFormJWTSchema = Yup.object().shape({
  txtClientName: Yup.string().required("Company Name is required."),
  txtAddress1: Yup.string().required("Address 1 is required."),
  drpCountry: Yup.string().required("Country is required."),
  // txtAddress2: Yup.string().required("Address 2 is required."),
  txtCity: Yup.string().required("City is required."),
  // drpState: Yup.string().required("State is required."),
  txtZip: Yup.string()
    .required("Zip code is required.")
    .min(4, "Please enter a valid zip code."),
});
const EditConnectWiseClientForm = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [objInitialFeaturesValues, setObjInitialFeaturesValues] = useState(
    _objInitialFeaturesValues
  );
  const [objStates, setObjStates] = useState(objUsStates);
  const [objCountries, setObjCountries] = useState(objAllCountries);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingFeatures, setIsUpdatingFeatures] = useState(false);
  const [isLoadingConnectWiseClient, setIsLoadingConnectWiseClient] =
    useState(false);
  const [
    objEditConnectWiseClientFormErrors,
    setObjEditConnectWiseClientFormErrors,
  ] = useState({});
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  useEffect(() => {
    // setObjVerifiyEmailAddressErrors({});
    setIsLoadingConnectWiseClient(true);
    dispatch(fetchConnectWiseClientById({ id, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingConnectWiseClient(false);
        // handle result here
        // const data = response.data;

        const response = originalPromiseResult;
        // setObjConnectWiseClient(response.data);
        // let txtPhoneNumber = "";
        // if (response.data.user.phone === null) {
        //   txtPhoneNumber = "";
        // } else {
        //   txtPhoneNumber = response.data.user.phone;
        // }
        const __ObjInitialValues = {
          txtClientName: response.data.name,
          // txtLastName: "",
          txtAddress1:
            response.data.address_1 === null ? "" : response.data.address_1,
          txtAddress2:
            response.data.address_2 === null ? "" : response.data.address_2,
          drpCountry:
            response.data.country === null ? "" : response.data.country,
          txtCity: response.data.city === null ? "" : response.data.city,
          drpState: response.data.state === null ? "" : response.data.state,
          txtZip: response.data.zip === null ? "" : response.data.zip,
        };

        const __ObjInitialFeaturesValues = {
          assessmentScorecardAccess:
            response.data.feature_toggles.assessment_scorecard_access,
          assetScorecardAccess:
            response.data.feature_toggles.asset_scorecard_access,
          policyScorecardAccess:
            response.data.feature_toggles.policy_scorecard_access,
          wispScorecardAccess:
            response.data.feature_toggles.wisp_scorecard_access,
          insuranceScorecardAccess:
            response.data.feature_toggles.insurance_scorecard_access,
          auditorReportAccess:
            response.data.feature_toggles.auditor_report_access,
          riskRegisterAccess:
            response.data.feature_toggles.risk_register_access,
          policyTemplatesAccess:
            response.data.feature_toggles.policy_template_access,
        };
        setObjInitialValues(__ObjInitialValues);
        setObjInitialFeaturesValues(__ObjInitialFeaturesValues);
        // console.log("response.status in registerUser");
        // console.log("response.status in registerUser");
        // console.log("response.status in registerUser");
        // console.log(response.status);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingConnectWiseClient(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (objErrorRsp.status === 404) {
          showMsg("error", "Invalid Url");
          navigate("/clients");
        }
      });
  }, []);
  const onSubmitForm = async (values) => {
    if (values.drpCountry !== "United States of America") {
      values.drpState = "";
    } else if (
      values.drpCountry === "United States of America" &&
      values.drpState === ""
    ) {
      showMsg("error", "State is required.");
      return;
    }
    const objPostData = {
      id,
      name: values.txtClientName,
      address_1: values.txtAddress1,
      address_2: values.txtAddress2,
      country: values.drpCountry,
      city: values.txtCity,
      state: values.drpState,
      zip: values.txtZip,
      objUser: props.objUser,
      // rememberMe,
    };
    setObjEditConnectWiseClientFormErrors({});
    setIsLoading(true);
    dispatch(updateConnectWiseClientById({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        // handle result here
        // const data = response.data;
        console.log(
          "originalPromiseResult in updateConnectWiseClientById",
          originalPromiseResult
        );
        const response = originalPromiseResult;
        console.log(
          "response.status in updateConnectWiseClientById",
          response.status
        );

        if (response.status === 200) {
          showMsg("success", "You have updated client data successfully.");
          navigate("/clients");
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Please correct the errors.");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        setObjEditConnectWiseClientFormErrors(objErrors);
        // handle result here
      });
  };

  const onSubmitFeaturesForm = async (values) => {
    const objPostData = {
      id,
      assessment_scorecard_access: values.assessmentScorecardAccess,
      asset_scorecard_access: values.assetScorecardAccess,
      policy_scorecard_access: values.policyScorecardAccess,
      wisp_scorecard_access: values.wispScorecardAccess,
      insurance_scorecard_access: values.insuranceScorecardAccess,
      auditor_report_access: values.auditorReportAccess,
      risk_register_access: values.riskRegisterAccess,
      policy_template_access: values.policyTemplatesAccess,
    };
    setIsUpdatingFeatures(true);
    dispatch(updateClientFeatureAccess({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUpdatingFeatures(false);
        const response = originalPromiseResult;

        if (response.status === 201) {
          showMsg("success", "You have updated client data successfully.");
          // navigate("/clients");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUpdatingFeatures(false);
        showMsg("error", "Please correct the errors.");
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );
        const objErrors = rejectedValueOrSerializedError.response.data.errors;
        setObjEditConnectWiseClientFormErrors(objErrors);
        // handle result here
      });
  };

  if (
    isLoadingConnectWiseClient ||
    Object.keys(objInitialValues).length === 0 ||
    objInitialValues.txtClientName === ""
  ) {
    return <LoadingSpinner subClass="text-center" color="success" size="lg" />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Formik
          initialValues={objInitialValues}
          validationSchema={EditConnectWiseClientFormJWTSchema}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"EditConnectWiseClientForm"} autoComplete="off">
              <Card sx={{ height: "100%" }}>
                <MDBox>
                  <EditConnectWiseClientFormFormikWrapper
                    values={values}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    objStates={objStates ?? {}}
                    objCountries={objCountries ?? {}}
                  />
                  {/* } */}

                  {Object.keys(objEditConnectWiseClientFormErrors).length >
                  0 ? (
                    <Grid
                      container
                      justifyContent="space-between"
                      pl={5}
                      pr={5}
                      pt={0}
                      pb={5}
                    >
                      <EditConnectWiseClientFormErrorsBlock
                        objEditConnectWiseClientFormErrors={
                          objEditConnectWiseClientFormErrors
                        }
                      />
                    </Grid>
                  ) : null}

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isLoading === false ? (
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 82px" }}
                      >
                        Save
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid item xs={6}>
        <Formik
          initialValues={objInitialFeaturesValues}
          // validationSchema={EditConnectWiseClientFormJWTSchema}
          onSubmit={onSubmitFeaturesForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form
              id={"EditConnectWiseClientFeaturesForm"}
              autoComplete="off"
              style={{ height: "100%" }}
            >
              <Card sx={{ height: "100%" }}>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  sx={{ height: "100%" }}
                >
                  <MDBox my={3} textAlign="left">
                    <MDTypography
                      px={5}
                      textAlign="left"
                      mb={1}
                      variant="h4"
                      fontWeight="bold"
                    >
                      Feature Display Controls
                    </MDTypography>
                    <MDBox mt={2}>
                      <Grid
                        container
                        spacing={3}
                        justifyContent="space-between"
                        p={5}
                      >
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            // maxHeight: 600,
                            "& ul": { padding: 0 },
                            padding: "0px 20px",
                          }}
                          subheader={<li />}
                        >
                          <MDTypography
                            textAlign="left"
                            mb={1}
                            variant="h6"
                            fontWeight="bold"
                          >
                            Core Features
                          </MDTypography>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    values.assessmentScorecardAccess === 1
                                  }
                                  name={"Assessment Scorecard"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Assessment Scorecard"}
                              key={"Assessment"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.assessmentScorecardAccess === 1) {
                                  setFieldValue("assessmentScorecardAccess", 0);
                                } else {
                                  setFieldValue("assessmentScorecardAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.assetScorecardAccess === 1}
                                  name={"Asset Scorecard"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Asset Scorecard"}
                              key={"Asset"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.assetScorecardAccess === 1) {
                                  setFieldValue("assetScorecardAccess", 0);
                                } else {
                                  setFieldValue("assetScorecardAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.policyScorecardAccess === 1}
                                  name={"Policy Scorecard"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Policy Scorecard"}
                              key={"Policy"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.policyScorecardAccess === 1) {
                                  setFieldValue("policyScorecardAccess", 0);
                                } else {
                                  setFieldValue("policyScorecardAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.wispScorecardAccess === 1}
                                  name={"WISP Scorecard"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"WISP Scorecard"}
                              key={"WISP"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.wispScorecardAccess === 1) {
                                  setFieldValue("wispScorecardAccess", 0);
                                } else {
                                  setFieldValue("wispScorecardAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    values.insuranceScorecardAccess === 1
                                  }
                                  name={"Insurance Scorecard"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Insurance Scorecard"}
                              key={"Insurance"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.insuranceScorecardAccess === 1) {
                                  setFieldValue("insuranceScorecardAccess", 0);
                                } else {
                                  setFieldValue("insuranceScorecardAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.auditorReportAccess === 1}
                                  name={"Auditor Reports"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Auditor Reports"}
                              key={"Auditor"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.auditorReportAccess === 1) {
                                  setFieldValue("auditorReportAccess", 0);
                                } else {
                                  setFieldValue("auditorReportAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.riskRegisterAccess === 1}
                                  name={"Risk Register"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Risk Register"}
                              key={"Risk"}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.riskRegisterAccess === 1) {
                                  setFieldValue("riskRegisterAccess", 0);
                                } else {
                                  setFieldValue("riskRegisterAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>

                          <MDTypography
                            textAlign="left"
                            mt={2}
                            mb={1}
                            variant="h6"
                            fontWeight="bold"
                          >
                            Advanced Features
                          </MDTypography>
                          <ListItem
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={values.policyTemplatesAccess === 1}
                                  name={"Policy Template Library"}
                                  sx={!darkMode ? { ml: 1, py: 1 } : { ml: 1 }}
                                />
                              }
                              label={"Policy Template Library"}
                              key={"Policy"}
                              disabled={values.policyScorecardAccess === 0}
                              // onChange={() => handleToggle()}
                              onChange={() => {
                                if (values.policyTemplatesAccess === 1) {
                                  setFieldValue("policyTemplatesAccess", 0);
                                } else {
                                  setFieldValue("policyTemplatesAccess", 1);
                                }
                              }}
                            />
                            <Tooltip title={"Tooltip"}>
                              <IconButton
                                sx={
                                  darkMode
                                    ? { fontSize: "1.2rem" }
                                    : { opacity: "0.4", fontSize: "1.2rem" }
                                }
                                pb={2}
                              >
                                <HelpIcon color="icon" />
                              </IconButton>
                            </Tooltip>
                          </ListItem>
                        </List>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <MDBox
                    sx={{ margin: "0 0 40px -40px" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-end"
                  >
                    {isUpdatingFeatures === false ? (
                      <MDButton
                        type="submit"
                        // variant="gradient"
                        color="success"
                        sx={{ padding: "12px 82px" }}
                      >
                        Save
                      </MDButton>
                    ) : (
                      <LoadingSpinner
                        subClass="text-center"
                        color="success"
                        size="lg"
                      />
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
};

export default EditConnectWiseClientForm;
