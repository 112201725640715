import { createSlice } from "@reduxjs/toolkit";
import { fetchCompanyUserById, updateCompanyUser } from "./companyUsersActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState,
  reducers: {
    // signupUser: (state,action) => {
    //   localStorage.removeItem("userToken"); // delete token from storage
    //   state.loading = false;
    //   state.userInfo = null;
    //   state.userToken = null;
    //   state.error = null;
    // },
  },
  extraReducers: {
    // fetchCompanyUserById
    [updateCompanyUser.pending]: (state) => {
      state.loading = true;
    },
    [updateCompanyUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateCompanyUserFormSuccess = payload;
    },
    [updateCompanyUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateCompanyUserFormSuccess = payload;
    },

    // fetchCompanyUserById
    [fetchCompanyUserById.pending]: (state) => {
      state.loading = true;
    },
    [fetchCompanyUserById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchCompanyUserByIdData = payload;
    },
    [fetchCompanyUserById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchCompanyUserByIdData = payload;
    },
  },
});

export default companyUsersSlice.reducer;
