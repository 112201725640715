/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";
import { IconButton, Tooltip } from "@mui/material";

import NameBtnTr from "./NameBtnTr";
const DataTableData = {
  columns: [
    {
      Header: "Title",
      accessor: "title",
      // Cell: ({ value, column, row }) => (
      //   <NameBtnTr value={value} column={column} row={row} />
      // ),
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    // {
    //   Header: "Frameworks",
    //   accessor: "current_rmfs",
    //   width: "15%",
    //   Cell: ({ value }) => (
    //     <>
    //       <Tooltip title={value} placement="top-end">
    //         <DefaultCell
    //           value={value.length > 20 ? value.slice(0, 20) + "..." : value}
    //         />
    //         <IconButton
    //           sx={{ opacity: "0.4", fontSize: "1.2rem" }}
    //           pb={2}
    //         ></IconButton>
    //       </Tooltip>
    //     </>
    //   ),
    // },
    {
      Header: "Published date",
      accessor: "created_at",
      width: "30%",
    },
  ],
  rows: [],
};

export default DataTableData;
