import React, { useCallback, useMemo } from "react";
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";

import { useDispatch } from "react-redux";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useLocation } from "react-router-dom";
import { showMsg } from "utils/general";
import MDTypography from "components/MDTypography";
import {
  Autocomplete,
  CardContent,
  Chip,
  FormControl,
  Grid,
  Icon,
  List,
  ListItem,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Form, Formik } from "formik";
import ComplianceToggles from "./components/ComplianceToggles";
import { FormControlLabel, IconButton, Switch, Tooltip } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { getNonExpiryKB } from "features/company/knowledgeBaseActions";
import DocumentsToggles from "./components/DocumentsToggles";
import { position } from "stylis";
import { getPublicData } from "features/company/TrustCenterActions";
import { saveOverviewChanges } from "features/company/TrustCenterActions";
import DOMPurify from "dompurify";
import brandDark from "assets/images/icons/logo.png";
import cis from "assets/images/icons/cis-v8.png";
import cmmc from "assets/images/icons/cmmc.png";
import cyber from "assets/images/icons/cyber-essentials.png";
import ftc from "assets/images/icons/ftc.png";
import pci from "assets/images/icons/pci.png";
import soc from "assets/images/icons/soc-2.png";

import { useMaterialUIController } from "context";
import AssessmentCharts from "views/components/GameCharts/AssessmentCharts";
import DocumentItem from "./components/DocumentItem";
import { savePointOfContactChanges } from "features/company/TrustCenterActions";

let objInitialValues = {
  overview: "",
};

const TrustCenter = (props) => {
  const { objUser } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [nonExpiryKB, setNonExpiryKB] = useState({});
  const [documents, setDocuments] = useState([]);
  const [rmfs, setRmfs] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [pointOfContacts, setPointOfContacts] = useState([]);
  const [contactsToAdd, setContactsToAdd] = useState();
  const [overview, setOverview] = useState();
  const [isEditingOverview, setIsEditingOverview] = useState(false);
  const [isEditingCompliance, setIsEditingCompliance] = useState(false);
  const [isEditingDocuments, setIsEditingDocuments] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [logo, setLogo] = useState(brandDark);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const saveOverview = (values) => {
    setIsLoading(true);
    dispatch(saveOverviewChanges({ objUser, overview: values.overview }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // showMsg("success", "Changes saved successfully");
        setOverview(values.overview);
        // fetchPublicData();
        if (Array.isArray(contactsToAdd)) {
          saveContacts();
        } else {
          showMsg("success", "Changes saved successfully");
          fetchPublicData();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);

        console.error(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong");
      });
  };

  const saveContacts = () => {
    setIsLoading(true);
    dispatch(savePointOfContactChanges({ objUser, contactsToAdd }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        showMsg("success", "Changes saved successfully");
        fetchPublicData();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);

        console.error(rejectedValueOrSerializedError);
        showMsg("error", "Something went wrong");
      });
  };

  const fetchNonExpiryKB = () => {
    setIsLoading(true);
    dispatch(getNonExpiryKB({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setNonExpiryKB(originalPromiseResult.data);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  const fetchPublicData = () => {
    setIsLoading(true);
    let clientId = objUser.user.current_client_id;
    dispatch(getPublicData({ clientId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setRmfs(originalPromiseResult.data.rmfs);
        setDocuments(originalPromiseResult.data.policies);
        setOverview(originalPromiseResult.data.overview);
        setContacts(originalPromiseResult.data.contacts);
        setPointOfContacts(originalPromiseResult.data.point_of_contacts);
        objInitialValues.overview = originalPromiseResult.data.overview;
        if (originalPromiseResult.data.logo) {
          setLogo(originalPromiseResult.data.logo);
        }
        setIsEditingOverview(false);
        setIsEditingDocuments(false);
        setIsEditingCompliance(false);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      })
      .finally(() => {});
  };

  const sanitizedText = (text) => {
    return DOMPurify.sanitize(text ?? "");
  };

  const getLogo = (text) => {
    switch (text) {
      case "CIS":
        return cis;
      case "CMMC":
        return cmmc;
      case "Cyber Insurance":
        return cyber;
      case "FTC":
        return ftc;
      case "SOC2":
        return soc;
      case "PCI-DSS":
        return pci;
      default:
        return brandDark;
    }
  };

  const shortenedText = (text) => {
    return text.length > 20 ? text.slice(0, 20) + "..." : text;
  };

  const previewTrustCenter = () => {
    window.open(`/${objUser.user.current_client_id}/trust-center`, "_blank");
  };

  const contactsAutocompleteGetLabelFunction = useCallback(
    (option) => {
      const contact = option?.first_name
        ? option
        : contacts.find((contact) => contact.id === option);

      return contact ? `${contact.first_name} ${contact.last_name}` : "";
    },
    [contacts]
  );

  const contactsAutocompleteOnChangeFunction = useCallback((e, value) => {
    let formattedValue = value.map((val) => {
      return val.id;
    });

    formattedValue = Array.from(new Set(formattedValue));
    setContactsToAdd(formattedValue);
  }, []);

  const formattedCurrentContacts = useMemo(() => {
    return contacts.filter((contact) => {
      return pointOfContacts.includes(contact.id);
    });
  }, [contacts, pointOfContacts]);

  useEffect(() => {
    fetchNonExpiryKB();
    fetchPublicData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        display="flex"
        justifyContent="space-between"
        mb={2}
        alignItem="center"
      >
        <MDTypography fontWeight="bold" variant="h4">
          Trust Center
        </MDTypography>
        <MDBox
          // to={"/" + objUser.user.current_client_id + "/trust-center"}
          target="_blank"
          color="dark"
          display="flex"
          alignItems="center"
          sx={{
            cursor: "pointer",
            marginRight: "30px",
            color: darkMode ? "white !important" : "text.primary",
          }}
        >
          <Icon
            sx={{ fontWeight: "bold" }}
            onClick={() => previewTrustCenter()}
          >
            visibility
          </Icon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
            onClick={() => previewTrustCenter()}
          >
            Preview
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3} width="100%">
          {isLoading ? (
            <Grid item xs={12} lg={23}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <MDTypography variant="p" fontWeight="regular">
                      <LoadingSpinner />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} lg={12}>
                <Card sx={{ height: "100%" }}>
                  <MDBox p={2}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      alignItem="center"
                    >
                      <MDTypography variant="h5" fontWeight="bold">
                        {/* Overview */}
                      </MDTypography>
                      {!isEditingOverview && (
                        <MDBox
                          mb={2}
                          color="dark"
                          display="flex"
                          alignItems="center"
                          sx={{
                            cursor: "pointer",
                            color: darkMode
                              ? "white !important"
                              : "text.primary",
                          }}
                          onClick={() => setIsEditingOverview(true)}
                        >
                          <Icon
                            sx={{
                              fontWeight: "bold",
                            }}
                          >
                            edit
                          </Icon>
                          <MDTypography
                            variant="p"
                            fontWeight="medium"
                            color="text.dark"
                            sx={{
                              lineHeight: 0,
                              marginLeft: "0.5rem",
                            }}
                          >
                            Edit
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                    {isEditingOverview ? (
                      <Formik
                        initialValues={objInitialValues}
                        // validationSchema={NewVersionFormJWTSchema}
                        onSubmit={saveOverview}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          isSubmitting,
                          setFieldValue,
                        }) => (
                          <Form id="AddNewPostForm" autoComplete="off">
                            <Grid container spacing={4}>
                              <Grid item xs={7}>
                                <FormField
                                  type="text"
                                  label="Enter Overview"
                                  name="overview"
                                  placeholder={"Enter Overview"}
                                  // error={errors.message && touched.message}
                                  // success={values.message.length > 0 && !errors.message}
                                  variant="textarea"
                                  fullWidth
                                  multiline
                                  autoFocus
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <FormControl
                                  sx={{ width: "100%", marginTop: "18px" }}
                                >
                                  <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    options={contacts}
                                    getOptionLabel={
                                      contactsAutocompleteGetLabelFunction
                                    }
                                    defaultValue={formattedCurrentContacts}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        label={"Point of Contact"}
                                        placeholder="Contact"
                                      />
                                    )}
                                    onChange={
                                      contactsAutocompleteOnChangeFunction
                                    }
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                            <MDBox
                              display="flex"
                              alignItems="end"
                              // sx={{ width: "100%", position:"abosute", top: "310px" }}
                              flexDirection="row-reverse"
                              gap={2}
                            >
                              {isLoading ? (
                                <LoadingSpinner />
                              ) : (
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  color="success"
                                  sx={{ padding: "12px 12px" }}
                                >
                                  Save changes
                                </MDButton>
                              )}
                            </MDBox>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <Grid container spacing={4}>
                        <Grid item xs={6}>
                          <MDTypography variant="h5" fontWeight="bold">
                            Overview
                          </MDTypography>
                          <MDTypography
                            variant="p"
                            fontWeight="regular"
                            dangerouslySetInnerHTML={{
                              __html: sanitizedText(overview),
                            }}
                          ></MDTypography>
                        </Grid>
                        <Grid item xs={6}>
                          <MDTypography variant="h5" fontWeight="bold">
                            Points of Contact
                          </MDTypography>
                          <TableContainer
                            component={Paper}
                            style={{ width: "100%" }}
                          >
                            <TableRow>
                              <TableCell
                                style={{
                                  width: "25%",
                                }}
                              >
                                <b>Name</b>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "25%",
                                }}
                              >
                                <b>Email</b>
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "25%",
                                }}
                              >
                                <b>Title</b>
                              </TableCell>
                            </TableRow>

                            <TableBody>
                              {formattedCurrentContacts ? (
                                <>
                                  {formattedCurrentContacts.map(
                                    (contact, id) => {
                                      return (
                                        <TableRow
                                          key={id}
                                          sx={{
                                            "&:last-child td, &:last-child th":
                                              {
                                                border: 0,
                                              },
                                          }}
                                        >
                                          <TableCell
                                            style={{
                                              width: "5%",
                                              minWidth: "100%",
                                            }}
                                          >
                                            {contact.first_name}{" "}
                                            {contact.last_name}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "5%",
                                              minWidth: "100%",
                                            }}
                                          >
                                            {contact.email}
                                          </TableCell>
                                          <TableCell
                                            style={{
                                              width: "5%",
                                              minWidth: "100%",
                                            }}
                                          >
                                            {contact.title}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </TableBody>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    )}
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      alignItem="center"
                    >
                      <MDTypography variant="h5" fontWeight="bold">
                        Policy Scorecard
                        <Tooltip
                          title="Show/hide the Policy Packs using the edit icon."
                          placement="right-end"
                        >
                          <IconButton
                            sx={
                              // darkMode
                              //   ? { fontSize: "1.2rem" }
                              { opacity: "0.4", fontSize: "1.2rem" }
                            }
                            pb={2}
                          >
                            <HelpIcon color="icon" />
                          </IconButton>
                        </Tooltip>
                      </MDTypography>
                      {!isEditingCompliance && (
                        <MDBox
                          color="dark"
                          display="flex"
                          alignItems="center"
                          sx={{
                            cursor: "pointer",
                            color: darkMode
                              ? "white !important"
                              : "text.primary",
                          }}
                          onClick={() => setIsEditingCompliance(true)}
                        >
                          <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                          <MDTypography
                            variant="p"
                            fontWeight="medium"
                            color="text.dark"
                            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                          >
                            Edit
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                    {isEditingCompliance ? (
                      <ComplianceToggles fetchPublicData={fetchPublicData} />
                    ) : (
                      <MDBox mt={4}>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            maxHeight: 500,
                            minHeight: 500,
                            "& ul": { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          <Grid container spacing={2}>
                            {rmfs.map((rmf, i) => {
                              return (
                                <>
                                  <Grid item xs={12} lg={4}>
                                    <Card
                                      sx={{ height: 120, maxHeight: 120 }}
                                      variant="outlined"
                                    >
                                      <CardContent
                                        sx={{
                                          height: "100%",
                                        }}
                                      >
                                        <MDBox
                                          mt={1}
                                          sx={{ height: "60%" }}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <img
                                            src={
                                              rmf.is_global == 1
                                                ? getLogo(rmf.name)
                                                : logo
                                            }
                                            style={{
                                              maxHeight: "100%",
                                              // height: "auto",
                                            }}
                                            alt=""
                                          />
                                        </MDBox>
                                        <MDBox
                                          display="flex"
                                          alignItem="center"
                                          justifyContent="center"
                                          mt={1}
                                          gap={1}
                                        >
                                          <MDBox
                                          // display="flex"
                                          // justifyContent="flex-end"
                                          >
                                            <Icon
                                              fontSize="small"
                                              color="success"
                                            >
                                              verified
                                            </Icon>
                                          </MDBox>
                                          <MDTypography
                                            fontSize="14px"
                                            variant="button"
                                          >
                                            {shortenedText(rmf.name)}
                                          </MDTypography>
                                        </MDBox>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        </List>
                      </MDBox>
                      // <>
                      //   <List
                      //     sx={{
                      //       width: "100%",
                      //       bgcolor: "background.paper",
                      //       position: "relative",
                      //       overflow: "auto",
                      //       maxHeight: 400,
                      //       "& ul": { padding: 0 },
                      //     }}
                      //     subheader={<li />}
                      //   >
                      //     {rmfs.map((rmf, i) => {
                      //       return (
                      //         <Card
                      //           sx={{ marginBottom: "20px", padding: "5px" }}
                      //         >
                      //           <ListItem key={i}>{rmf.name}</ListItem>
                      //         </Card>
                      //       );
                      //     })}
                      //   </List>
                      // </>
                    )}
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <AssessmentCharts objUser={objUser} trustCenterPage={true} />
              </Grid>

              {/* <Grid item xs={12} lg={6}>
                <Card sx={{ height: "100%" }}>
                  <MDBox p={2} sx={{ height: "100%" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      alignItem="center"
                    >
                      <MDTypography variant="h5" fontWeight="bold">
                        Assessment Scorecard
                      </MDTypography>
                      <MDBox display="flex" gap={2}>
                        <Link
                          to={`/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${nonExpiryKB.id}/${nonExpiryKB.shared_link_id}`}
                          target="_blank"
                        >
                          <MDButton type="button" color="success">
                            View Knowledge Base
                          </MDButton>
                        </Link>
                        {!isEditingDocuments && (
                          <MDBox
                            color="dark"
                            display="flex"
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                              color: darkMode
                                ? "white !important"
                                : "text.primary",
                            }}
                            onClick={() => setIsEditingDocuments(true)}
                          >
                            <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                            <MDTypography
                              variant="p"
                              fontWeight="medium"
                              color="text.dark"
                              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                            >
                              Edit
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                    {isEditingDocuments ? (
                      <DocumentsToggles fetchPublicData={fetchPublicData} />
                    ) : (
                      <MDBox mt={4}>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            maxHeight: 500,
                            minHeight: 500,
                            "& ul": { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          <Grid container spacing={2}>
                            {documents.map((document, i) => {
                              return (
                                <>
                                  <Grid item xs={12} lg={4}>
                                    <Card
                                      sx={{ height: 120, maxHeight: 120 }}
                                      variant="outlined"
                                    >
                                      <CardContent
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          height: "100%",
                                        }}
                                      >
                                        <MDTypography
                                          fontSize="14px"
                                          variant="button"
                                        >
                                          {shortenedText(document.title)}
                                        </MDTypography>
                                        <MDBox
                                          display="flex"
                                          justifyContent="flex-end"
                                        >
                                          <Icon fontSize="medium">article</Icon>
                                        </MDBox>
                                      </CardContent>
                                    </Card>
                                  </Grid>
                                </>
                              );
                            })}
                          </Grid>
                        </List>
                      </MDBox>
                    )}
                  </MDBox>
                </Card>
              </Grid> */}

              <Grid item xs={12}>
                <Card sx={{ height: "100%" }}>
                  <MDBox p={2} sx={{ height: "100%" }}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      mb={2}
                      alignItem="center"
                    >
                      <MDTypography variant="h5" fontWeight="bold">
                        Documents
                      </MDTypography>
                      <MDBox display="flex" gap={2}>
                        <Link
                          to={`/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${nonExpiryKB.id}/${nonExpiryKB.shared_link_id}`}
                          target="_blank"
                        >
                          <MDButton type="button" color="success">
                            View Knowledge Base
                          </MDButton>
                        </Link>
                        {!isEditingDocuments && (
                          <MDBox
                            color="dark"
                            display="flex"
                            alignItems="center"
                            sx={{
                              cursor: "pointer",
                              color: darkMode
                                ? "white !important"
                                : "text.primary",
                            }}
                            onClick={() => setIsEditingDocuments(true)}
                          >
                            <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                            <MDTypography
                              variant="p"
                              fontWeight="medium"
                              color="text.dark"
                              sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                            >
                              Edit
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </MDBox>
                    {isEditingDocuments ? (
                      <DocumentsToggles fetchPublicData={fetchPublicData} />
                    ) : (
                      <MDBox mt={4}>
                        <List
                          sx={{
                            width: "100%",
                            bgcolor: "background.paper",
                            position: "relative",
                            overflow: "auto",
                            maxHeight: 500,
                            minHeight: 500,
                            "& ul": { padding: 0 },
                          }}
                          subheader={<li />}
                        >
                          <Grid container spacing={2}>
                            {documents.map((document, i) => {
                              return (
                                <>
                                  {/* <Grid item xs={12} lg={2}>
                                    <Card
                                      sx={{ height: 120, maxHeight: 120 }}
                                      variant="outlined"
                                    >
                                      <CardContent
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-between",
                                          height: "100%",
                                        }}
                                      >
                                        <MDTypography
                                          fontSize="14px"
                                          variant="button"
                                        >
                                          {shortenedText(document.title)}
                                        </MDTypography>
                                        <MDBox
                                          display="flex"
                                          justifyContent="flex-end"
                                        >
                                          <Icon fontSize="medium">article</Icon>
                                        </MDBox>
                                      </CardContent>
                                    </Card>
                                  </Grid> */}
                                  <DocumentItem
                                    policy={document}
                                    kbs={`/kbs/${objUser.user.company_id}/${objUser.user.current_client_id}/${nonExpiryKB.id}/${nonExpiryKB.shared_link_id}`}
                                    newPage
                                  />
                                </>
                              );
                            })}
                          </Grid>
                        </List>
                      </MDBox>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default TrustCenter;
