import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

export const fetchAllPlatformStats = createAsyncThunk(
  "client/fetchAllPlatformStats",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/platform-stats/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllCompanies = createAsyncThunk(
  "client/fetchAllCompanies",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/companies/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const toggleCompanyReportState = createAsyncThunk(
  "company/toggleCompanyReportStae",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/companies/toggle-report`,
        objPostData
      );
      // return res.data;

      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchClientsForCompany = createAsyncThunk(
  "client/fetchAllClientsForCompany",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, company_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/companies/${company_id}/clients/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const validatePassword = createAsyncThunk(
  "client/validatePassword",
  async (values, thunkApi) => {
    try {
      const { password } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/validate-password`,
        {
          password: password,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**Global templates */
export const fetchGlobalTemplates = createAsyncThunk(
  "fetchGlobalTemplates",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplate = createAsyncThunk(
  "template/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchGlobalTemplate = createAsyncThunk(
  "template/fetch",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveGlobalTemplateChanges = createAsyncThunk(
  "template/saveTemplateChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const publishGlobalTemplate = createAsyncThunk(
  "template/publishGlobalTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/publish/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const unPublishGlobalTemplate = createAsyncThunk(
  "template/unPublishGlobalTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/unpublish/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const discardDraft = createAsyncThunk(
  "template/discardDraft",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/templates/discard-draft/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**RMF */
export const getRMF = createAsyncThunk(
  "company/getRMF",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/rmfs`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addRMF = createAsyncThunk(
  "company/addRMF",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/rmfs`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRMFData = createAsyncThunk(
  "company/deleteRMFData",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/rmfs/${dataFromActionBtn.rmfId}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveRMFChanges = createAsyncThunk(
  "company/saveCategory",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/rmfs/${objPostData.objPostData.id}`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**save policy packs changes */
export const savePolicyPackChanges = createAsyncThunk(
  "company/savePolicyPackChanges",
  async (values, thunkApi) => {
    try {
      const { modifiedTemplateData, currentSelectedRMF } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/packs/`,
        {
          updatedTemplates: modifiedTemplateData,
          currentSelectedRMF: currentSelectedRMF,
        }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const peerGroupAdminUsersList = createAsyncThunk(
  "company/companyUsersList",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-admins?per_page=${_per_page}&page=${_page}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllFifthWallStats = createAsyncThunk(
  "fifthwall/fetch",
  async (thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/fifthwall`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**company template actions */
export const getAssessmentTemplates = createAsyncThunk(
  "assessmentTemplate/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/assessment-templates`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const approveAssessmentTemplateShare = createAsyncThunk(
  "assessmentTemplate/share",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/assessment-templates/approve-share/${templateId}`,
        {}
      );
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error);
      throw error;
    }
  }
);

export const denyAssessmentTemplateShare = createAsyncThunk(
  "assessmentTemplate/share",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/assessment-templates/deny-share/${templateId}`,
        {}
      );
      return res;
    } catch (error) {
      // return thunkApi.rejectWithValue(error);
      throw error;
    }
  }
);

export const publishGlobalAssessmentTemplate = createAsyncThunk(
  "template/publishGlobalAssessmentTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/assessment-templates/publish/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const unPublishGlobalAssessmentTemplate = createAsyncThunk(
  "template/unPublishGlobalAssessmentTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/assessment-templates/unpublish/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateNewVersionInfo = createAsyncThunk(
  "updateNewVersionInfo",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/new-version-info`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getNewVersionInfo = createAsyncThunk(
  "getNewVersionInfo",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/new-version-info`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getConfigurables = createAsyncThunk(
  "getConfigurables",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/configurables`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getGlobalAssessmentTemplates = createAsyncThunk(
  "getGlobalAssessmentTemplates",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/global-assessment-templates`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const filterAssessmentTemplateQuestions = createAsyncThunk(
  "superadmin/filterAssessmentTemplateQuestions",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/questions-for-assessment-template`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addQuestionsToExistingAssessmentEventsAndTemplates =
  createAsyncThunk(
    "superadmin/addQuestionsToExistingAssessmentEventsAndTemplates",
    async (values, thunkApi) => {
      try {
        const { objPostData } = values;

        const res = await axios.post(
          `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/add-questions`,
          objPostData
        );
        return res;
      } catch (error) {
        return thunkApi.rejectWithValue(error);
      }
    }
  );

export const fetchAllPlatformUsers = createAsyncThunk(
  "fetchAllPlatformUsers ",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/platform-users`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resetTwoFA = createAsyncThunk(
  "superadmin/resetTwoFA",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/reset-two-fa`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const cancelMSPAccount = createAsyncThunk(
  "superadmin/cancelMSPAccount",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/cancel-msp-account`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchAllUnAssignedUsers = createAsyncThunk(
  "superadmin/fetchAllUnAssignedUsers ",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/unassigned-users`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const reassignUserToCompany = createAsyncThunk(
  "superadmin/reassignUserToCompany",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/toolkit/reassign-user-to-company`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
