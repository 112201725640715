import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { VERSION_SERVER } from "components/utils/constants/misc";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Switch from "@mui/material/Switch";
import { Editor } from "@tinymce/tinymce-react";
import { TINYMC_API_KEY } from "components/utils/constants/misc";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { useState } from "react";
import { TagsInput } from "react-tag-input-component";
import MergeTagsInfo from "views/components/MergeTagsInfo";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useMaterialUIController } from "context";
import "./EditTemplateFormikWrapper.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const EditTemplateFormikWrapper = (props) => {
  const {
    isClientUser,
    values,
    errors,
    touched,
    setFieldValue,
    objStates,
    objTemplate,
    documentSectionsToSave,
    editorDocument,
    setEditorDocument,
    showReferenceMaterial,
    handleUpdateDocumentSectionsToSave,
    handleAutoSave,
    savingSectionIndex,
    btnClickSectionSave,
    editorRef,
    readOnly,
    mergeTags,
  } = props;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [selectedTabs, setSelectedTabs] = useState({});
  const [editorsToShow, setEditorsToShow] = useState([]);

  const showEditor = (section_id) => {
    let updatedEditorsToShow = [...editorsToShow];
    const index = updatedEditorsToShow.indexOf(section_id);
    if (index !== -1) {
      updatedEditorsToShow.splice(index, 1);
    } else {
      updatedEditorsToShow.push(section_id);
    }
    setEditorsToShow(updatedEditorsToShow);
  };

  function handleChange(frameworkId) {
    let newValues = values.risk_management_frameworks.map((framework) => {
      return framework.id === frameworkId
        ? { ...framework, selected: !framework.selected }
        : framework;
    });
    setFieldValue("risk_management_frameworks", newValues);
  }

  const mergeTagsList = [
    {
      title: "Global",
      menu:
        mergeTags?.global?.map((tag) => ({
          value: tag.name,
          title: tag.description,
        })) ?? [],
    },
  ];
  if (mergeTags?.company?.length > 0) {
    mergeTagsList.push({
      title: "Company",
      menu:
        mergeTags?.company?.map((tag) => ({
          value: tag.name,
          title: tag.description ?? tag.name,
        })) ?? [],
    });
  }

  const handleTabChange = (tabValue, newValue) => {
    setSelectedTabs((prevState) => ({
      ...prevState,
      [tabValue]: newValue,
    }));
  };

  return (
    <MDBox my={3} textAlign="left">
      <MDBox px={5} textAlign="left" mx="auto" mt={4}>
        <Grid
          container
          justifyContent="space-between"
          columnSpacing={2}
          rowSpacing={3}
        >
          <Grid item xs={12} md={6} pt={0}>
            <MDBox mb={0}>
              <MDBox mb={0}>
                {values.is_global_template ? (
                  <MDTypography variant="h5" fontWeight="bold">
                    Preview template
                  </MDTypography>
                ) : (
                  <MDTypography variant="h5" fontWeight="bold">
                    Edit template
                  </MDTypography>
                )}
              </MDBox>
              <MDTypography variant="body2" color="text">
                Template details
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={0} pt={2} px={5} pb={5}>
        <Grid container justifyContent="space-between" columnSpacing={5}>
          {/* Left column */}
          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                type="text"
                label="Template Name"
                name="template_name"
                value={values.template_name}
                placeholder={""}
                disabled={isClientUser}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <label className="tags-label" htmlFor="templateTags">
                Template Tags
              </label>
              <TagsInput
                disabled={isClientUser}
                value={values.tags}
                onChange={(value) => {
                  if (value === null) {
                    setFieldValue("templateTags", []);
                  } else {
                    setFieldValue("templateTags", value);
                  }
                }}
                name="templateTags"
                placeHolder=""
                className="tags-input-container"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <FormLabel sx={{ fontSize: "0.75em" }}>
              Policy Packs (RMFs)
            </FormLabel>

            {values.risk_management_frameworks.map((framework, i) => {
              return (
                <FormControlLabel
                  control={
                    <Switch
                      checked={framework.selected ? true : false}
                      name={
                        framework.owning_rmf.name
                          ? framework.owning_rmf.name.replace(/\s/g, "")
                          : ""
                      }
                      disabled={values.is_global_template || isClientUser}
                    />
                  }
                  label={
                    framework.owning_rmf.name ? framework.owning_rmf.name : ""
                  }
                  key={framework.id}
                  onChange={() => handleChange(framework.id)}
                />
              );
            })}
          </Grid>

          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                pt={0}
                type="text"
                label="Short Description"
                name="description"
                value={values.description}
                placeholder={""}
                error={errors.description && touched.description}
                success={values.description > 0 && !errors.description}
                disabled={isClientUser}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} lg={12} rowSpacing={3}>
            <Grid item xs={12} pt={0}>
              <FormField
                pt={0}
                type="text"
                label="Notes"
                name="notes"
                value={values.notes}
                placeholder={""}
                error={errors.notes && touched.notes}
                success={values.notes > 0 && !errors.notes}
                disabled={isClientUser}
              />
            </Grid>
          </Grid>

          <Grid
            className="no-pt-element"
            container
            mt={3}
            item
            xs={12}
            columnSpacing={8}
            pt={0}
          >
            <Grid item xs={12}>
              {objTemplate.doc_type === "oscal" ? (
                documentSectionsToSave.map((section, index) => {
                  return (
                    <Grid
                      container
                      item
                      xs={12}
                      lg={12}
                      rowSpacing={3}
                      key={index}
                      mb={5}
                    >
                      <Grid item xs={12} pt={0}>
                        {/* Accordion start */}
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon color="icon" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                              flexDirection: "row-reverse",
                              borderBottom: "1px solid #ababab",
                              background: darkMode ? "#2f3958" : "#ededed",
                            }}
                          >
                            <MDTypography variant="h6" p={1}>
                              {section.owning_global_section.title}
                            </MDTypography>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              backgroundColor: darkMode ? "#2f3958" : "#e5e9ec",
                            }}
                          >
                            {/* About section */}
                            {showReferenceMaterial && (
                              <Accordion
                                style={{
                                  marginBottom: "25px",
                                  marginTop: "16px",
                                  borderRadius: "5px",
                                  boxShadow: "none",
                                }}
                                sx={
                                  darkMode
                                    ? { backgroundColor: "#fff" }
                                    : { backgroundColor: "#fff" }
                                }
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon color="icon" />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <MDTypography
                                    variant="h6"
                                    style={{
                                      fontSize: "15px",
                                      color: "grey",
                                    }}
                                  >
                                    Reference Material
                                  </MDTypography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <MDBox>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: `<div class='dangerousHTMLDIVClass'>${section.section_about}</div>`,
                                      }}
                                    />
                                  </MDBox>
                                </AccordionDetails>
                              </Accordion>
                            )}

                            <MDBox mt={2}>
                              <div
                                style={{
                                  display: `${
                                    editorsToShow.includes(section.id)
                                      ? "block"
                                      : "none"
                                  }`,
                                }}
                              >
                                {section.owning_global_section.title ===
                                  "Policy" && (
                                  <MergeTagsInfo mergeTags={mergeTags} />
                                )}
                                <Editor
                                  disabled={isClientUser}
                                  onInit={(evt, editor) => {
                                    if (
                                      section.owning_global_section.title ===
                                      "Policy"
                                    ) {
                                      editorRef.current = editor;
                                    }
                                  }}
                                  key={index + section.id}
                                  apiKey={TINYMC_API_KEY}
                                  value={section.section_content}
                                  init={{
                                    branding: false,
                                    height: 500,
                                    menubar: false,
                                    advcode_inline: true,
                                    automatic_uploads: true,
                                    mergetags_list: mergeTagsList,
                                  }}
                                  onEditorChange={(newValue, editor) => {
                                    handleUpdateDocumentSectionsToSave(
                                      "section_content",
                                      index,
                                      newValue
                                    );
                                  }}
                                  plugins={[
                                    "anchor",
                                    "autolink",
                                    "advcode",
                                    "link",
                                    "lists",
                                    "searchreplace",
                                    "table",
                                    "visualblocks",
                                    "wordcount",
                                    "checklist",
                                    "mediaembed",
                                    "casechange",
                                    "export",
                                    "formatpainter",
                                    "pageembed",
                                    "linkchecker",
                                    "a11ychecker",
                                    "tinymcespellchecker",
                                    "permanentpen",
                                    "powerpaste",
                                    "advtable",
                                    "advcode",
                                    "editimage",
                                    "tableofcontents",
                                    "footnotes",
                                    "mergetags",
                                    "autocorrect",
                                    "fullscreen",
                                    "inlinecss",
                                  ]}
                                  toolbar={[
                                    "fullscreen undo redo | blocks fontfamily fontsize | " +
                                      "bold italic underline strikethrough | removeformat code",
                                    "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                                      "checklist numlist bullist indent outdent ",
                                  ]}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  maxHeight: "500px",
                                  overflow: "auto",
                                  padding: "5px",
                                  borderRadius: "5px",
                                  display: `${
                                    editorsToShow.includes(section.id)
                                      ? "none"
                                      : "block"
                                  }`,
                                }}
                              >
                                <div
                                  style={{ color: darkMode ? "white" : "" }}
                                  dangerouslySetInnerHTML={{
                                    __html: `<div class='dangerousHTMLDIVClass'>${section.section_content}</div>`,
                                  }}
                                />
                              </div>
                              {!values.is_global_template &&
                                (savingSectionIndex === index ? (
                                  <MDBox
                                    sx={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    <LoadingSpinner color="success" size="lg" />
                                  </MDBox>
                                ) : (
                                  <MDBox
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                    }}
                                  >
                                    <MDButton
                                      color="dark"
                                      onClick={() => showEditor(section.id)}
                                      sx={{
                                        marginTop: "20px",
                                        display: `${
                                          editorsToShow.includes(section.id)
                                            ? "none"
                                            : "block"
                                        }`,
                                      }}
                                    >
                                      Edit Section Content
                                    </MDButton>
                                    <MDButton
                                      color="dark"
                                      onClick={() =>
                                        btnClickSectionSave(index, () =>
                                          showEditor(section.id)
                                        )
                                      }
                                      sx={{
                                        marginTop: "20px",
                                        display: `${
                                          editorsToShow.includes(section.id)
                                            ? "block"
                                            : "none"
                                        }`,
                                      }}
                                    >
                                      Save Section Content
                                    </MDButton>
                                  </MDBox>
                                ))}
                            </MDBox>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <>
                  <MergeTagsInfo mergeTags={mergeTags} />
                  <Editor
                    disabled={readOnly || isClientUser}
                    apiKey={TINYMC_API_KEY}
                    onInit={(evt, editor) => {
                      editorRef.current = editor;
                    }}
                    onEditorChange={(newValue, editor) => {
                      setEditorDocument(newValue);
                    }}
                    // initialValue={values.document}
                    value={editorDocument}
                    init={{
                      branding: false,
                      height: 400,
                      menubar: false,
                      advcode_inline: true,
                      automatic_uploads: true,
                      images_upload_url:
                        process.env.REACT_APP_BACKEND_API_URL +
                        VERSION_SERVER +
                        "/upload-images-to-server",
                      external_plugins: {
                        polygonAutosave:
                          process.env.PUBLIC_URL +
                          "/autoSaveMCEPlugin/autoSaveMCEPlugin.js",
                        polygonVersionBrowser:
                          process.env.PUBLIC_URL +
                          "/versionBrowserPlugin/versionBrowserPlugin.js",
                      },
                      polygonAutosave_autosaveCall: handleAutoSave,
                      polygonAutosave_interval: "30s",
                      polygonVersionBrowser_versionsCallback:
                        props.documentVersions,
                      polygonVersionBrowser_fetchVersionCallback:
                        props.fetchDocumentVersion,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      mergetags_list: mergeTagsList,
                    }}
                    plugins={[
                      "anchor",
                      "autolink",
                      "advcode",
                      "link",
                      "lists",
                      "searchreplace",
                      "table",
                      "visualblocks",
                      "wordcount",
                      "checklist",
                      "mediaembed",
                      "casechange",
                      "export",
                      "formatpainter",
                      "pageembed",
                      "linkchecker",
                      "a11ychecker",
                      "tinymcespellchecker",
                      "permanentpen",
                      "powerpaste",
                      "advtable",
                      "advcode",
                      "editimage",
                      "tableofcontents",
                      "footnotes",
                      "mergetags",
                      "autocorrect",
                      "fullscreen",
                      "inlinecss",
                    ]}
                    toolbar={[
                      "fullscreen undo redo | blocks fontfamily fontsize | " +
                        "bold italic underline strikethrough | removeformat code",
                      "link table mergetags | spellcheckdialog a11ycheck | align lineheight | " +
                        "checklist numlist bullist indent outdent ",
                    ]}
                  />
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
};

export default EditTemplateFormikWrapper;
