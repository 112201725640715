import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/**company peer group actions */
export const createPeerGroupPost = createAsyncThunk(
  "peerGroup/create",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;
      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (value instanceof FileList || value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPost = createAsyncThunk(
  "peerGroup/fetch",
  async (thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/peer-group-post/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPostSuper = createAsyncThunk(
  "peerGroup/fetchSuper",
  async (thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-peer-group-post/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePeerGroupPost = createAsyncThunk(
  "peerGroup/update",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (value instanceof FileList || value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/${objPostData.id}/`,
        formData,
        { "Content-Type": "multipart/form-data" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchPeerGroupPostDetails = createAsyncThunk(
  "peerGroup/details",
  async (values, thunkApi) => {
    try {
      const { post_id } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/peer-group-post/${post_id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deletePeerGroupPost = createAsyncThunk(
  "peerGroup/details",
  async (values, thunkApi) => {
    try {
      const { post_id } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/peer-group-post/${post_id}`
      );
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
