import { useState, useEffect } from "react";
import { Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
function CheckApi() {
  const [status, setStatus] = useState();
  const fetchApiStatus = async () => {
    const response = await fetch("https://staging-api.compliancerisk.io/api/status");
    const data = await response.json();
    setStatus(data.status);
  };
  useEffect(() => {
    fetchApiStatus();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: "1rem" }}>
        <MDTypography fontSize="2rem" fontWeight="bold">
          Api Status: {status}
        </MDTypography>
      </Card>
    </DashboardLayout>
  );
}

export default CheckApi;
