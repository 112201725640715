/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";

const ClientContactsDataTableData = {
  columns: [
    {
      Header: "first name",
      accessor: "firstname",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "last name",
      accessor: "lastname",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "email",
      accessor: "email",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },

    // {
    //   Header: "status",
    //   accessor: "status",
    //   // Cell: ({ value, column, row }) => {
    //   //   return <div onClick={() => console.log(row.original)}>Click Me</div>;
    //   // },
    //   Cell: ({ value, column, row }) => (
    //     <CustomerStatusBtnTr value={value} column={column} row={row} />
    //   ),
    // },
  ],
  rows: [],
};

export default ClientContactsDataTableData;
