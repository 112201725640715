import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";

import { useDispatch } from "react-redux";
import {
  getAssessmentItemScoring,
  updateAssessmentItemScoring,
} from "../../../../../features/assessmentConfig/assessmentConfigActions";

import { getAssessmentTemplates } from "features/company/assessmentsActions";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import { Grid, Select, MenuItem } from "@mui/material";

import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

import tinycolor from "tinycolor2";
import { useMaterialUIController } from "context";

const AssessmentData = (props) => {
  const { objUser } = props;

  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [colorOptions, setColorOptions] = useState(null);
  const [currentAssessment, setCurrentAssessment] = useState("");
  const [assessments, setAssessments] = useState([]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const fetchCurrentScoring = () => {
    setIsLoading(true);
    dispatch(getAssessmentItemScoring({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const results = originalPromiseResult.data.assessment_item_scoring;
        const scores = results.map((score) => {
          return {
            code: score.code,
            name: score.name,
            score_value: score.value,
            possible_points: score.possible_points,
            color: score.color,
            readonly: score.code == "0" || score.code == 0 || score.code == 1,
          };
        });

        setFields(scores);
        setColorOptions(originalPromiseResult.data.color_options);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        showMsg("error", objErrorRsp?.data?.message);
        showMsg("error", objErrorRsp?.data?.error_code);
      });
  };

  useEffect(() => {
    if (
      objUser.user &&
      objUser.user.role &&
      objUser.user.role.name != "admin" &&
      objUser.user.is_super_admin != 1 &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    fetchCurrentScoring();
    loadTemplates();
  }, []);

  const handleChange = (index, fieldName, value) => {
    let updatedFields = [...fields];
    const currentIndex = index;
    let newErrors = [];
    setErrors([]);

    /**empty current input */
    if (value === "") {
      newErrors.push("Please enter a value for current row.");
    }

    /**any empty existing inputs */
    const rowsWithEmptyInputs = updatedFields.filter((field, index) => {
      return (
        (field.name === "" ||
          field.score_value === "" ||
          field.possible_points === "") &&
        index != currentIndex
      );
    });

    if (rowsWithEmptyInputs.length > 0) {
      newErrors.push("No inputs must be left empty.");
    }

    if (fieldName == "possible_points") {
      updatedFields = updatedFields.map((field, index) => {
        return field.code == "0" || field.code == 0 || field.code == 1
          ? { ...field }
          : { ...field, possible_points: value };
      });
    }

    setErrors(newErrors);

    updatedFields[index][fieldName] = value;

    setFields(updatedFields);
  };

  const handleTemplateChange = (value) => {
    setCurrentAssessment(value);
    loadTemplateItemScores(value.id);
  };

  const loadTemplateItemScores = (template_id) => {
    setIsLoading(true);
    setFields([]);
    dispatch(getAssessmentItemScoring({ objUser, template_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const results = originalPromiseResult.data.assessment_item_scoring;
        const scores = results.map((score) => {
          return {
            code: score.code,
            name: score.name,
            score_value: score.value,
            possible_points: score.possible_points,
            color: score.color,
            readonly: score.code == "0" || score.code == 0 || score.code == 1,
          };
        });

        setFields(scores);
        setColorOptions(originalPromiseResult.data.color_options);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  const loadTemplates = () => {
    setIsLoading(true);
    dispatch(getAssessmentTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        if (response.data.templates.length > 0) {
          response.data.templates = response.data.templates.filter(
            (template) => {
              return template.is_global_template === 0;
            }
          );
          if (response.data.templates.length > 0) {
            setAssessments(response.data.templates);
            setCurrentAssessment(response.data.templates[0]);
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      })
      .finally(() => {});
  };

  const lightenColor = (color) => {
    const colorObject = colorOptions.find((item) => {
      return item.square_color === color;
    });

    if (colorObject != undefined && colorObject) {
      return colorObject.row_color;
    } else {
      const colorObj = tinycolor(color);
      return colorObj.isDark()
        ? colorObj.brighten(60).toString()
        : colorObj.darken(5).toString();
    }
  };

  const saveAssessmentScoringChanges = async () => {
    setIsSavingChanges(true);
    let template_id = currentAssessment.id;
    if (errors.length == 0) {
      dispatch(updateAssessmentItemScoring({ objUser, fields, template_id }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsSavingChanges(false);
          showMsg(
            "success",
            "Assessment item scoring settings saved successfully"
          );
          setErrors([]);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsSavingChanges(false);
          console.log(rejectedValueOrSerializedError);
          if (rejectedValueOrSerializedError.response.data.errors) {
            rejectedValueOrSerializedError.response.data.errors.forEach(
              (error) => {
                showMsg("error", error);
              }
            );
          } else if (rejectedValueOrSerializedError.response.data.message) {
            showMsg(
              "error",
              rejectedValueOrSerializedError.response.data.message
            );
          } else {
            showMsg("error", "Error saving changes");
          }
        });
    } else {
      setIsSavingChanges(false);
      showMsg("info", "Please fix the errors first");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          {/* custom */}

          {errors.length > 0 ? (
            <Grid item xs={12} lg={12}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Errors with scoring ranges:
                  </MDTypography>
                  {errors.map((error, index) => {
                    return (
                      <>
                        <MDTypography
                          key={index}
                          variant="p"
                          fontWeight="regular"
                          sx={{ color: "red" }}
                        >
                          {error}
                        </MDTypography>
                        <br />
                      </>
                    );
                  })}
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} lg={12}>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox display="flex" alignItems="center">
                <MDTypography variant="body2" fontWeight={"bold"}>
                  Assessment Template :
                </MDTypography>

                {/* select template */}
                <Select
                  value={currentAssessment}
                  sx={{
                    minWidth: "150px",
                    marginLeft: "10px",
                    backgroundColor: "white",
                    padding: "8px",
                    borderWidth: "4px",
                  }}
                  onChange={(e) => handleTemplateChange(e.target.value)}
                >
                  <MenuItem key={"Global"} value={{ name: "Global", id: null }}>
                    Global
                  </MenuItem>
                  {assessments.map((event) => (
                    <MenuItem key={event.id} value={event}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
                <UnfoldMoreIcon
                  sx={{
                    position: "relative",
                    right: "20px",
                    pointerEvents: "none",
                  }}
                />
              </MDBox>
              {isSavingChanges ? (
                <LoadingSpinner color="success" size="lg" />
              ) : (
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  onClick={() => saveAssessmentScoringChanges()}
                  sx={{
                    padding: "10px 17px",
                    margin: "0px 5px",
                  }}
                >
                  Save Assessment Item Scoring Rules
                </MDButton>
              )}
            </MDBox>
            <Card sx={{ marginTop: "16px" }}>
              <TableContainer sx={{ padding: "1rem" }}>
                {isLoading && fields.length == 0 ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <Table>
                    <TableRow sx={{ color: darkMode && "whitesmoke" }}>
                      <TableCell>
                        <b>Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Value</b>
                      </TableCell>
                      <TableCell>
                        <b>Possible Points</b>
                      </TableCell>
                      <TableCell>
                        <b>Color</b>
                      </TableCell>
                    </TableRow>

                    <TableBody>
                      {fields.map((field, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor: lightenColor(field.color),
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <TextField
                                value={field.name}
                                onChange={(e) =>
                                  handleChange(index, "name", e.target.value)
                                }
                                sx={{
                                  backgroundColor: darkMode
                                    ? "#202940"
                                    : "#fff",
                                  color: darkMode && "whitesmoke",
                                  borderRadius: "5px",
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextField
                                sx={{
                                  backgroundColor: darkMode
                                    ? "#202940"
                                    : "#fff",
                                  color: darkMode ? "whitesmoke" : "inherit",
                                  borderRadius: "5px",
                                  "& .css-5dsoqz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                                    {
                                      "-webkit-text-fill-color": darkMode
                                        ? "gray"
                                        : "inherit",
                                    },
                                }}
                                value={field.score_value}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "score_value",
                                    e.target.value
                                  )
                                }
                                disabled={field.readonly ? true : false}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextField
                                sx={{
                                  backgroundColor: darkMode
                                    ? "#202940"
                                    : "#fff",
                                  color: darkMode ? "whitesmoke" : "inherit",
                                  borderRadius: "5px",
                                  "& .css-5dsoqz-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled":
                                    {
                                      "-webkit-text-fill-color": darkMode
                                        ? "gray"
                                        : "inherit",
                                    },
                                }}
                                value={field.possible_points}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "possible_points",
                                    e.target.value
                                  )
                                }
                                disabled={field.readonly ? true : false}
                              />
                            </TableCell>
                            <TableCell>
                              <Select
                                onChange={(e) =>
                                  handleChange(index, "color", e.target.value)
                                }
                                sx={{ backgroundColor: field.color }}
                              >
                                {colorOptions.map((option) => (
                                  <MenuItem
                                    key={option.square_color}
                                    value={option.square_color}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "20px",
                                        backgroundColor: option.square_color,
                                      }}
                                    ></div>
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentData;
