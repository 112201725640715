import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const LoadingSpinner = (props) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress
        color="success"
        size={
          props.size == "sm" ? "1rem" : props.size == "md" ? "2rem" : "2.5rem"
        }
      />
    </Box>
  );
};

export default LoadingSpinner;
