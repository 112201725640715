/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";
const PlatformStatsDataTableData = {
  columns: [
    {
      Header: "msp count",
      accessor: "mspCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "client count",
      accessor: "clientsCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "total accounts",
      accessor: "totalAccounts",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "total policies",
      accessor: "policiesCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "authorized policy count",
      accessor: "authorizedPoliciesCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "published policy count",
      accessor: "publishedPoliciesCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "assessment event count",
      accessor: "assessmentEventCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "total assets synced",
      accessor: "sumOfAssetsSynced",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "asset list count",
      accessor: "assetPolicyCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "adopted policy count",
      accessor: "adoptedPolicyCount",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default PlatformStatsDataTableData;
