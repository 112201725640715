import Card from "@mui/material/Card";
import { useEffect, useMemo, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardButton from "views/components/DashboardButtton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";

import { unwrapResult } from "@reduxjs/toolkit";
import { getAssessmentTemplates } from "features/company/assessmentsActions";
import { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import globalDataTableData from "./components/GlobalTemplatesDataTableData.js";
import sharedDataTableData from "./components/SharedTemplatesDataTableData.js";
import localDataTableData from "./components/LocalTemplatesDataTableData";
import TemplatesDatatable from "./components/TemplatesDataTable";
import CreateNewTemplateDialog from "./components/createNewTemplateDialog";
import ImportTemplateCSVDialog from "./components/ImportTemplateCSVDialog";

const AssessmentTemplates = (props) => {
  const { objUser } = props;
  const [openNewTemplateDialog, setOpenNewTemplateDialog] = useState(false);
  const [openImportTemplateDialogCSV, setOpenImportTemplateDialogCSV] =
    useState(false);
  const [isLoadingClientTemplates, setIsLoadingClientTemplates] =
    useState(false);
  const [objLocalTableData, setObjLocalTableData] = useState(
    localDataTableData(objUser, () => fetchAssessmentTemplates())
  );
  const [objGlobalPlusTableData, setObjGlobalPlusTableData] =
    useState(globalDataTableData);
  const [objGlobalLiteTableData, setObjGlobalLiteTableData] =
    useState(globalDataTableData);
  const [objSharedTableData, setObjSharedTableData] = useState(
    sharedDataTableData(objUser, () => fetchAssessmentTemplates())
  );
  const [localTotalCount, setLocalTotalCount] = useState(0);
  const [sharedTotalCount, setSharedTotalCount] = useState(0);
  const [globalPlusTotalCount, setGlobalPlusTotalCount] = useState(0);
  const [globalLiteTotalCount, setGlobalLiteTotalCount] = useState(0);

  const hasLiteSubscription = useMemo(
    () => objUser?.user?.company?.subscription_package === "lite",
    [objUser]
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const btnOnClickNewTemplate = () => {
    setOpenNewTemplateDialog(true);
  };

  const onCloseNewTemplateDialog = () => {
    setOpenNewTemplateDialog(false);
  };

  const btnOnClickImportTemplate = () => {
    setOpenImportTemplateDialogCSV(true);
  };

  const onCloseImportTemplateDialogCSV = () => {
    setOpenImportTemplateDialogCSV(false);
  };

  const onSubmitImportTemplateDialogCSV = () => {
    setOpenImportTemplateDialogCSV(false);
    showMsg("success", "New Template added successfully");
    fetchAssessmentTemplates();
  };

  const onSubmitNewTemplateDialog = (templateId, name) => {
    setOpenNewTemplateDialog(false);
    showMsg("success", "New Template added successfully");
    routeDictionary[templateId] = name;
    navigate(`/assessments/templates/${templateId}`, {
      state: { routeDictionary },
    });
  };

  const fetchAssessmentTemplates = () => {
    setIsLoadingClientTemplates(true);
    dispatch(getAssessmentTemplates({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientTemplates(false);
        const response = originalPromiseResult;

        const objLocalRows = [];
        const objSharedRows = [];
        const objGlobalPlusRows = [];
        const objGlobalLiteRows = [];
        const objClientTemplatesList = response.data.templates;
        for (let index = 0; index < objClientTemplatesList.length; index++) {
          const element = objClientTemplatesList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.name = element.name;
          objTr.created_by = element?.created_by;
          objTr.created_at = new Date(element.created_at);
          objTr.is_global_template = element?.is_global_template;
          objTr.original_template_id = element?.original_template_id;
          objTr.description = element?.description;
          objTr.shareRequest = element?.shareRequest;
          objTr.msp = element?.msp;
          objTr.element = element;
          objTr.objUser = objUser;
          if (objTr.is_global_template && !objTr.msp) {
            objTr.subscription_category = element?.subscription_category;
            objTr.subscription_package = response.data?.subscription_package;
            if (objTr.subscription_category === "plus") {
              objGlobalPlusRows.push(objTr);
            } else {
              objGlobalLiteRows.push(objTr);
            }
          }
          if (objTr.msp) {
            objTr.owner = element?.msp;
            objTr.status = objTr.shareRequest?.status;
            objSharedRows.push(objTr);
          }
          if (!objTr.is_global_template || objTr.shareRequest) {
            objTr.status = objTr.shareRequest?.status ?? "Not Shared";
            objLocalRows.push(objTr);
          }
        }

        setSharedTotalCount(objSharedRows.length); // There is no pagination on the backend, so that is ok
        setObjSharedTableData({
          ...sharedDataTableData(objUser, () => fetchAssessmentTemplates()),
          rows: objSharedRows,
        });
        setLocalTotalCount(objLocalRows.length); // There is no pagination on the backend, so that is ok
        setObjLocalTableData({
          ...localDataTableData(objUser, () => fetchAssessmentTemplates()),
          rows: objLocalRows,
        });
        setGlobalPlusTotalCount(objGlobalPlusRows.length); // There is no pagination on the backend, so that is ok
        setObjGlobalPlusTableData({
          ...globalDataTableData,
          rows: objGlobalPlusRows,
        });
        setGlobalLiteTotalCount(objGlobalLiteRows.length); // There is no pagination on the backend, so that is ok
        setObjGlobalLiteTableData({
          ...globalDataTableData,
          rows: objGlobalLiteRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientTemplates(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  useEffect(() => {
    fetchAssessmentTemplates();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ImportTemplateCSVDialog
        open={openImportTemplateDialogCSV}
        onClose={onCloseImportTemplateDialogCSV}
        onSubmit={onSubmitImportTemplateDialogCSV}
        objUser={props.objUser}
      />
      <CreateNewTemplateDialog
        open={openNewTemplateDialog}
        onClose={onCloseNewTemplateDialog}
        onSubmit={onSubmitNewTemplateDialog}
        objUser={props.objUser}
      />
      <MDBox>
        <Card
          position="relative"
          sx={{ padding: "32px", marginBottom: "1rem" }}
        >
          <MDBox
            mb={1}
            textAlign="left"
            sx={{
              display: "inline-flex",
              width: "100%",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <MDTypography variant="h3" pt={1}>
              My Assessment Templates
            </MDTypography>
            <MDBox
              sx={{
                display: "inline-flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              {hasLiteSubscription ? (
                <>
                  <DashboardActionButton
                    btnText="Create Template"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                    buttonDisabled={true}
                  />
                  <DashboardActionButton
                    btnText="Import From CSV"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                    buttonDisabled={true}
                  />
                  <DashboardActionButton
                    btnText="Download Sample CSV"
                    btnIcon="download"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                    buttonDisabled={true}
                  />
                </>
              ) : (
                <>
                  <DashboardActionButton
                    action={btnOnClickNewTemplate}
                    btnText="Create Template"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                  />
                  <DashboardActionButton
                    action={btnOnClickImportTemplate}
                    btnText="Import From CSV"
                    btnIcon="add"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                  />
                  <DashboardButton
                    component="a"
                    href="/sample_template.csv"
                    download
                    btnText="Download Sample CSV"
                    btnIcon="download"
                    textColor="white"
                    bgColor="success"
                    sx={{ margin: "0" }}
                  />
                </>
              )}
            </MDBox>
          </MDBox>
          {isLoadingClientTemplates === false &&
          objLocalTableData.rows.length > 0 &&
          localTotalCount > 0 ? (
            <TemplatesDatatable
              table={objLocalTableData}
              entriesPerPage={true}
              canSearch
              totalCount={localTotalCount}
              objUser={objUser}
            />
          ) : (
            <Fragment>
              {isLoadingClientTemplates ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p className="empty-text-p">
                  Company does not have any templates.
                </p>
              )}
            </Fragment>
          )}
        </Card>
        {(objUser.user.role.name == "company_user" ||
          objUser.user.role.name == "admin") && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant="h3">
                Global Lite Assessment Templates
              </MDTypography>
            </MDBox>
            {isLoadingClientTemplates === false &&
            objGlobalLiteTableData.rows.length > 0 &&
            globalLiteTotalCount > 0 ? (
              <TemplatesDatatable
                table={objGlobalLiteTableData}
                entriesPerPage={true}
                canSearch
                totalCount={globalLiteTotalCount}
                objUser={objUser}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    There are no Global Lite Templates available in your
                    subscription tier.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
        {(objUser.user.role.name == "company_user" ||
          objUser.user.role.name == "admin") && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <MDTypography variant="h3">
                Global Plus Assessment Templates
              </MDTypography>
            </MDBox>
            {isLoadingClientTemplates === false &&
            objGlobalPlusTableData.rows.length > 0 &&
            globalPlusTotalCount > 0 ? (
              <TemplatesDatatable
                table={objGlobalPlusTableData}
                entriesPerPage={true}
                canSearch
                totalCount={globalPlusTotalCount}
                objUser={objUser}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    There are no Global Plus Templates available in your
                    subscription tier.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
        {(objUser.user.role.name == "company_user" ||
          objUser.user.role.name == "admin") && (
          <Card
            position="relative"
            sx={{ padding: "32px", marginBottom: "1rem" }}
          >
            <MDBox
              mb={1}
              textAlign="left"
              sx={{
                display: "inline-flex",
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <MDTypography variant="h3" pt={1}>
                Shared Assessment Templates
              </MDTypography>
            </MDBox>
            {isLoadingClientTemplates === false &&
            objSharedTableData.rows.length > 0 &&
            sharedTotalCount > 0 ? (
              <TemplatesDatatable
                table={objSharedTableData}
                entriesPerPage={true}
                canSearch
                totalCount={sharedTotalCount}
                objUser={objUser}
              />
            ) : (
              <Fragment>
                {isLoadingClientTemplates ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p className="empty-text-p">
                    No MSP Shared Assessment Templates.
                  </p>
                )}
              </Fragment>
            )}
          </Card>
        )}
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentTemplates;
