/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props

// Material Dashboard 2 PRO React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// @mui icons
import { useLocation, useNavigate } from "react-router-dom";
const TemplateNameBtnTr = (props) => {
  const { value, column, row } = props;
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const btnOnClickTemplateName = (objTemplateData) => {
    routeDictionary[objTemplateData.element.id] = value;
    navigate(`/template-library/${objTemplateData.element.id}`, {
      state: { routeDictionary },
    });
  };

  const disabled =
    row.original.global_template_type === "Plus" &&
    row.original.subscription_package !== "plus";

  return (
    <MDButton
      variant="text"
      onClick={() => btnOnClickTemplateName(row.original)}
      disabled={disabled}
    >
      <MDTypography
        variant="text"
        fontWeight="medium"
        color="success"
        sx={{ lineHeight: 0 }}
      >
        {value}
      </MDTypography>
    </MDButton>
  );
};

export default TemplateNameBtnTr;
