import { useEffect, useState, useRef } from "react";
import { Form, Formik } from "formik";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { saveTemplateChanges } from "features/company/templatesActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import EditTemplateFormikWrapper from "./EditTemplateFormikWrapper";
import { usePrompt } from "utils/Helpers/NavigationBlocker";

const EditTemplateDraft = (props) => {
  const { objUser, objTemplate, loadTemplate } = props;
  const dispatch = useDispatch();
  let { clientId, templateId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [objInitialValues, setObjInitialValues] = useState({
    template_name: "",
    created_by: "",
    document: "",
  });
  const [objAddNewCustomerFormErrors, setObjAddNewCustomerFormErrors] =
    useState({});
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const editorRef = useRef(null);
  const formRef = useRef();

  useEffect(() => {
    var tags = [];

    if (objTemplate.tags) {
      tags = objTemplate.tags.map((tg) => tg.name);
    }

    const __ObjInitialValues = {
      template_name: objTemplate.name ?? "",
      created_by: objTemplate.created_by ?? "",
      document: objTemplate?.document ?? "",
      tags: tags,
    };

    if (
      typeof objTemplate.id !== "undefined" &&
      objTemplate.id !== null &&
      typeof objTemplate.document !== "undefined"
    ) {
      __ObjInitialValues.document = objTemplate.document;
    }
    setObjInitialValues(__ObjInitialValues);
  }, [objTemplate]);

  const onSubmitForm = (currentValues) => {
    setIsLoading(true);
    handleAutoSave(
      editorRef.current,
      () => showMsg("success", "Template saved succesfully"),
      () => showMsg("error", "Error saving template. Try again later")
    );
  };

  const handleAutoSave = (editor, successCallback, failureCallback) => {
    const objPostData = {
      name: formRef.current.values.template_name,
      tags: formRef.current.values.tags,
      document: editor.getContent(),
    };
    dispatch(
      saveTemplateChanges({ objPostData, objUser, templateId, clientId })
    )
      .then(unwrapResult)
      .then((response) => {
        if (response.status === 201) {
          successCallback();
          setObjInitialValues(formRef.current.values);
          setIsLoading(false);
        } else {
          failureCallback();
          setIsLoading(false);
        }
      })
      .catch(() => {
        failureCallback();
        setIsLoading(false);
      });
  };

  usePrompt(
    "There are unsaved changes. Are you sure you want to leave?",
    formRef.current?.values?.template_name !== objInitialValues.template_name ||
      JSON.stringify(formRef.current?.values?.tags) !==
        JSON.stringify(objInitialValues.tags) ||
      editorRef.current?.isDirty()
  );

  return (
    <>
      {isLoadingTemplate === false &&
      Object.keys(objInitialValues).length > 0 &&
      objInitialValues.template_name !== "" ? (
        <Formik
          initialValues={objInitialValues}
          innerRef={formRef}
          onSubmit={onSubmitForm}
        >
          {({ values, errors, touched, isSubmitting, setFieldValue }) => (
            <Form id={"EditTemplateForm"} autoComplete="off">
              <MDBox>
                <EditTemplateFormikWrapper
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  objStates={{}}
                  handleAutoSave={handleAutoSave}
                  editorRef={editorRef}
                  mergeTags={objTemplate.merge_tags}
                />

                {isLoading === false ? (
                  <MDBox
                    px={5}
                    sx={{ margin: "0" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="dark"
                      sx={{ padding: "12px 12px" }}
                    >
                      SAVE CHANGES
                    </MDButton>
                  </MDBox>
                ) : (
                  <MDBox
                    px={5}
                    sx={{ margin: "0" }}
                    width="100%"
                    display="flex"
                    justifyContent="flex-start"
                  >
                    <LoadingSpinner color="success" size="lg" />
                  </MDBox>
                )}
              </MDBox>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </>
  );
};

export default EditTemplateDraft;
