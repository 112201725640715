import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

/***Blocked domains */
export const getBlockedDomains = createAsyncThunk(
  "company/getBlockedDomains",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addBD = createAsyncThunk(
  "company/addBD",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteBlockedDomain = createAsyncThunk(
  "company/deleteBlockedDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains/${dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveBDChanges = createAsyncThunk(
  "company/saveBDChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/blocked-domains`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**allowed multiple msp signups */
export const getMDDomains = createAsyncThunk(
  "company/getMDDomains",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addMD = createAsyncThunk(
  "company/addMD",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteMDDomain = createAsyncThunk(
  "company/deleteMDDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups/${dataFromActionBtn.id}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveMDChanges = createAsyncThunk(
  "company/saveMDChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/security/allowed-multiple-signups`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
