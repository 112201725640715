/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateCurrentClientSelected } from "features/company/companyUsersActions";

const ActionBtnTr = (props) => {
  const { row, objUser } = props;
  const location = useLocation();
  const routeDictionary = location.state?.routeDictionary ?? {};
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSwitchingClient, setIsSwitchingClient] = useState(false);

  const id = row.original.client_id;

  const from_client =
    objUser.user.role.name == "client_user"
      ? objUser.user.list_of_clients
      : objUser.user.list_of_clients.find((item) => {
          return item.id === objUser.user.current_client_id;
        });

  const to_client =
    objUser.user.role.name == "client_user"
      ? objUser.user.list_of_clients
      : objUser.user.list_of_clients.find((item) => {
          return item.id === id;
        });

  const value = to_client;

  const btnGoToReviewPage = (data) => {
    if (id === objUser.user.current_client_id) {
      navigate(`/asset-lists/${data.policy_id}/review/${data.id}`, {
        state: { routeDictionary },
      });
    } else {
      setIsSwitchingClient(true);
      dispatch(updateCurrentClientSelected({ value }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          window.location = `/asset-lists/${data.policy_id}/review/${data.id}`;
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsSwitchingClient(false);
          console.log(rejectedValueOrSerializedError);
        });
    }
  };

  return (
    <Fragment>
      {isSwitchingClient ? (
        <LoadingSpinner subClass="text-center" color={"#fff"} size="lg" />
      ) : (
        <MDButton
          color="success"
          onClick={() => btnGoToReviewPage(row.original)}
        >
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="white"
            sx={{ lineHeight: 0, color: "white" }}
          >
            {isSwitchingClient ? (
              <LoadingSpinner subClass="text-center" color={"#fff"} size="lg" />
            ) : (
              <Fragment>Review Asset List</Fragment>
            )}
          </MDTypography>
        </MDButton>
      )}
    </Fragment>
  );
};

export default ActionBtnTr;
