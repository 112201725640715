import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";

import { useDispatch } from "react-redux";
import {
  getAssessmentScoring,
  updateAssessmentScoring,
} from "../../../../../features/assessmentConfig/assessmentConfigActions";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import { Grid, Select, MenuItem } from "@mui/material";

import MDButton from "components/MDButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";

import tinycolor from "tinycolor2";
import { useMaterialUIController } from "context";

const AssessmentData = (props) => {
  const { objUser } = props;

  const [fields, setFields] = useState([]);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [colorOptions, setColorOptions] = useState(null);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const fetchCurrentScoring = () => {
    setIsLoading(true);
    dispatch(getAssessmentScoring({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const results = originalPromiseResult.data.assessment_scoring;
        const scores = results.map((score) => {
          return {
            name: score.name,
            min_score: score.min_score,
            max_score: score.max_score,
            color: score.color,
            limit: score.limit,
            readonly: score.limit == "lowest" ? true : false,
            allow_add_after: score.limit == "highest" ? false : true,
            allow_delete: score.limit == "middle" ? true : false,
          };
        });

        setFields(scores);
        setIsLoading(false);
        setColorOptions(originalPromiseResult.data.color_options);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  useEffect(() => {
    if (
      objUser.user &&
      objUser.user.role &&
      objUser.user.role.name != "admin" &&
      objUser.user.is_super_admin != 1 &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    fetchCurrentScoring();
  }, []);

  const addField = (indexToAddAfter) => {
    const updatedFields = [...fields];
    updatedFields.splice(indexToAddAfter + 1, 0, {
      name: "Default",
      min_score: 0,
      max_score: 0,
      color: "#cccccc",
      limit: "middle",
      readonly: false,
      allow_add_after: true,
      allow_delete: true,
    });
    setFields(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = [...fields];
    const currentIndex = index;
    const previousIndex = index - 1;

    if (updatedFields[currentIndex].max_score > 0) {
      updatedFields[previousIndex].max_score =
        updatedFields[currentIndex].max_score;
    }

    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  const handleChange = (index, fieldName, value) => {
    const updatedFields = [...fields];
    const currentIndex = index;
    const previousIndex = index - 1;
    const nextIndex = index + 1;
    const limit = updatedFields[currentIndex]["limit"];
    let newErrors = [];
    setErrors([]);

    /**empty current input */
    if (value === "") {
      newErrors.push("Please enter a value for current row.");
    }

    /**any empty existing inputs */
    const rowsWithEmptyInputs = updatedFields.filter((field, index) => {
      return (
        /**The && index!=currentIndex is an important condition I added.
         * This comparison wwas being done with the current value of the fields state value and not
         * the current actually entered input
         * So if previously the input was empty and you entered a value of 5, you still got the error
         *
         */
        (field.min_score === "" || field.name === "") && index != currentIndex
      );
    });

    if (rowsWithEmptyInputs.length > 0) {
      newErrors.push("No inputs must be left empty.");
    }

    /**make sure no row has a min score of 100 */
    const rowsWithMinScore100 = updatedFields.filter((field, index) => {
      return (field.min_score >= 100 && index != currentIndex) || value >= 100;
    });
    if (rowsWithMinScore100.length > 0) {
      newErrors.push("Min score for any row must be less than 100");
    }

    if (fieldName == "min_score") {
      const minScoreForCurrentIndex = parseFloat(value);
      const maxScoreForCurrentIndex = parseFloat(
        updatedFields[currentIndex].max_score
      );

      /**make sure current inputted min score is not higher than any of the rows after it */
      const rowsWithHigherMinScoreThanCurrentRow = updatedFields.filter(
        (field, index) => {
          return (
            field.min_score <= minScoreForCurrentIndex && index > currentIndex
          );
        }
      );

      if (rowsWithHigherMinScoreThanCurrentRow.length > 0) {
        newErrors.push(
          "Min score of current row must not be higher than or equal to any of the rows after it."
        );
      }

      /**make sure current inputted min score is not lower than any of the rows before it */
      const rowsWithLowerMinScoreThanCurrentRow = updatedFields.filter(
        (field, index) => {
          return (
            minScoreForCurrentIndex <= field.min_score && index < currentIndex
          );
        }
      );

      if (rowsWithLowerMinScoreThanCurrentRow.length > 0) {
        newErrors.push(
          "Min score of current row must not be lower than or equal to any of the rows before it."
        );
      }

      if (limit == "middle") {
        updatedFields[previousIndex].max_score = minScoreForCurrentIndex - 0.1;
        updatedFields[currentIndex].max_score =
          updatedFields[nextIndex].min_score - 0.1;
      } else {
        //highest limit min score is being changed
        updatedFields[previousIndex].max_score = minScoreForCurrentIndex - 0.1;
      }
    }

    setErrors(newErrors);
    updatedFields[index][fieldName] = value;
    setFields(updatedFields);
  };

  const lightenColor = (color) => {
    const colorObject = colorOptions.find((item) => {
      return item.square_color === color;
    });

    if (colorObject != undefined && colorObject) {
      return colorObject.row_color;
    } else {
      const colorObj = tinycolor(color);
      return colorObj.isDark()
        ? colorObj.brighten(60).toString()
        : colorObj.darken(5).toString();
    }
  };

  const saveAssessmentScoringChanges = async () => {
    setIsSavingChanges(true);

    const rowsWithZeroMinScore = fields.filter((field) => {
      return field.min_score == 0 && field.limit != "lowest";
    });

    if (rowsWithZeroMinScore.length > 0) {
      showMsg(
        "info",
        "No row should have a min score of zero except the first row"
      );
      setIsSavingChanges(false);
      return;
    }

    if (errors.length == 0) {
      dispatch(updateAssessmentScoring({ objUser, fields }))
        .then(unwrapResult)
        .then((originalPromiseResult) => {
          setIsSavingChanges(false);
          showMsg("success", "Assessment scoring settings saved successfully");
          setErrors([]);
        })
        .catch((rejectedValueOrSerializedError) => {
          setIsSavingChanges(false);
          console.log(rejectedValueOrSerializedError);
          if (rejectedValueOrSerializedError.response.data.errors) {
            rejectedValueOrSerializedError.response.data.errors.forEach(
              (error) => {
                showMsg("error", error);
              }
            );
          } else if (rejectedValueOrSerializedError.response.data.message) {
            showMsg(
              "error",
              rejectedValueOrSerializedError.response.data.message
            );
          } else {
            showMsg("error", "Error saving changes");
          }
        });
    } else {
      setIsSavingChanges(false);
      showMsg("info", "Please fix the errors first");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox>
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          {/* custom */}

          {errors.length > 0 ? (
            <Grid item xs={12} lg={12}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <MDTypography variant="h5" fontWeight="bold">
                    Errors with scoring ranges:
                  </MDTypography>
                  {errors.map((error, index) => {
                    return (
                      <>
                        <MDTypography
                          key={index}
                          variant="p"
                          fontWeight="regular"
                          sx={{ color: "red" }}
                        >
                          {error}
                        </MDTypography>
                        <br />
                      </>
                    );
                  })}
                </MDBox>
              </Card>
            </Grid>
          ) : (
            ""
          )}

          <Grid item xs={12} lg={12}>
            <MDBox display="flex" justifyContent="right">
              {isSavingChanges ? (
                <LoadingSpinner color="success" size="lg" />
              ) : (
                <MDButton
                  type="submit"
                  variant="gradient"
                  color="info"
                  onClick={() => saveAssessmentScoringChanges()}
                  sx={{
                    padding: "10px 17px",
                    margin: "0px 5px",
                  }}
                >
                  Save Assessment Scoring Rules
                </MDButton>
              )}
            </MDBox>
            <Card sx={{ marginTop: "16px" }}>
              <TableContainer sx={{ padding: "1rem" }}>
                {isLoading && fields.length == 0 ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <Table>
                    <TableRow sx={{ color: darkMode && "whitesmoke" }}>
                      <TableCell>
                        <b>Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Min Score</b>
                      </TableCell>
                      <TableCell>
                        <b>Max Score</b>
                      </TableCell>
                      <TableCell>
                        <b>Color</b>
                      </TableCell>
                      <TableCell>
                        <b>Action</b>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>

                    <TableBody>
                      {fields.map((field, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              backgroundColor: lightenColor(field.color),
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <TextField
                                value={field.name}
                                onChange={(e) =>
                                  handleChange(index, "name", e.target.value)
                                }
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "5px",
                                }}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextField
                                sx={{
                                  backgroundColor: "#fff",
                                  borderRadius: "5px",
                                }}
                                value={field.min_score}
                                type="number"
                                onChange={(e) =>
                                  handleChange(
                                    index,
                                    "min_score",
                                    e.target.value
                                  )
                                }
                                disabled={field.readonly ? true : false}
                              />
                            </TableCell>
                            <TableCell>{field.max_score}</TableCell>
                            <TableCell>
                              <Select
                                onChange={(e) =>
                                  handleChange(index, "color", e.target.value)
                                }
                                sx={{ backgroundColor: field.color }}
                              >
                                {colorOptions.map((option) => (
                                  <MenuItem
                                    key={option.square_color}
                                    value={option.square_color}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "20px",
                                        backgroundColor: option.square_color,
                                      }}
                                    ></div>
                                  </MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                            <TableCell>
                              {field.allow_add_after == true ? (
                                <MDButton
                                  type="submit"
                                  color="success"
                                  onClick={() => addField(index)}
                                  sx={{
                                    padding: "10px 17px",
                                    margin: "0px 5px",
                                  }}
                                >
                                  Add Row After
                                </MDButton>
                              ) : (
                                ""
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {field.allow_delete == true ? (
                                <Icon
                                  sx={{ cursor: "pointer" }}
                                  onClick={() => removeField(index)}
                                >
                                  delete
                                </Icon>
                              ) : (
                                ""
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentData;
