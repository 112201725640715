import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
function DashboardActionButton({
  btnText,
  btnIcon,
  textColor,
  bgColor,
  action,
  type,
  buttonDisabled = false,
  marginTop,
}) {
  return (
    <MDButton
      onClick={action}
      type={type ? type : "button"}
      color={bgColor}
      sx={{ marginTop: marginTop ? marginTop : "15px", padding: "5px 15px" }}
      disabled={buttonDisabled ? true : false}
    >
      <Icon fontSize="large">{btnIcon}</Icon>
      <MDTypography
        fontWeight="bold"
        sx={{ marginLeft: "0.75rem" }}
        color={textColor}
        variant="button"
      >
        {btnText}
      </MDTypography>
    </MDButton>
  );
}

export default DashboardActionButton;
