import DefaultCell from "views/components/TableCells/DefaultCell";

let DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, column, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Company",
      accessor: "company_name",
      Cell: ({ value, column, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Submitted",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;
