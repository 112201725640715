import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardActionButton from "views/components/DashboardActionButton";
// import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "./data/dataTableData";
import { useDispatch, useSelector } from "react-redux";
import { getAllPendingReviewsForUser } from "features/user/notificationActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ManagementTable from "./components/ManagementTable";

import { showMsg } from "utils/general";
import "./Styling.css";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import DashboardButton from "views/components/DashboardButtton";
import { standardDateFormat } from "utils/general";

const PendingAssetApprovals = (props) => {
  const { objUser } = props;

  /**categories */
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const isAdmin =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "admin"
      : false;

  const isClientUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "client_user"
      : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    loadPendingReviews(perPage, page);
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const loadPendingReviews = (_per_page, _page) => {
    setIsLoadingMoreData(true);
    dispatch(getAllPendingReviewsForUser({ _per_page, _page }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreData(false);

        const response = originalPromiseResult;
        const objRows = [];
        const objList = response.data.pendingAssetApprovals;

        for (let index = 0; index < objList.length; index++) {
          const element = objList[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.policy_id = element.policy_id;
          objTr.name = element.policy.title;
          objTr.company_name = element.policy.client.name;
          objTr.type = capitalizeFirstLetter(element.type);
          objTr.created_at = standardDateFormat(element.created_at);
          objTr.status = capitalizeFirstLetter(element.status);
          objTr.client_id = element.policy.client_id;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreData(false);

        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDTypography fontWeight="bold" variant="h5">
        Your Pending Reviews
      </MDTypography> */}

      <MDBox>
        <Grid spacing={2} container width="100%">
          <Grid item xs={12} lg={12}>
            <Card sx={{ marginTop: "16px" }}>
              {isLoadingMoreData === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 ? (
                <ManagementTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  isClientUser={isClientUser}
                  totalCount={totalCount}
                  loadMoreCategories={loadPendingReviews}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                  objUser={objUser}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreData ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p className="empty-text-p" style={{ padding: "10px" }}>
                      Nothing pending to review
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default PendingAssetApprovals;
