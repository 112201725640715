import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import { CameraAlt } from "@mui/icons-material";
import { FormControlLabel, Grid, Icon, Radio, RadioGroup } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import LogoUploadRequirements from "views/components/LogoUploadRequirements";
import {
  fetchDisplayLogoMSP,
  fetchReportLogoMSP,
  fetchPDFDownloadSettings,
  uploadDisplayLogoMSP,
  uploadReportLogoMSP,
  updatePDFDownloadSettings,
  deleteMSPReportLogo,
  deleteMSPDisplayLogo,
  fetchAutoLogoutTimer,
  updateAutoLogoutTimer,
} from "../../../../features/company/CompanyConfigActions";
import {
  deleteMergeTag,
  fetchMergeTags,
} from "../../../../features/company/mergeTagsActions";
import "./ClientConfig.css";
import EditTagsDialog from "./components/EditTagsDialog";
import ToolTip from "./components/ToolTip";
import { Formik, Form  } from "formik";
import FormField from "layouts/pages/users/new-user/components/FormField";

const mergeTagsTableData = (editAction, removeAction) => ({
  columns: [
    { Header: "Name", accessor: "name" },
    { Header: "Description", accessor: "description" },
    { Header: "Type", accessor: "type" },
    {
      Header: "Actions",
      accessor: "id",
      align: "right",
      Cell: ({ value, row }) => {
        return (
          <>
            {row.original.type == "Global" ? (
              "Can't modify Global Tags"
            ) : (
              <>
                <MDButton onClick={() => editAction(value)}>
                  <MDBox color="dark" display="flex" alignItems="center">
                    <Icon sx={{ fontWeight: "bold" }}>edit</Icon>
                    <MDTypography
                      variant="p"
                      fontWeight="medium"
                      color="text.dark"
                      sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                    >
                      Edit
                    </MDTypography>
                  </MDBox>
                </MDButton>
                <MDButton
                  onClick={() => removeAction(value)}
                  variant="gradient"
                  sx={{ marginLeft: "1rem" }}
                >
                  <MDBox color="error" display="flex" alignItems="center">
                    <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                    <MDTypography
                      variant="p"
                      fontWeight="medium"
                      color="error"
                      sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                    >
                      Delete
                    </MDTypography>
                  </MDBox>
                </MDButton>
              </>
            )}
          </>
        );
      },
    },
  ],
  rows: [],
});

const CompanyConfig = (props) => {
  const { objUser } = props;
  /**Logos */
  const [file, setFile] = useState(null);
  const [reportFile, setReportFile] = useState(null);
  const [isUploadingDisplayLogo, setIsUploadingDisplayLogo] = useState(false);
  const [isFetchingDisplayLogo, setIsFetchingDisplayLogo] = useState(true);
  const [isFetchingMergeTags, setIsFetchingMergeTags] = useState(true);
  const [dataTableData, setDataTableData] = useState(
    mergeTagsTableData(
      () => {},
      () => {}
    )
  );
  const [displayLogoURL, setDisplayLogoURL] = useState(null);
  const [isUploadingReportLogo, setIsUploadingReportLogo] = useState(false);
  const [isFetchingReportLogo, setIsFetchingReportLogo] = useState(true);
  const [reportLogoURL, setReportLogoURL] = useState(null);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [currenTag, setCurrentTag] = useState(null);
  const [logoutTimer, setLogoutTimer] = useState(20);
  const confirm = useConfirm();

  /**pdf */
  const [isFetchingPDFSettings, setIsFetchingPDFSettings] = useState(true);
  const [isFetchingAutoLogoutTimer, setIsFetchingAutoLogoutTimer] =
    useState(true);
  const [pdfSettingValue, setPdfSettingValue] = useState("yes");


  const dispatch = useDispatch();

  useEffect(() => {
    loadDisplayLogo();
    loadReportLogo();
    loadPDFDownloadSettings();
    loadAutoLogoutTime();
  }, []);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    setIsFetchingMergeTags(true);
    dispatch(fetchMergeTags({ objUser }))
      .then(unwrapResult)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Get marge tags request failed.");
        }

        setDataTableData({
          ...mergeTagsTableData(editTag, removeTag),
          rows: response.data.sort((a, b) => {
            const nameComparison = a.type.localeCompare(b.type);
            return nameComparison !== 0
              ? nameComparison
              : a.name.localeCompare(b.name);
          }),
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log("Fetch mergeTags", rejectedValueOrSerializedError);
        showMsg("error", "Error fetching merge tags");
      })
      .finally(() => {
        setIsFetchingMergeTags(false);
      });
  };

  /**file uploads */

  const handleDisplayLogoInputChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const btnClickUploadDisplayLogo = async () => {
    if (!file) {
      showMsg("error", "Please select an image to upload");
      return;
    }
    setIsUploadingDisplayLogo(true);
    dispatch(uploadDisplayLogoMSP({ objUser, file }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingDisplayLogo(false);
        setFile(null);
        const response = originalPromiseResult;
        showMsg("success", "Logo updated");
        setDisplayLogoURL(response.data.display_logo);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingDisplayLogo(false);
        setFile(null);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
        console.log(rejectedValueOrSerializedError);
      });
  };

  const loadDisplayLogo = async () => {
    dispatch(fetchDisplayLogoMSP({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingDisplayLogo(false);
        const response = originalPromiseResult;
        if (response.data.display_logo) {
          setDisplayLogoURL(response.data.display_logo);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingDisplayLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your display logo, please try again."
        );
      });
  };

  const deleteDisplayLogo = async () => {
    setIsUploadingDisplayLogo(true);
    dispatch(deleteMSPDisplayLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingDisplayLogo(false);
        const response = originalPromiseResult;
        if (response.data.display_logo) {
          showMsg("success", "Logo Deleted");
          setDisplayLogoURL(response.data.display_logo);
          window.location.reload();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingDisplayLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your display logo, please try again."
        );
      });
  };

  const btnOnClickDeleteDisplayLogo = () => {
    confirm({
      description: "Are you sure, You want to delete this logo?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteDisplayLogo();
      })
      .catch(() => {
        /* ... */
      });
  };

  /**Report logo */
  const handleReportLogoInputChange = (e) => {
    if (e.target.files) {
      setReportFile(e.target.files[0]);
    }
  };

  const btnClickUploadReportLogo = async () => {
    if (!reportFile) {
      showMsg("error", "Please select an image to upload");
      return;
    }
    setIsUploadingReportLogo(true);
    dispatch(uploadReportLogoMSP({ objUser, reportFile }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingReportLogo(false);
        setReportFile(null);
        const response = originalPromiseResult;
        showMsg("success", "Logo updated");
        setReportLogoURL(response.data.report_logo);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingReportLogo(false);
        setReportFile(null);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
        console.log(rejectedValueOrSerializedError);
      });
  };

  const loadReportLogo = async () => {
    dispatch(fetchReportLogoMSP({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingReportLogo(false);
        const response = originalPromiseResult;
        if (response.data.report_logo) {
          setReportLogoURL(response.data.report_logo);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingReportLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your reports logo, please try again."
        );
      });
  };

  const deleteReportLogo = async () => {
    setIsUploadingReportLogo(true);
    dispatch(deleteMSPReportLogo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsUploadingReportLogo(false);
        const response = originalPromiseResult;
        if (response.data.report_logo) {
          showMsg("success", "Logo Deleted");
          setReportLogoURL(response.data.report_logo);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsUploadingReportLogo(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your report logo, please try again."
        );
      });
  };

  const btnOnClickDeleteReportLogo = () => {
    confirm({
      description: "Are you sure, You want to delete this report logo?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteReportLogo();
      })
      .catch(() => {
        /* ... */
      });
  };

  const createNewTag = () => {
    setCurrentTag(null);
    setTagDialogOpen(true);
  };

  const editTag = (tagId) => {
    const tag = dataTableData.rows.find((tag) => tag.id === tagId);
    setCurrentTag(tag);
    setTagDialogOpen(true);
  };

  const onTagDialogClose = () => {
    setTagDialogOpen(false);
  };

  const onTagDialogSubmit = (tag) => {
    setTagDialogOpen(false);
    tag.type = "MSP Created";
    if (tag.id && !dataTableData.rows.includes((t) => t.id === tag.id)) {
      setDataTableData({
        ...dataTableData,
        rows: [...dataTableData.rows.filter((t) => t.id !== tag.id), tag].sort(
          (a, b) => {
            const nameComparison = a.type.localeCompare(b.type);
            return nameComparison !== 0
              ? nameComparison
              : a.name.localeCompare(b.name);
          }
        ),
      });
    } else {
      getTags();
    }
  };

  const removeTag = async (tagId) => {
    try {
      await confirm({
        description: "Do you want to remove the selected merge tag? ",
        confirmationButtonProps: {
          color: "success",
          variant: "contained",
          sx: {
            backgroundColor: "#4CAF50",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#1b5e20",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
        cancellationButtonProps: {
          color: "error",
          variant: "contained",
          sx: {
            backgroundColor: "#d32f2f",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#d32f2f",
              color: "#fff",
              boxShadow:
                "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
            },
          },
        },
      });

      setIsFetchingMergeTags(true);
      try {
        const response = await dispatch(
          deleteMergeTag({ objUser, tagId })
        ).unwrap();
        if (response.status !== 204) {
          throw new Error("Delete merge tag request failed.");
        }
        showMsg("success", "Merge tag deleted");

        getTags();
      } catch (error) {
        console.log("Delete merge tag", error);
        showMsg("error", "Error deleting merge tag");
      }
    } catch (e) {}
  };

  /**pdf settings */
  const loadPDFDownloadSettings = async () => {
    dispatch(fetchPDFDownloadSettings({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setPdfSettingValue(response.data);
        setIsFetchingPDFSettings(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingPDFSettings(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your PDF download settings. Please try again."
        );
      });
  };

  /**update email settings*/

  const btnOnClickSavePDFSettingsChanges = async () => {
    setIsFetchingPDFSettings(true);
    dispatch(updatePDFDownloadSettings({ objUser, pdfSettingValue }))
      .then(unwrapResult)
      .then(() => {
        setIsFetchingPDFSettings(false);
        showMsg("success", "PDF download preferences saved successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingPDFSettings(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg(
            "error",
            "Something went wrong as we tried to UPDATE your PDF download settings, please try again."
          );
        }
      });
  };

  const handlePDFSettingRadioChange = (event) => {
    setPdfSettingValue(event.target.value);
  };

  /**update auto logout timer*/
  const loadAutoLogoutTime = async () => {
    dispatch(fetchAutoLogoutTimer({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setLogoutTimer(parseInt(response.data) / 60000);
        setIsFetchingAutoLogoutTimer(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAutoLogoutTimer(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          "Something went wrong as we tried to fetch your Auto logout timer settings. Please try again."
        );
      });
  };

  const updateTimer = async () => {
    setIsFetchingAutoLogoutTimer(true);
    let autoLogoutTimer = parseInt(logoutTimer) * 60000;
    dispatch(updateAutoLogoutTimer({ objUser, autoLogoutTimer }))
      .then(unwrapResult)
      .then(() => {
        setIsFetchingAutoLogoutTimer(false);
        showMsg("success", "Auto logout timer preferences saved successfully");
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingAutoLogoutTimer(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg(
            "error",
            "Something went wrong as we tried to UPDATE your PDF download settings, please try again."
          );
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDTypography fontWeight="bold" variant="h5">
        Logo Customization
      </MDTypography>
      <MDBox>
        <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={0}>
          <Grid spacing={2} container width="100%">
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                <MDBox display="flex" alignItems="flex-start">
                  <MDTypography sx={{ marginBottom: "1rem" }} variant="h4">
                    Display Logo
                  </MDTypography>
                  <ToolTip
                    color="icon"
                    info={
                      "Upload a custom company logo to be displayed in the dashboard "
                    }
                  />
                </MDBox>
                <LogoUploadRequirements />

                <MDBox mb={2}>
                  {isFetchingDisplayLogo ? (
                    <LoadingSpinner subClass="text-center" size="lg" />
                  ) : (
                    <>
                      {displayLogoURL ? (
                        <div>
                          <img
                            src={displayLogoURL}
                            style={{ maxWidth: "100%", height: "auto" }}
                            alt="Display logo"
                          />
                          <MDButton
                            onClick={btnOnClickDeleteDisplayLogo}
                            variant="gradient"
                            sx={{ marginLeft: "1rem" }}
                          >
                            <MDBox
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>{" "}
                            </MDBox>
                          </MDButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </MDBox>

                <input type="file" onChange={handleDisplayLogoInputChange} />

                <div>{file && `${file.name} - ${file.type}`}</div>

                <MDButton
                  variant="gradient"
                  onClick={btnClickUploadDisplayLogo}
                  color="info"
                  sx={{ padding: "5px 15px", marginTop: "1rem" }}
                >
                  {isUploadingDisplayLogo ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="white"
                      size="lg"
                    />
                  ) : (
                    <Fragment>
                      <Icon fontSize="large">
                        <CameraAlt />
                      </Icon>
                      <MDTypography
                        fontWeight="bold"
                        sx={{ marginLeft: "0.75rem" }}
                        variant="button"
                        color="white"
                      >
                        Upload Display Logo
                      </MDTypography>
                    </Fragment>
                  )}
                </MDButton>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
                <MDBox display="flex" alignItems="flex-start">
                  <MDTypography sx={{ marginBottom: "1rem" }} variant="h4">
                    Reports/PDFs Logo
                  </MDTypography>
                  <ToolTip
                    info={"This logo will be embedded on your reports and PDFs"}
                  />
                </MDBox>
                <LogoUploadRequirements />
                <MDBox mb={2}>
                  {isFetchingReportLogo ? (
                    <LoadingSpinner subClass="text-center" size="lg" />
                  ) : (
                    <>
                      {reportLogoURL ? (
                        <div>
                          <img
                            src={reportLogoURL}
                            style={{ maxWidth: "100%", height: "auto" }}
                            alt="Report logo"
                          />
                          <MDButton
                            onClick={btnOnClickDeleteReportLogo}
                            variant="gradient"
                            sx={{ marginLeft: "1rem" }}
                          >
                            <MDBox
                              color="error"
                              display="flex"
                              alignItems="center"
                            >
                              <Icon sx={{ fontWeight: "bold" }}>delete</Icon>{" "}
                            </MDBox>
                          </MDButton>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </MDBox>

                <input type="file" onChange={handleReportLogoInputChange} />

                <div>
                  {reportFile && `${reportFile.name} - ${reportFile.type}`}
                </div>

                <MDButton
                  variant="gradient"
                  onClick={btnClickUploadReportLogo}
                  color="info"
                  sx={{ padding: "5px 15px", marginTop: "1rem" }}
                >
                  {isUploadingReportLogo ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="white"
                      size="lg"
                    />
                  ) : (
                    <Fragment>
                      <Icon fontSize="large">
                        <CameraAlt />
                      </Icon>
                      <MDTypography
                        fontWeight="bold"
                        sx={{ marginLeft: "0.75rem" }}
                        variant="button"
                        color="white"
                      >
                        Upload Reports/PDFs Logo
                      </MDTypography>
                    </Fragment>
                  )}
                </MDButton>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <MDTypography
        fontWeight="bold"
        variant="h5"
        sx={{ marginTop: "1rem" }}
        display="flex"
        alignItems="flex-start"
      >
        PDF Downloads
        <ToolTip
          info={"Specify whether clients are allowed to download policies as pdfs or not."}
        />
      </MDTypography>

      <MDBox>
        <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
          {isFetchingPDFSettings ? (
            <LoadingSpinner subClass="text-center" size="lg" />
          ) : (
            <MDBox mb={2}>
              <RadioGroup
                name="pdfSetting"
                value={pdfSettingValue}
                onChange={handlePDFSettingRadioChange}
              >
                <Grid spacing={2} container width="100%">
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="yes"
                      control={<Radio />}
                      label={
                        <div onClick={() => setPdfSettingValue("yes")}>
                          Allow PDF Downloads
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControlLabel
                      className="emailPreferenceRadioDiv"
                      value="no"
                      control={<Radio />}
                      label={
                        <div onClick={() => setPdfSettingValue("no")}>
                          Disable PDF Downloads
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <DashboardActionButton
                action={btnOnClickSavePDFSettingsChanges}
                btnText="Save Changes"
                btnIcon="save"
                textColor="white"
                bgColor="success"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>

      <MDTypography
        fontWeight="bold"
        variant="h5"
        sx={{ marginTop: "1rem" }}
        display="flex"
        alignItems="flex-start"
      >
        Auto Logout Timer
        <ToolTip
          info={
            "If inactive after set number of minutes, the system will auto logout."
          }
        />
      </MDTypography>

      <MDBox>
        <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
          {isFetchingAutoLogoutTimer ? (
            <LoadingSpinner subClass="text-center" size="lg" />
          ) : (
            <Grid container width="100%" spacing={2}>
              <Grid item xs={6}>
                <MDBox mb={2}>
                  <Formik
                    initialValues={{}}
                    onSubmit={updateTimer}
                  >
                    {() => (
                      <Form id="AddNewPostForm" autoComplete="off">
                        <FormField
                          type="number"
                          label="Logout Timer (minutes)"
                          name="timer"
                          value={logoutTimer}
                          onChange={(event) => {
                            setLogoutTimer(event.target.value);
                          }}
                          placeholder={"Enter Auto Logout time"}
                          variant="standard"
                          fullWidth
                          autoFocus
                        />
                      </Form>
                    )}
                  </Formik>
                  <DashboardActionButton
                    action={updateTimer}
                    btnText="Save Changes"
                    btnIcon="save"
                    textColor="white"
                    bgColor="success"
                  />
                </MDBox>
              </Grid>
            </Grid>
          )}
        </Card>
      </MDBox>

      {/* merge tags */}
      <MDTypography
        fontWeight="bold"
        variant="h5"
        sx={{ marginTop: "1rem" }}
        display="flex"
        alignItems="flex-start"
      >
        Merge Tags
        <ToolTip
          info={"These can be used to capture dynamic content for policies."}
        />
      </MDTypography>
      <MDBox>
        <Card sx={{ marginTop: "16px", padding: "1.2rem" }}>
          <MDBox mb={2}>
            <MDTypography variant="body1">
              Merge tags are used to dynamically insert data into your
              templates. For example, you can use the merge tag{" "}
              <code>{"{{company_name}}"}</code> to insert the client's name into
              your template.
            </MDTypography>
            <MDTypography variant="body1">
              Here you can define your own merge tags. You can set the value of
              the merge tag in the client's configuration.
            </MDTypography>
          </MDBox>
          {isFetchingMergeTags ? (
            <LoadingSpinner subClass="text-center" size="lg" />
          ) : (
            <MDBox mb={2}>
              <MDBox
                display="flex"
                gap=".6rem"
                justifyContent="flex-end"
                mb={2}
              >
                <DashboardActionButton
                  action={createNewTag}
                  btnText="Create New Merge Tag"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                />
              </MDBox>
              {dataTableData.rows.length === 0 ? (
                <MDTypography variant="p">
                  Please add some merge tags first.
                </MDTypography>
              ) : (
                <DataTable
                  table={dataTableData}
                  entriesPerPage={true}
                  showTotalEntries={false}
                  isSorted={false}
                />
              )}
            </MDBox>
          )}
        </Card>
      </MDBox>
      <EditTagsDialog
        open={tagDialogOpen}
        onClose={onTagDialogClose}
        onSubmit={onTagDialogSubmit}
        objUser={objUser}
        mergeTag={currenTag}
      />
    </DashboardLayout>
  );
};

export default CompanyConfig;
