import { combineReducers } from "redux";
// import authReducers from "./auth/";
import companiesSlice from "./companiesSlice";
import clientSlice from "./clientSlice";
import companyUsersSlice from "./companyUsersSlice";
import clientContactsSlice from "./clientContactsSlice";

const companiesReducers = combineReducers({
  companies: companiesSlice,
  client: clientSlice,
  companyUsers: companyUsersSlice,
  clientContacts: clientContactsSlice,
});

export default companiesReducers;
