import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const getAdoptionCampaigns = createAsyncThunk(
  "policy/get-campaigns",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/adoption-campaigns/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPolicies = createAsyncThunk(
  "company/getPolicies",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/policies/all-published`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getAudiences = createAsyncThunk(
  "company/getAudiences",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/audiences/adoption`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewAdoptionCampaign = createAsyncThunk(
  "policy/create-campaign",
  async (values, thunkApi) => {
    try {
      const {
        objUser,
        selectedAudience,
        campaignName,
        campaignDescription,
        reminderTime,
        sections,
      } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/adoption-campaigns/`,
        {
          selectedAudience,
          campaignName,
          campaignDescription,
          reminderTime,
          sections,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const loadAdoptionCampaign = createAsyncThunk(
  "policy/load-campaign",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/campaigns/${campaignId}/`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resendCampaignToAllNotAdopted = createAsyncThunk(
  "policy/resend-campaign-to-all",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/${campaignId}/resend-to-all`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const exportAdoptersListToCsv = createAsyncThunk(
  "policy/export-list-to-csv",
  async (values, thunkApi) => {
    try {
      const { objUser, campaignId, campaignData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/adoption-campaigns/${campaignId}/export-adopters-list-to-csv`,
        {
          campaignData,
        }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
