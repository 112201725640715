import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import {
  TEMPLATES_SERVER,
  COMPANIES_SERVER,
} from "components/utils/constants/misc";

const createTemplateURL = (objUser, client_id) => {
  return TEMPLATES_SERVER.replace(
    "{companyId}",
    objUser.user.company_id
  ).replace("{clientId}", client_id);
};

/**client template actions */

export const getTemplates = createAsyncThunk(
  "templates/get",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const this_templates_server = createTemplateURL(objUser, values.id);
      const res = await axios.get(
        `${BACKEND_API_URL}${this_templates_server}/?per_page=${_per_page}&page=${_page}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTemplatePDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${values.objUser.user.company_id}/templates/download/pdf/${values.objId}`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getTemplatePreview = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${values.objUser.user.company_id}/templates/preview/${values.objId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getClonedTemplatesFromGlobal = createAsyncThunk(
  "getClonedTemplatesFromGlobal",
  async (values, thunkApi) => {
    try {
      const { templateId, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/templates-from-global/${templateId}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplate = createAsyncThunk(
  "template/create",
  async (values, thunkApi) => {
    try {
      const { objUser, clientId, ...objPostData } = values;
      const this_templates_server = createTemplateURL(objUser, clientId);
      const res = await axios.post(
        `${BACKEND_API_URL}${this_templates_server}/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchTemplate = createAsyncThunk(
  "template/fetch",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, clientId } = values;
      const this_templates_server = createTemplateURL(objUser, clientId);
      const res = await axios.get(
        `${BACKEND_API_URL}${this_templates_server}/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveTemplateChanges = createAsyncThunk(
  "template/saveTemplateChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, clientId, templateId, ...objPostData } = values;
      const this_templates_server = createTemplateURL(objUser, clientId);
      const res = await axios.post(
        `${BACKEND_API_URL}${this_templates_server}/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

/**company template actions */
export const getCompanyTemplates = createAsyncThunk(
  "templates/get",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/?per_page=${_per_page}&page=${_page}`
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewCompanyTemplate = createAsyncThunk(
  "template/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchCompanyTemplate = createAsyncThunk(
  "template/fetch",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveCompanyTemplateChanges = createAsyncThunk(
  "template/saveTemplateChanges",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/${templateId}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createNewTemplateClone = createAsyncThunk(
  "template/createNewTemplateClone",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/clone/clone-template`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkImportTemplates = createAsyncThunk(
  "template/bulkImportTemplates",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/clone/bulk-clone-templates`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const transferDocuments = createAsyncThunk(
  "template/transferDocuments",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/documents/transfer`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "template/deleteTemplate",
  async (values, thunkApi) => {
    try {
      const { objUser, templateId } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/templates/${templateId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
