import { createSlice, current } from "@reduxjs/toolkit";
import {
  fetchClientContactsById,
  clearClientContactsById,
  storeClientContactByClientId,
  updateClientContactByClientId,
  fetchClientContactByContactId,
  deleteClientContactByContactId,
  deleteClientContactByContactIdfromStore,
} from "./clientContactsActions";

const initialState = {
  // loading: false,
  // userInfo: null,
  // userToken,
  // error: null,
  // success: false,
};

const clientContactsSlice = createSlice({
  name: "clientContacts",
  initialState,
  reducers: {},
  extraReducers: {
    // fetchClientContactsById
    [fetchClientContactsById.pending]: (state) => {
      state.loading = true;
    },
    [fetchClientContactsById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
    [fetchClientContactsById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },

    // clearClientContactsById
    [clearClientContactsById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
    [clearClientContactsById.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },

    // storeClientContactByClientId
    [storeClientContactByClientId.pending]: (state) => {
      state.loading = true;
    },
    [storeClientContactByClientId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientContactByIdFormSuccess = payload;
    },
    [storeClientContactByClientId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objStoreClientContactByIdFormSuccess = payload;
    },

    // updateClientContactByClientId
    [updateClientContactByClientId.pending]: (state) => {
      state.loading = true;
    },
    [updateClientContactByClientId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateClientContactByIdFormSuccess = payload;
    },
    [updateClientContactByClientId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objUpdateClientContactByIdFormSuccess = payload;
    },

    // fetchClientContactByContactId
    [fetchClientContactByContactId.pending]: (state) => {
      state.loading = true;
    },
    [fetchClientContactByContactId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objFetchClientContactByContactIdData = payload;
    },
    [fetchClientContactByContactId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objFetchClientContactByContactIdData = payload;
    },
    // deleteClientContactByContactId
    [deleteClientContactByContactId.pending]: (state) => {
      state.loading = true;
    },
    [deleteClientContactByContactId.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteClientContactByContactIdData = payload;
    },
    [deleteClientContactByContactId.rejected]: (state, { payload }) => {
      state.loading = false;
      state.objDeleteClientContactByContactIdData = payload;
    },
    // deleteClientContactByContactIdfromStore
    [deleteClientContactByContactIdfromStore.fulfilled]: (state, action) => {
      state.loading = false;

      let objCurrentClientContact = action.payload;

      const objNewClientContactsData =
        state.objfetchClientContactsByIdData.data.data.data.filter(
          (item) => item.id !== objCurrentClientContact.id
        );

      state.objfetchClientContactsByIdData.data.data.data =
        objNewClientContactsData;
    },
    [deleteClientContactByContactIdfromStore.rejected]: (
      state,
      { payload }
    ) => {
      state.loading = false;
      state.objfetchClientContactsByIdData = payload;
    },
  },
});

// export const { logout } = clientContactsSlice.actions;

export default clientContactsSlice.reducer;
