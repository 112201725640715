import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import LandingLogo from "assets/images/landing-logo-box.png";
import "../../pages/Landing/index.css";
import "font/Nexa-Light.ttf";
import "font/NexaHeavy.ttf";

function Header({ objUser }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (objUser.authorized) {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <MDBox
        minHeight="87vh"
        sx={{
          background:
            "linear-gradient(to bottom, rgb(26,107,170) 20%, rgb(14,58,94) 100%)",
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            textAlign: "center",
            flexDirection: "column",
            minHeight: "inherit",
            paddingTop: 0,
          }}
        >
          <Grid item xs={11} lg={5.5} p={0}>
            <img
              src={LandingLogo}
              style={{ marginTop: "-20px" }}
              alt="Compliance Scorecard Logo"
            />
          </Grid>

          <Grid item xs={11} lg={8.5} sx={{ paddingTop: "4rem" }}>
            <MDBox mb={2}>
              <p
                className="hero-text"
                style={{ color: "white", fontSize: "3rem", lineHeight: "1.2" }}
              >
                Compliance Scorecard: The SaaS Governance Program designed for
                MSPs.
              </p>
            </MDBox>
          </Grid>

          {/* buttons */}
          <MDBox
            flexDirection={{ xs: "column", md: "row" }}
            paddingBottom={{ xs: "4rem" }}
            sx={{ display: "flex", gap: 3, paddingTop: "33px" }}
          >
            <Link to="/sign-in" style={{ flexShrink: 0 }}>
              <span className="landing-page-links">Sign In</span>
            </Link>
            <Link to="/sign-up">
              <span className="landing-page-links" style={{ flexShrink: 0 }}>
                Create Account
              </span>
            </Link>
            <Link to="/forgot-password">
              <span className="landing-page-links" style={{ flexShrink: 0 }}>
                Forgot Password
              </span>
            </Link>
          </MDBox>
          {/* buttons ends */}
        </Grid>
      </MDBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Header;
