import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchClientLeaderboard } from 'features/leaderboard/clientLeaderboardActions'; // Adjust the path as needed
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import Icon from "@mui/material/Icon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import './sidebar-styles.css';

const GamificationSidebar = ({ isOpen, toggleSidebar }) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const dispatch = useDispatch();
    const [clientLeaderboardData, setClientLeaderboardData] = useState({
        client_list: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const sidebarRef = useRef(null);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            toggleSidebar(); // Use toggleSidebar instead of setIsOpen
        }
    };

    useEffect(() => {
        if (isOpen) {
            setIsLoading(true);
            fetchLeaderboardData();
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const fetchLeaderboardData = () => {
        dispatch(fetchClientLeaderboard())
            .then(unwrapResult)
            .then((originalPromiseResult) => {
                setIsLoading(false);
                setClientLeaderboardData(originalPromiseResult);
            })
            .catch((rejectedValueOrSerializedError) => {
                console.error("Failed to fetch leaderboard data:", rejectedValueOrSerializedError);
                setIsLoading(false);
                setClientLeaderboardData([]);
            });
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <div ref={sidebarRef} className={`gamification-sidebar ${isOpen ? 'open' : 'closed'}`}>
            {isOpen && (
                <div className="gamification-sidebar-content">
                    <Icon 
                      fontSize="medium" 
                      className="close-icon" 
                      onClick={toggleSidebar}
                    >
                        close
                    </Icon>
                    <Tabs 
                      value={selectedTab} 
                      onChange={handleTabChange} 
                      className="sidebar-tabs"
                    >
                        <Tab label="Client Leaderboard" className={selectedTab === 0 ? 'tab-active sidebar-tab' : 'sidebar-tab'} />
                        <Tab label="MSP Leaderboard" className={selectedTab === 1 ? 'tab-active sidebar-tab' : 'sidebar-tab'} />
                        <Tab label="Scoring Details" className={selectedTab === 2 ? 'tab-active sidebar-tab' : 'sidebar-tab'} />
                    </Tabs>
                    <div className="tab-content">
                        {selectedTab === 0 && (
                            <div className="leaderboard-content">
                                <h3>Client Leaderboard</h3>
                                <p>Compete against other clients in your MSP Network.</p>
                                {isLoading === false ? (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Client</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {clientLeaderboardData?.client_list && clientLeaderboardData.client_list
                                        .slice()
                                        .sort((a, b) => {
                                            // Converting the "points" strings to integers and sort in descending order
                                            const pointsA = parseInt(a.points, 10);
                                            const pointsB = parseInt(b.points, 10);
                                            return pointsB - pointsA; 
                                        })
                                        .map((item, index) => {   
                                            if (index === 0) {
                                                return (
                                                <tr className="first-place" key={item.id}>
                                                    <td><span className="material-icons-round award-icon notranslate MuiIcon-root MuiIcon-fontSizeMedium" aria-hidden="true">emoji_events</span>#1 - {item.name}</td>
                                                    <td>{item.points}</td>
                                                </tr>
                                                )
                                            } else if (index === 1) {
                                                return (
                                                <tr className="second-place" key={item.id}>
                                                    <td><span className="material-icons-round award-icon notranslate MuiIcon-root MuiIcon-fontSizeMedium" aria-hidden="true">emoji_events</span>#2 - {item.name}</td>
                                                    <td>{item.points}</td>
                                                </tr>
                                                )
                                            } else if (index === 2) {
                                                return (
                                                <tr className="third-place" key={item.id}>
                                                    <td><span className="material-icons-round award-icon notranslate MuiIcon-root MuiIcon-fontSizeMedium" aria-hidden="true">emoji_events</span>#3 - {item.name}</td>
                                                    <td>{item.points}</td>
                                                </tr>
                                                )
                                            } else {
                                                return (
                                                <tr key={item.id}>
                                                    <td>{item.name}</td>
                                                    <td>{item.points}</td>
                                                </tr>
                                                );
                                            }
                                        })
                                    }
                                    </tbody>
                                </table>
                                ) : (
                                    <LoadingSpinner
                                        subClass="text-center"
                                        color="success"
                                        size="lg"
                                    />
                                )}
                            </div>
                        )}
                        {selectedTab === 1 && (
                            <div className="leaderboard-content">
                                <h3>MSP Leaderboard</h3>
                                {/* TODO Render MSP Leaderboard data here */}
                                <p>An opt-in leaderboard to see how you stack up against other MSPs.</p>
                                <p><em>Coming Early September...</em></p>
                            </div>
                        )}
                        {selectedTab === 2 && (
                            <div className="leaderboard-content">
                                <h3>Point Accruals</h3>
                                <h4>Policy Scorecard</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Points Earned</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Policy Created</td>
                                            <td>+1 Point</td>
                                        </tr>
                                        <tr>
                                            <td>Policy Review Date Set</td>
                                            <td>+2 Points</td>
                                        </tr>
                                        <tr>
                                            <td>A Published Policy Version Exists</td>
                                            <td>+2 Points</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 style={{marginTop: 10}}>Assessment Scorecard</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Points Earned</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Each Assessment Event Created</td>
                                            <td>+1 Point</td>
                                        </tr>
                                        <tr>
                                            <td>Published Version of Any Event</td>
                                            <td>+2 Points</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <h4 style={{marginTop: 10}}>Asset Scorecard</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Points Earned</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Asset List Created</td>
                                            <td>+1 Point</td>
                                        </tr>
                                        <tr>
                                            <td>Asset List Published Version Exists</td>
                                            <td>+2 Points</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default GamificationSidebar;
