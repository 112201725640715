import { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
// @mui icons
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { unwrapResult } from "@reduxjs/toolkit";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { fetchClientContactByContactId } from "features/company/clientContactsActions";
import EditClientContactByClientContactIdForm from "./components/EditClientContactByClientContactIdForm";
import DashboardButton from "views/components/DashboardButtton";
const _objInitialValues = {
  txtFirstName: "",
  txtLastName: "",
  txtEmail: "",
  txtTitle: "",
  txtPhone: "",
  txtRoleRegisterAccess: "",
  txtAssessmentAccess: "",
};
const EditClientContactByClientContactIdFormJWTSchema = Yup.object().shape({
  txtFirstName: Yup.string().required("First Name is required."),
  txtLastName: Yup.string().required("Last Name is required."),
  txtEmail: Yup.string().email("Invalid email").required("Email is Required"),
  txtTitle: Yup.string(),
  txtPhone: Yup.string().min(
    6,
    "Phone number is too short!(min 6 characters are required)"
  ),
});
const EditClientContactByClientContactId = (props) => {
  const { objUser } = props;
  const isClientUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "client_user"
      : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [objInitialValues, setObjInitialValues] = useState(_objInitialValues);
  const [objClientContact, setObjClientContact] = useState({});
  const [isClientUserContact, setIsClientUserContact] = useState(false);
  const [isMSPUserContact, setIsMSPUserContact] = useState(false);
  let { client_contact_id } = useParams();
  const [isLoadingClientContactById, setIsLoadingClientContactById] =
    useState(false);
  useEffect(() => {
    if (isClientUser) {
      navigate(`/home`);
    }
    // setObjVerifiyEmailAddressErrors({});
    setIsLoadingClientContactById(true);
    dispatch(fetchClientContactByContactId({ objUser, client_contact_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingClientContactById(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        setObjClientContact(response.data);
        let __ObjInitialValues;
        if (
          response.data.user &&
          response.data.user.role.name == "client_user"
        ) {
          setIsClientUserContact(true);
          __ObjInitialValues = {
            txtFirstName: response.data.first_name ?? "",
            txtLastName: response.data.last_name ?? "",
            txtEmail: response.data.email ?? "",
            txtTitle: response.data.title ?? "",
            txtPhone: response.data.phone ?? "",
            txtRoleRegisterAccess:
              response.data.user?.permissions?.risk_register_access ?? "",
            txtAssessmentAccess:
              response.data.user?.permissions?.assessment_access ?? "",
          };
        } else {
          __ObjInitialValues = {
            txtFirstName: response.data.first_name ?? "",
            txtLastName: response.data.last_name ?? "",
            txtEmail: response.data.email ?? "",
            txtTitle: response.data.title ?? "",
            txtPhone: response.data.phone ?? "",
          };
        }
        if (
          response.data.user &&
          response.data.user.role.name == "company_user"
        ) {
          setIsMSPUserContact(true);
        }

        setObjInitialValues(__ObjInitialValues);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingClientContactById(false);
        // showMsg("error", "Please correct the errors.");
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate("/clients");
        }
      });
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
        {/* <DashboardButton
          component={Link}
          to={`/clients/${client_id}/audiences/`}
          // state={{ routeDictionary }}
          btnText="Manage Audiences"
          btnIcon="peopleAlt"
          textColor="white"
          bgColor="dark"
        />
        <DashboardButton
          component={Link}
          to={`/clients/${client_id}/policies/`}
          // state={{ routeDictionary }}
          btnText="Manage Policies"
          btnIcon="description"
          textColor="white"
          bgColor="dark"
        />
        <DashboardButton
          component={Link}
          to={`/clients/${client_id}/templates/`}
          // state={{ routeDictionary }}
          btnText="Policy Templates"
          btnIcon="content_copy"
          textColor="white"
          bgColor="dark"
        /> */}
      </MDBox>
      {/* <Card> */}
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          {isLoadingClientContactById === false &&
          Object.keys(objClientContact).length > 0 &&
          objInitialValues.txtFirstName !== "" ? (
            <EditClientContactByClientContactIdForm
              client_contact_id={client_contact_id}
              objUser={objUser}
              isClientUserContact={isClientUserContact}
              isMSPUserContact={isMSPUserContact}
              objInitialValues={objInitialValues}
              EditClientContactByClientContactIdFormJWTSchema={
                EditClientContactByClientContactIdFormJWTSchema
              }
              setIsClientUserContact={setIsClientUserContact}
              setIsMSPUserContact={setIsMSPUserContact}
            />
          ) : (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          )}
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default EditClientContactByClientContactId;
