import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import { Alert } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
// Data
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getRiskRegisterList,
  getRiskRegisterListItems,
  getRiskRegistersPDF,
} from "features/company/riskRegisterActions";

import { Fragment } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import RiskRegisterTable from "../RiskRegister/components/RiskRegisterTable/RiskRegisterTable";
import MDTypography from "components/MDTypography";
import NoActionItemsTable from "./NoActionItemsTable/NoActionItemsTable";
import DashboardActionButton from "views/components/DashboardActionButton";
import { getRiskRegistersCSV } from "features/company/riskRegisterActions";

const RiskRegisterLists = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const [isFetchingPDF, setIsFetchingPDF] = useState(false);
  const [isLoadingNoActionItems, setIsLoadingNoActionItems] = useState(true);
  const [id, setId] = useState(null);
  const [riskRegisterList, setriskRegisterList] = useState(null);
  const [riskLevels, setRiskLevels] = useState([]);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [objNoActionItemsTableData, setObjNoActionItemsTableData] =
    useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [totalNoActionItemsCount, setTotalNoActionItemsCount] = useState(0);
  const [perPageRF, setPerPageRF] = useState(25);
  const [pageRF, setPageRF] = useState(1);
  const [perPageDA, setPerPageDA] = useState(25);
  const [pageDA, setPageDA] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    getList();
  }, []);

  const getList = () => {
    setIsLoading(true);
    dispatch(getRiskRegisterList({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        if (response.data.riskRegisterList) {
          setriskRegisterList(response.data.riskRegisterList);
          setId(response.data.riskRegisterList.id);
          loadMoreItems(response.data.riskRegisterList.id);
          loadMoreNoActionItems(response.data.riskRegisterList.id);
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadMoreItems = (listId) => {
    setIsLoadingItems(true);
    dispatch(getRiskRegisterListItems({ objUser, listId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        const objRows = response.data.riskRegisterItems.map((objEvent) => {
          const objTr = {};
          objTr.eventName = objEvent.assessment_event_name;
          objTr.itemName = objEvent.item_name;
          objTr.riskLevel = objEvent.risk_level;
          objTr.priorityLevel = !objEvent.priority_level
            ? null
            : objEvent.priority_level < 6
            ? "Low"
            : objEvent.priority_level < 13
            ? "Medium"
            : objEvent.priority_level < 21
            ? "High"
            : objEvent.priority_level < 26
            ? "Very High"
            : null;
          objTr.will_take_action = objEvent.will_take_action;
          objTr.id = objEvent.id;
          return objTr;
        });
        const itemScores = response.data.itemScores.map((item) => {
          return item.name;
        });
        setRiskLevels(itemScores);
        setTotalCount(response.data.riskRegisterItems.length);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoadingItems(false);
      });
  };

  const loadMoreNoActionItems = (listId) => {
    setIsLoadingNoActionItems(true);
    dispatch(getRiskRegisterListItems({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        const objNoActionItemsRows = response.data.noActionItems.map(
          (objEvent) => {
            const objTr = {};
            objTr.eventName = objEvent.assessment_event_name;
            objTr.itemName = objEvent.item_name;
            objTr.reason = objEvent.reason;
            objTr.decidedAction = objEvent.decided_action;
            objTr.dateDeclined = objEvent.formattedCreatedAt;
            objTr.id = objEvent.id;
            return objTr;
          }
        );
        setTotalNoActionItemsCount(response.data.noActionItems.length);
        setObjNoActionItemsTableData({
          ...dataTableData,
          rows: objNoActionItemsRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoadingNoActionItems(false);
      });
  };

  const printPage = (output) => {
    setIsFetchingPDF(true);
    dispatch(
      output == "pdf"
        ? getRiskRegistersPDF({
            objUser,
            listId: id,
          })
        : getRiskRegistersCSV({
            objUser,
            listId: id,
          })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;

          link.setAttribute("download", `Risk Register.` + output); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // TODO handle download error
        setIsFetchingPDF(false);
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        {isLoading === false && !riskRegisterList ? (
          <>
            <p>There are no Action Items</p>
          </>
        ) : (
          <Fragment>
            <MDBox
              display={"flex"}
              gap=".6rem"
              alignItems="flex-start"
              justifyContent="space-between"
              mb={1}
            >
              <MDTypography
                sx={{ paddingTop: "1rem" }}
                fontWeight="bold"
                variant="h4"
                color="dark"
              >
                Action Items
              </MDTypography>
              {isFetchingPDF ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="sm"
                />
              ) : (
                <MDBox display="flex" gap={2}>
                  <DashboardActionButton
                    action={() => printPage("csv")}
                    btnText="Export to CSV"
                    btnIcon="print"
                    textColor="white"
                    bgColor="success"
                    sx={{ marginTop: "0px !important" }}
                  />
                  <DashboardActionButton
                    action={() => printPage("pdf")}
                    btnText="Export to PDF"
                    btnIcon="print"
                    textColor="white"
                    bgColor="success"
                    sx={{ marginTop: "0px !important" }}
                  />
                </MDBox>
              )}
            </MDBox>
            {(objUser.user.role.name === "company_user" ||
              objUser.user.role.name === "admin") && (
              <MDBox>
                <Alert
                  severity="success"
                  icon={<InfoIcon fontSize="inherit" />}
                >
                  The Decided Action step is intended to be filled out by the
                  selected company’s Risk Register users. This indicates if they
                  will take steps to address the risk item.
                </Alert>
              </MDBox>
            )}

            <Card sx={{ marginTop: "16px" }}>
              {isLoadingItems === false &&
              objTableData !== undefined &&
              objTableData.rows !== undefined &&
              objTableData.rows.length > 0 &&
              totalCount > 0 ? (
                <RiskRegisterTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  objUser={objUser}
                  totalCount={totalCount}
                  listItems={true}
                  loadMoreItems={loadMoreItems}
                  listId={id}
                  perPage={perPageRF}
                  _page={pageRF}
                  setPage={setPageRF}
                />
              ) : (
                <Fragment>
                  {isLoadingItems ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p>There are no Action Items</p>
                  )}
                </Fragment>
              )}
            </Card>
          </Fragment>
        )}
      </MDBox>

      {isLoading === false && totalNoActionItemsCount > 0 && (
        <>
          <MDBox display={"flex"} gap=".6rem" alignItems="flex-start" mb={0}>
            <MDTypography
              sx={{ paddingTop: "2rem" }}
              fontWeight="bold"
              variant="h4"
              color="dark"
            >
              Declined Actions
            </MDTypography>
          </MDBox>
          {(objUser.user.role.name === "company_user" ||
            objUser.user.role.name === "admin") && (
            <MDBox>
              <Alert severity="success" icon={<InfoIcon fontSize="inherit" />}>
                Items declined by the company’s Risk Register users will appear
                here as a record.
              </Alert>
            </MDBox>
          )}
          <Card sx={{ marginTop: "16px" }}>
            {isLoading === false &&
            objNoActionItemsTableData !== undefined &&
            objNoActionItemsTableData.rows !== undefined &&
            objNoActionItemsTableData.rows.length > 0 &&
            totalNoActionItemsCount > 0 ? (
              <>
                <NoActionItemsTable
                  table={objNoActionItemsTableData}
                  entriesPerPage={true}
                  canSearch
                  objUser={objUser}
                  totalCount={totalNoActionItemsCount}
                  loadMoreNoActionItems={loadMoreNoActionItems}
                  perPage={perPageDA}
                  setPerPage={setPerPageDA}
                  _page={pageDA}
                  setPage={setPageDA}
                />
              </>
            ) : (
              <Fragment>
                {isLoadingNoActionItems ? (
                  <LoadingSpinner
                    subClass="text-center"
                    color="success"
                    size="lg"
                  />
                ) : (
                  <p>There are no Declined Actions</p>
                )}
              </Fragment>
            )}
          </Card>
        </>
      )}
    </DashboardLayout>
  );
};

export default RiskRegisterLists;
