import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {
  checkIfMSPHasConfiguredAnyConnectors,
  createBlankAssetPolicy,
} from "features/connectSecure/connectSecureActions";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardButton from "views/components/DashboardButtton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import "./settings.css";
import AzureImg from "assets/images/logos/azure.png";
import CyberCNSLogo from "assets/images/logos/cybercns.jpg";
import LionGuardLogo from "assets/images/logos/lionguard.png";
import NodewareLogo from "assets/images/logos/nodeware.jpg";
import NinjaLogo from "assets/images/logos/ninja_logo.jpg";
import NCentralLogo from "assets/images/logos/ncentral.jpg";

const AssetManagement = (props) => {
  const dispatch = useDispatch();
  const { assetType } = useParams();
  const hardware = useRef(null);
  const software = useRef(null);
  const data = useRef(null);
  const human = useRef(null);
  const network = useRef(null);
  const facility = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const { objUser } = props;
  const isClientUser =
    objUser.user?.role?.name === "client_user" ? true : false;

  /**connectsecure v3*/
  const [isCyberCNSConfigured, setIsCyberCNSConfigured] = useState(false);
  const [currentClientIsMappedToCyberCNS, setCurrentClientIsMappedToCyberCNS] =
    useState(false);

  /**connectsecure v4 */
  const [isCyberCNSv4Configured, setIsCyberCNSv4Configured] = useState(false);
  const [
    currentClientIsMappedToCyberCNSv4,
    setCurrentClientIsMappedToCyberCNSv4,
  ] = useState(false);

  /**nodeware */
  const [isNodewareConfigured, setIsNodewareConfigured] = useState(false);
  const [currentClientIsMappedToNodeware, setCurrentClientIsMappedToNodeware] =
    useState(false);

  /**Lionguard */
  const [isLionguardConfigured, setIsLionguardConfigured] = useState(false);
  const [
    currentClientIsMappedToLionguard,
    setCurrentClientIsMappedToLionguard,
  ] = useState(false);

  /**Ninja */
  const [isNinjaConfigured, setIsNinjaConfigured] = useState(false);
  const [currentClientIsMappedToNinja, setCurrentClientIsMappedToNinja] =
    useState(false);

  /**Ncentral */
  const [isNcentralConfigured, setIsNcentralConfigured] = useState(false);

  /**msgraph */
  const [isMSGraphConfigured, setIsMSGraphConfigured] = useState(false);
  const [currentClientIsMappedToMSGraph, setCurrentClientIsMappedToMSGGraph] =
    useState(false);

  const [connectorsHTTPErrors, setConnectorsHTTPErrors] = useState([]);

  useEffect(() => {
    checkConnectors();
  }, []);

  const scrollTo = (assetType) => {
    let section;
    if (assetType) {
      switch (assetType) {
        case "hardware":
          section = hardware;
          break;
        case "software":
          section = software;
          break;
        case "data":
          section = data;
          break;
        case "human":
          section = human;
          break;
        case "network":
          section = network;
          break;
        case "facility":
          section = facility;
          break;
      }
      if (section.current) {
        section.current.scrollIntoView({ behavior: "smooth" });
        // slight offset to top
        window.scrollBy(0, -100);
      }
    }
  };

  const checkConnectors = async () => {
    setIsLoading(true);
    dispatch(checkIfMSPHasConfiguredAnyConnectors())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setIsLoading(false);

        if (response.data.hasConnectors) {
          setConnectorsHTTPErrors(response.data.connectorsHTTPErrors);
          /**
           * Idea is that, you can initially configure a connector and later destroy the configuration
           * In such a case, we will still need to display the stats of the previous mappings
           */
          /**connectsecure */
          setIsCyberCNSConfigured(
            response.data.configuredConnectors.isCyberCNSConfigured
          );

          setCurrentClientIsMappedToCyberCNS(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToCyberCNS
          );
          /****************************************** */

          /**connectsecure v4*/
          setIsCyberCNSv4Configured(
            response.data.configuredConnectors.isCyberCNSv4Configured
          );

          setCurrentClientIsMappedToCyberCNSv4(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToCyberCNSv4
          );
          /****************************************** */

          /**nodeware */
          setIsNodewareConfigured(
            response.data.configuredConnectors.isNodewareConfigured
          );

          setCurrentClientIsMappedToNodeware(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToNodeware
          );

          /**Lion guard */
          setIsLionguardConfigured(
            response.data.configuredConnectors.isLionguardConfigured
          );

          setCurrentClientIsMappedToLionguard(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToLionguard
          );

          /**Ninja */
          setIsNinjaConfigured(
            response.data.configuredConnectors.isNinjaConfigured
          );

          setCurrentClientIsMappedToNinja(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToNinja
          );

          /**Ncentral */
          setIsNcentralConfigured(
            response.data.configuredConnectors.isNcentralConfigured
          );

          /**MSGraph */
          setIsMSGraphConfigured(
            response.data.configuredConnectors.isMsgraphConfigured
          );

          setCurrentClientIsMappedToMSGGraph(
            response.data.statusOfConnectorsForCurrentClient
              .currentClientIsMappedToMsgraph
          );
        }
        setTimeout(() => {
          scrollTo(assetType);
        }, 100);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error fetching connectors data");
        }
      });
  };

  const newBlankWithType = (type) => {
    setIsLoading(true);
    submitNewBlankAssetPolicy({}, type, setIsLoading).finally(() => {
      setIsLoading(false);
    });
  };

  const submitNewBlankAssetPolicy = (initialData, type, setIsLoading) => {
    return dispatch(
      createBlankAssetPolicy({
        data: initialData,
        assets_type_imported: type,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        navigate(
          `/asset-lists/${response.data.finalPolicyObject.id}/${response.data.updatedMajorVersion}`
        );
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error creating blank asset list");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid spacing={2} container width="100%">
          {isLoading ? (
            <Grid item xs={12} lg={23}>
              <Card sx={{ marginTop: "16px" }}>
                <MDBox p={2}>
                  <Grid>
                    <MDTypography variant="p" fontWeight="regular">
                      <LoadingSpinner />
                    </MDTypography>
                  </Grid>
                </MDBox>
              </Card>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} lg={12}>
                <MDBox
                  mb={1}
                  textAlign="left"
                  sx={{
                    display: "inline-flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <MDTypography variant="h2">
                    Asset Data Connectors
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold">
                    <DashboardButton
                      component={Link}
                      // to={`/asset-management/connectsecure/clients`}
                      to={`/assets/connections`}
                      state={{ routeDictionary }}
                      btnText="Review & Setup Connectors"
                      btnIcon="settings"
                      textColor="white"
                      bgColor="success"
                      disabled={isClientUser}
                    />
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={hardware}>
                  Hardware Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Hardware")}
                  btnText="Create Blank Hardware Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>

              {/* connectsecure V3 */}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isCyberCNSConfigured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>

                      <Grid item xs={12} lg={2}>
                        <img src={CyberCNSLogo} alt="CyberCNS" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        ConnectSecure v3
                      </MDTypography>
                      {/* <MDTypography variant="p" fontWeight="regular">
                        {totalNumberOfClientsMappedToCyberCNS} of{" "}
                        {totalClientsFromCyberCNS} clients mapped
                      </MDTypography> */}
                      <MDTypography variant="p" fontWeight="regular">
                        {currentClientIsMappedToCyberCNS
                          ? "Client is mapped"
                          : "Client is not mapped"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/connectsecure`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        {/* <DashboardButton
                          component={Link}
                          to={`/asset-management/connectsecure/clients`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        /> */}
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/connectsecure`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              {/* connectsecure V4 */}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isCyberCNSv4Configured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>
                      <Grid item xs={12} lg={2}>
                        <img src={CyberCNSLogo} alt="CyberCNS" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        ConnectSecure v4
                      </MDTypography>
                      {/* <MDTypography variant="p" fontWeight="regular">
                        {totalNumberOfClientsMappedToCyberCNS} of{" "}
                        {totalClientsFromCyberCNS} clients mapped
                      </MDTypography> */}
                      <MDTypography variant="p" fontWeight="regular">
                        {currentClientIsMappedToCyberCNSv4
                          ? "Client is mapped"
                          : "Client is not mapped"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/connectsecure/v4`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        {/* <DashboardButton
                          component={Link}
                          to={`/asset-management/connectsecure/clients`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        /> */}
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/connectsecure/v4`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end connectsecure */}

              {/* nodeware*/}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isNodewareConfigured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>
                      <Grid item xs={12} lg={2}>
                        <img src={NodewareLogo} alt="Nodeware" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Nodeware
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        {currentClientIsMappedToNodeware
                          ? "Client is mapped"
                          : "Client is not mapped"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/nodeware`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/nodeware`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end nodeware */}
              {/* msgraph*/}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isMSGraphConfigured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>
                      <Grid item xs={12} lg={2}>
                        <img src={AzureImg} alt="Azure" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        MS Graph Devices
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        {currentClientIsMappedToMSGraph
                          ? "Client is mapped"
                          : "Client is not mapped"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/msgraph-assets/clients-to-configure`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/msgraph/hardware`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end msguard */}
              {/* ncentral*/}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isNcentralConfigured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>
                      <Grid item xs={12} lg={2}>
                        <img src={NCentralLogo} alt="Azure" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        N-Central/N-Able
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        {isNcentralConfigured
                          ? "Client is configured"
                          : "Client is not configured"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/ncentral`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/ncentral`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end ncentral */}

              {/*Ninja*/}
              <Grid item xs={12} lg={6}>
                <Card>
                  <MDBox p={2}>
                    <Grid>
                      <Icon
                        sx={{
                          fontSize: "0.5rem",
                          float: "right",
                          color: isNinjaConfigured ? "#38b54a" : "orange",
                        }}
                      >
                        circle
                      </Icon>
                      <Grid item xs={12} lg={2}>
                        <img src={NinjaLogo} alt="Azure" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Ninja
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        {isNinjaConfigured
                          ? "Client is configured"
                          : "Client is not configured"}
                      </MDTypography>
                      <MDBox display="flex" gap=".6rem" alignItems="flex-start">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/ninja`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="text.dark"
                          bgColor="primaryBlue"
                          disabled={isClientUser}
                        />
                        <DashboardButton
                          component={Link}
                          to={`/asset-management/ninja`}
                          state={{ routeDictionary }}
                          btnText="Manage Assets"
                          btnIcon="sync"
                          textColor="white"
                          bgColor="dark"
                        />
                      </MDBox>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
              {/* end ncentral */}

              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={software}>
                  Software Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Software")}
                  btnText="Create Blank Software Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                {/* lionguard*/}
                <Grid item xs={12} lg={6}>
                  <Card>
                    <MDBox p={2}>
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            float: "right",
                            color: isLionguardConfigured ? "#38b54a" : "orange",
                          }}
                        >
                          circle
                        </Icon>
                        <Grid item xs={12} lg={4}>
                          <img src={LionGuardLogo} alt="LionGard" />
                        </Grid>
                        <MDTypography variant="h5" fontWeight="bold">
                          Liongard
                        </MDTypography>
                        <MDTypography variant="p" fontWeight="regular">
                          {currentClientIsMappedToLionguard
                            ? "Client is mapped"
                            : "Client is not mapped"}
                        </MDTypography>
                        <MDBox
                          display="flex"
                          gap=".6rem"
                          alignItems="flex-start"
                        >
                          <DashboardButton
                            component={Link}
                            to={`/assets/connections/liongard`}
                            state={{ routeDictionary }}
                            btnText="Config"
                            btnIcon="settings"
                            textColor="text.dark"
                            bgColor="primaryBlue"
                            disabled={isClientUser}
                          />
                          <DashboardButton
                            component={Link}
                            to={`/asset-management/liongard`}
                            state={{ routeDictionary }}
                            btnText="Manage Assets"
                            btnIcon="sync"
                            textColor="white"
                            bgColor="dark"
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                {/* end lionguard */}
              </Grid>
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={data}>
                  Data Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Data")}
                  btnText="Create Blank Data Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                {/* msgraph/}
                <Grid item xs={12} lg={6}>
                  <Card>
                    <MDBox p={2}>
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            float: "right",
                            color: isMSGraphConfigured ? "#38b54a" : "orange",
                          }}
                        >
                          circle
                        </Icon>
                        <Grid item xs={12} lg={2}>
                          <img src={AzureImg} alt="Azure" />
                        </Grid>
                        <MDTypography variant="h5" fontWeight="bold">
                          MS Graph Usage Data
                        </MDTypography>
                        <MDTypography variant="p" fontWeight="regular">
                          {currentClientIsMappedToMSGraph
                            ? "Client is mapped"
                            : "Client is not mapped"}
                        </MDTypography>
                        <MDBox
                          display="flex"
                          gap=".6rem"
                          alignItems="flex-start"
                        >
                          <DashboardButton
                            component={Link}
                            to={`/assets/connections/msgraph-assets/clients-to-configure`}
                            state={{ routeDictionary }}
                            btnText="Config"
                            btnIcon="settings"
                            textColor="text.dark"
                            bgColor="primaryBlue"
                          />
                          <DashboardButton
                            component={Link}
                            to={`/asset-management/msgraph/data`}
                            state={{ routeDictionary }}
                            btnText="Manage Assets"
                            btnIcon="sync"
                            textColor="white"
                            bgColor="dark"
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                {/ end msguard */}
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="p" fontWeight="regular">
                      There are currently no APIs for this asset category.
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={human}>
                  Human Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Human")}
                  btnText="Create Blank Human Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                {/* msgraph */}
                <Grid item xs={12} lg={6}>
                  <Card>
                    <MDBox p={2}>
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "0.5rem",
                            float: "right",
                            color: isMSGraphConfigured ? "#38b54a" : "orange",
                          }}
                        >
                          circle
                        </Icon>
                        <Grid item xs={12} lg={2}>
                          <img src={AzureImg} alt="Azure" />
                        </Grid>
                        <MDTypography variant="h5" fontWeight="bold">
                          MS Graph Users & Groups
                        </MDTypography>
                        <MDTypography variant="p" fontWeight="regular">
                          {currentClientIsMappedToMSGraph
                            ? "Client is mapped"
                            : "Client is not mapped"}
                        </MDTypography>
                        <MDBox
                          display="flex"
                          gap=".6rem"
                          alignItems="flex-start"
                        >
                          <DashboardButton
                            component={Link}
                            to={`/assets/connections/msgraph-assets/clients-to-configure`}
                            state={{ routeDictionary }}
                            btnText="Config"
                            btnIcon="settings"
                            textColor="text.dark"
                            bgColor="primaryBlue"
                            disabled={isClientUser}
                          />
                          <DashboardButton
                            component={Link}
                            to={`/asset-management/msgraph/people`}
                            state={{ routeDictionary }}
                            btnText="Manage Assets"
                            btnIcon="sync"
                            textColor="white"
                            bgColor="dark"
                          />
                        </MDBox>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
                {/* end msguard */}
                {/*<Card>
                  <MDBox p={2}>
                    <MDTypography variant="p" fontWeight="regular">
                      There are currently no APIs for this asset category.
                    </MDTypography>
                  </MDBox>
                </Card>*/}
              </Grid>
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={network}>
                  Network Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Network")}
                  btnText="Create Blank Network Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="p" fontWeight="regular">
                      There are currently no APIs for this asset category.
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} lg={12}>
                <MDTypography variant="h3" ref={facility}>
                  Facility Assets
                </MDTypography>
              </Grid>
              <Grid item xs={12} lg={12} pt={0}>
                <DashboardActionButton
                  action={() => newBlankWithType("Facility")}
                  btnText="Create Blank Facility Asset List"
                  btnIcon="add"
                  textColor="white"
                  bgColor="success"
                  marginTop="0px"
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <Card>
                  <MDBox p={2}>
                    <MDTypography variant="p" fontWeight="regular">
                      There are currently no APIs for this asset category.
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssetManagement;
