import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
// Data
import { unwrapResult } from "@reduxjs/toolkit";
import { getAssessmentEventVersions } from "features/company/assessmentsActions";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import AssessmentEventsTable from "./components/AssessmentEventsTable/AssessmentEventsTable";
import CreateNewEventDialog from "./components/AssessmentEventsTable/createNewEventDialog";
import MDTypography from "components/MDTypography";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useMaterialUIController } from "context";
import CompareIcon from "@mui/icons-material/Compare";
import tinycolor from "tinycolor2";

const totalScorePercentage = (score, possibleScore) => {
  return ((score / possibleScore) * 100.0).toFixed(2);
};

const ComparisonCard = ({
  assessmentEventObj,
  itemScores,
  latestVersionScore,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const objScore = totalScorePercentage(
    assessmentEventObj?.score,
    assessmentEventObj?.possible_score
  );

  let bgCol = "#1c75bd";

  if (objScore > latestVersionScore) {
    bgCol = "#38b54a";
  } else if (objScore < latestVersionScore) {
    bgCol = "#c21515";
  }

  return assessmentEventObj ? (
    <Card>
      <CardMedia
        sx={{
          height: 40,
          backgroundColor: assessmentEventObj?.risk_level?.color,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          margin: 0,
        }}
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      />

      <CardContent>
        <MDTypography variant="h5" textAlign="center">
          Score: {objScore}%
        </MDTypography>
        <MDTypography variant="h5" textAlign="center">
          Risk Level: {assessmentEventObj?.risk_level?.name}
        </MDTypography>
        {Object.entries(assessmentEventObj?.category ?? []).map(
          ([category, questions], catIndex) => (
            <Fragment key={category}>
              <Accordion sx={{ marginBottom: "20px" }}>
                <AccordionSummary
                  sx={{
                    flexDirection: "row-reverse",
                    borderBottom: "1px solid #ababab",
                  }}
                  expandIcon={<ExpandMoreIcon color="icon" />}
                >
                  <MDTypography variant="h5" p={1}>
                    {category}{" "}
                  </MDTypography>
                  <MDTypography
                    variant="h6"
                    p={1}
                    sx={{
                      marginLeft: "auto",
                      backgroundColor: "#eee",
                      color: darkMode && "#eee",
                      borderRadius: "10px",
                    }}
                  >
                    Score:{" "}
                    {questions.reduce((accumulator, currentValue) => {
                      const seletedOption = currentValue.options.find(
                        (option) => option.id === currentValue.answer
                      );
                      return accumulator + seletedOption?.score;
                    }, 0)}
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {questions.map((question) => {
                    const seletedOption = question.options.find(
                      (option) => option.id === question.answer
                    );
                    return (
                      <Fragment key={question.question_id}>
                        <MDTypography variant="h6">
                          {question.item}
                        </MDTypography>
                        <Chip
                          label={itemScores[seletedOption?.score]?.name}
                          variant="outlined"
                          sx={{
                            backgroundColor:
                              itemScores[seletedOption?.score]?.color,
                            color: itemScores[seletedOption?.score]?.text_color,
                          }}
                        />
                        <MDTypography variant="body2" fontWeight="regular">
                          {seletedOption?.option}
                        </MDTypography>
                        <MDTypography variant="body2">
                          {!!question.vendor && (
                            <p>Vendor: {question.vendor}</p>
                          )}{" "}
                          {!!question.tool && <p>Tool: {question.tool}</p>}
                        </MDTypography>
                        <Divider />
                      </Fragment>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            </Fragment>
          )
        )}
      </CardContent>

      {latestVersionScore && (
        <div
          style={{
            backgroundColor: bgCol,
            width: "auto",
            padding: "0.5rem",
            position: "absolute",
            borderRadius: "5px",
            top: "55px",
            left: "-45px",
            color: "white",
          }}
        >
          {objScore > latestVersionScore && "+"}{" "}
          {(objScore - latestVersionScore).toFixed(2)} %
        </div>
      )}
    </Card>
  ) : (
    <Card
      sx={{
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardContent style={darkMode ? { color: "white" } : {}}>
        Please <b>select</b> a completed version to compare
      </CardContent>
    </Card>
  );
};

const AssessmentEvents = (props) => {
  const { objUser } = props;
  const { assessment_template_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingVersion, setIsCreatingVersion] = useState(false);
  const [assessmentName, setAssessmentName] = useState("");
  const [openNewVersionDialog, setOpenNewVersionDialog] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [latestVersion, setLatestVersion] = useState(null);
  const [selectedVersionToCompareId, setSelectedVersionToCompareId] =
    useState("");
  const [selectedVersionToCompareObj, setSelectedVersionToCompareObj] =
    useState(null);
  const [versionsListToCompare, setVersionsListToCompare] = useState([]);
  const [itemScores, setItemScores] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const showNewVersionModal = () => {
    setOpenNewVersionDialog(true);
  };

  const sortQuestionsIntoCategories = (objTemplate, itemScores = null) => {
    objTemplate.category = {};
    objTemplate?.questions?.sort((a, b) => a.sequence - b.sequence);
    for (let index = 0; index < objTemplate?.questions?.length; index++) {
      const element = objTemplate.questions[index];
      element.options.sort((a, b) => a.sequence - b.sequence);

      //trimming down policy and procedure statements to just the ids
      element.policy_statements = element.policy_statements?.map((record) => {
        return record.policy;
      });

      element.procedure_statements = element.procedure_statements?.map(
        (record) => {
          return record.procedure;
        }
      );

      if (typeof objTemplate.category[element.category] === "undefined") {
        objTemplate.category[element.category] = [];
      }

      objTemplate.category[element.category].push(element);
    }

    return objTemplate;
  };

  useEffect(() => {
    loadMoreEvents();
  }, []);
  const loadMoreEvents = () => {
    setIsLoading(true);
    dispatch(getAssessmentEventVersions({ objUser, assessment_template_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;

        /**for table************ */
        const objRows = response.data.events_obj.events.map((objEvent) => {
          const objTr = {};
          objTr.version = objEvent.version ? objEvent.version + ".0" : "";
          objTr.name = objEvent.name;
          objTr.assessmentTemplateId = objEvent.assessment_template_id;
          objTr.status = objEvent.status;
          objTr.riskLevel = objEvent.risk_level?.name;
          objTr.createdBy = objEvent.created_by;
          objTr.createdFor = objEvent.client_name;
          objTr.startDate = new Date(objEvent.start_date).toLocaleDateString();
          objTr.completedOn = objEvent.end_date
            ? new Date(objEvent.end_date).toLocaleDateString()
            : "";
          objTr.id = objEvent.id;
          return objTr;
        });
        setAssessmentName(objRows[0]?.name ?? "");
        setTotalCount(response.data.events_obj.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
        /**end for table **************/
        /******************************************* */

        /*****For compare********** */

        const objAssessments = response.data.events_obj.events.map(
          (objEvent) => {
            return sortQuestionsIntoCategories(
              objEvent,
              response.data?.itemScores
            );
          }
        );

        const sortedLatestVersion = response.data.latest_version_obj
          ? sortQuestionsIntoCategories(
              response.data.latest_version_obj,
              response.data?.itemScores
            )
          : null;

        const scores = {};
        response.data?.itemScores?.forEach((itemScore) => {
          const color = tinycolor(itemScore.color);
          scores[itemScore.code] = {
            color: color.toString(),
            text_color: itemScore.text_color,
            name: itemScore.name,
            value: itemScore.value,
            possible_points: itemScore.possible_points,
          };
        });
        /********************** */

        setLatestVersion(sortedLatestVersion);

        setVersionsListToCompare(
          objAssessments.filter((obj) => {
            return (
              obj.status === "completed" &&
              obj.version !== sortedLatestVersion.version
            );
          })
        );
        setItemScores(scores);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const takeTheAssessment = (objEventData) => {
    navigate(
      `/assessments/events/${objEventData.assessmentTemplateId}/${objEventData.id}`,
      {
        state: { routeDictionary },
      }
    );
  };

  const viewAssessmentVersionsPage = (objEventData) => {
    routeDictionary[objEventData.id] = objEventData.name;
    navigate(`/assessments/events/${objEventData.assessmentTemplateId}`, {
      state: { routeDictionary },
    });
  };

  const handleSelectVersionToCompareChange = (event) => {
    const currentVersionsListToCompare = [...versionsListToCompare];

    const selectedVsn = currentVersionsListToCompare.find((obj) => {
      return obj.id == event.target.value;
    });

    setSelectedVersionToCompareObj(selectedVsn);
    setSelectedVersionToCompareId(event.target.value);
  };

  const onNewEvent = (id, name) => {
    setOpenNewVersionDialog(false);
    takeTheAssessment({ id, name });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <CreateNewEventDialog
        objUser={objUser}
        open={openNewVersionDialog}
        onClose={() => setOpenNewVersionDialog(false)}
        onSubmit={takeTheAssessment}
        templateId={assessment_template_id}
        assessmentName={assessmentName}
      />

      <MDBox>
        <MDBox
          display="flex"
          gap=".6rem"
          sx={{ justifyContent: "space-between" }}
          mb={0}
        >
          <MDTypography
            sx={{ paddingTop: "1rem" }}
            fontWeight="bold"
            variant="h4"
            color="dark"
          >
            {assessmentName} versions
          </MDTypography>
          {isCreatingVersion ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <DashboardActionButton
              action={showNewVersionModal}
              btnText="Create New Version"
              btnIcon={"add"}
              textColor="white"
              bgColor="success"
              className="dashboard-button"
            />
          )}
        </MDBox>
        <Card sx={{ marginTop: "16px", padding: "0.5rem" }}>
          <Accordion disabled={latestVersion === null}>
            <AccordionSummary
              sx={{
                flexDirection: "row-reverse",
                borderBottom: "1px solid #ababab",
                background: darkMode ? "#2f3958" : "#ededed",
              }}
              expandIcon={<ExpandMoreIcon color="icon" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <MDTypography variant="h6" p={1}>
                {latestVersion === null
                  ? "No recently completed version to compare"
                  : "Compare"}
              </MDTypography>
              <MDTypography
                sx={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
                p={1}
              >
                <CompareIcon fontSize="medium" />
              </MDTypography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: darkMode ? "#2f3958" : "#e5e9ec",
                paddingTop: "1rem",
              }}
            >
              <Grid spacing={2} container width="100%">
                {/* selectors */}
                {/* current version */}
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h6">
                    Current version (v{latestVersion?.version + ".0"})
                  </MDTypography>
                </Grid>

                {/* version selector */}
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel
                      id="Versionselector"
                      sx={{
                        fontSize: "1rem",
                        color: "black",
                        background: "#fff",
                      }}
                    >
                      Select version to compare
                    </InputLabel>
                    <Select
                      sx={{ height: "46px", background: "#fff" }}
                      labelId="Versionselector"
                      label="Versionselector"
                      id="Versionselector"
                      value={selectedVersionToCompareId}
                      onChange={handleSelectVersionToCompareChange}
                    >
                      {versionsListToCompare.map((version) => {
                        return version.id !== latestVersion?.id ? (
                          <MenuItem
                            key={version.id}
                            value={version.id}
                            sx={{ fontSize: "1rem", padding: "1rem" }}
                          >
                            v{version.version + ".0"} completed
                          </MenuItem>
                        ) : (
                          ""
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {/* ***** */}

                {/* comparison outputs */}
                <Grid item xs={12} sm={6}>
                  {/* latest version data points */}
                  <MDBox
                    sx={{
                      height: "auto",
                      width: "100%",
                    }}
                  >
                    <ComparisonCard
                      assessmentEventObj={latestVersion}
                      itemScores={itemScores}
                      latestVersionScore={null}
                    />
                  </MDBox>
                </Grid>

                {/* selected version data points */}
                <Grid item xs={12} sm={6}>
                  <MDBox
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ComparisonCard
                      assessmentEventObj={selectedVersionToCompareObj}
                      itemScores={itemScores}
                      latestVersionScore={totalScorePercentage(
                        latestVersion?.score,
                        latestVersion?.possible_score
                      )}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Card>

        <Card sx={{ marginTop: "16px" }}>
          {isLoading === false &&
          objTableData !== undefined &&
          objTableData.rows !== undefined &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <AssessmentEventsTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              takeTheAssessment={takeTheAssessment}
              viewAssessmentVersionsPage={viewAssessmentVersionsPage}
            />
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no versions for this Assessment</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentEvents;
