import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { COMPANIES_SERVER } from "components/utils/constants/misc";

export const addRiskRegisterItem = createAsyncThunk(
  "addToRiskRegister",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/${eventId}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const bulkAddToRiskRegister = createAsyncThunk(
  "bulkAddToRiskRegister",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, eventId } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/${eventId}/bulk`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskRegisterItems = createAsyncThunk(
  "riskRegisterItems/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createRiskRegisterList = createAsyncThunk(
  "createRiskRegisterList",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.post(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateRiskRegisterItemState = createAsyncThunk(
  "updateRiskRegisterItemState",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, itemId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/${itemId}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addRiskRegisterItemToMatrix = createAsyncThunk(
  "addRiskRegisterItemToMatrix",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, itemId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/risk-matrix/${itemId}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskRegisterList = createAsyncThunk(
  "riskRegisterList/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskRegisterListItems = createAsyncThunk(
  "getRiskRegisterListItems/get",
  async (values, thunkApi) => {
    try {
      const { objUser, listId } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list/${listId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPoams = createAsyncThunk(
  "poams/get",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/poams`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePoam = createAsyncThunk(
  "updatePoam",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData, poamId } = values;
      const res = await axios.patch(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/poams/${poamId}`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deletePoam = createAsyncThunk(
  "deletePoam/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, poamId } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/poams/${poamId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteRiskRegisterItem = createAsyncThunk(
  "deleteRiskRegisterItem/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, itemId } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/${itemId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteActionItem = createAsyncThunk(
  "deleteActionItem/delete",
  async (values, thunkApi) => {
    try {
      const { objUser, itemId } = values;

      const res = await axios.delete(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list/item/${itemId}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskFindingsPDF = createAsyncThunk(
  "riskfindings/get-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/findings-pdf`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskFindingsCSV = createAsyncThunk(
  "riskfindings/get-csv",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/assessments/risk-register/findings-csv`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskRegistersPDF = createAsyncThunk(
  "riskregister/get-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser, listId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list/register-pdf/${listId}`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getRiskRegistersCSV = createAsyncThunk(
  "riskregister/get-csv",
  async (values, thunkApi) => {
    try {
      const { objUser, listId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/risk-list/register-csv/${listId}`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPoamsPDF = createAsyncThunk(
  "poams/get-pdf",
  async (values, thunkApi) => {
    try {
      const { objUser, listId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/poams/pdf`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPoamsCSV = createAsyncThunk(
  "poams/get-csv",
  async (values, thunkApi) => {
    try {
      const { objUser, listId } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${COMPANIES_SERVER}/${objUser.user.company_id}/poams/csv`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
