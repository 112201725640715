import Grid from "@mui/material/Grid";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import CreatePolicyDocumentWizard from "./components/CreatePolicyDocumentWizard/CreatePolicyDocumentWizard";

const CreatePolicyDocument = (props) => {
  let { version } = useParams();

  return (
    <DashboardLayout>
      <DashboardNavbar title={`Version ${version}`} />
      {/* <Card> */}
      <Grid
        mb={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <CreatePolicyDocumentWizard objUser={props.objUser} />
        </Grid>
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default CreatePolicyDocument;
