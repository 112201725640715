import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useLocation } from "react-router-dom";
import "./settings.css";
import AzureImg from "assets/images/logos/azure.png";
import CyberCNSLogo from "assets/images/logos/cybercns.jpg";
import LionGuardLogo from "assets/images/logos/lionguard.png";
import NodewareLogo from "assets/images/logos/nodeware.jpg";
import NinjaLogo from "assets/images/logos/ninja_logo.jpg";
import NCentralLogo from "assets/images/logos/ncentral.jpg";
import DashboardButton from "views/components/DashboardButtton";

const AssetConnections = (props) => {
  const { objUser } = props;
  const isClientUser = objUser.user.role.name === "client_user" ? true : false;
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDTypography fontWeight="bold" mt={5} variant="h5">
        Asset Management
      </MDTypography>

      <MDBox>
        <Grid spacing={2} container width="100%">
          {!isClientUser ? (
            <>
              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={CyberCNSLogo} alt="CyberCNS" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        ConnectSecure v3
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        ConnectSecure v3 to be deprecated in the Fall of 2024
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          // to={`/asset-management/connectsecure/clients`}
                          to={`/assets/connections/connectsecure`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={CyberCNSLogo} alt="CyberCNS" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        ConnectSecure v4
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from ConnectSecure
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/connectsecure/v4`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={NodewareLogo} alt="Nodeware" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Nodeware API
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from Nodeware
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/nodeware`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={NinjaLogo} alt="Nodeware" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Ninja API
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from NinjaOne
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/ninja`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={5}>
                        <img src={LionGuardLogo} alt="LionGuard" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Liongard
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from Liongard
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/liongard`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={AzureImg} alt="Azure" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold">
                        Graph API Assets
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from M365
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/msgraph-assets/clients-to-configure`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Card sx={{ marginTop: "16px" }}>
                  <MDBox p={2}>
                    <Grid>
                      <Grid item xs={12} lg={2}>
                        <img src={NCentralLogo} alt="N-Central Logo" />
                      </Grid>
                      <MDTypography variant="h5" fontWeight="bold" mt={2}>
                        N-Able/N-Central
                      </MDTypography>
                      <MDTypography variant="p" fontWeight="regular">
                        Sync your assets from N-able
                      </MDTypography>
                      <MDTypography variant="h5" fontWeight="bold">
                        <DashboardButton
                          component={Link}
                          to={`/assets/connections/ncentral`}
                          state={{ routeDictionary }}
                          btnText="Config"
                          btnIcon="settings"
                          textColor="white"
                          bgColor="success"
                        />
                      </MDTypography>
                    </Grid>
                  </MDBox>
                </Card>
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssetConnections;
