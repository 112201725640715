import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import { showMsg } from "utils/general";

import { useDispatch } from "react-redux";
import {
  getKBWithIdAndSecret,
  getPolicyDocumentKB,
  applySortingFilter,
} from "features/company/knowledgeBaseActions";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import {
  Link,
  useFetcher,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Icon from "@mui/material/Icon";
import { useCookies } from "react-cookie";

// import testLogo from "assets/images/test-logo.jpg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  Pagination,
  Stack,
  TextField,
} from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import brandDark from "assets/images/icons/logo.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PaginationComponent from "./components/PaginationComponent";
import "./components/OpenedDocument.css";

const PolicyItem = (props) => {
  // const dispatch = useDispatch();
  const { policy } = props;
  // const [open, setOpen] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  let { company_id, client_id, kbs_id, kbs_secret } = useParams();
  const tags = policy.policy_tags?.split(",");
  return (
    <>
      <Grid item xs={3} lg={3} mt={5}>
        <Card
          sx={{
            padding: "0.7rem",
          }}
        >
          <MDBox sx={{ width: "100%" }}>
            <MDTypography
              variant="h5"
              style={{ textAlign: "center" }}
              p={1}
              color="dark"
            >
              {policy.title}
            </MDTypography>
            <MDTypography
              variant="p"
              style={{ display: "block", fontSize: "1rem" }}
              p={1}
              color="dark"
            >
              {policy.description}
            </MDTypography>
            <Link
              to={`/kbs/open-document/${company_id}/${client_id}/${kbs_id}/${kbs_secret}/${policy.policy_id}/${policy.policy_document_id}`}
            >
              <MDButton
                sx={{ padding: "0.7rem 1.75rem", width: "100%" }}
                // variant="gradient"
                color={"success"}
              >
                <MDTypography
                  fontWeight="bold"
                  color={"white"}
                  variant="button"
                >
                  Open Document
                </MDTypography>
              </MDButton>
            </Link>

            <MDTypography
              variant="p"
              style={{
                fontSize: "1rem",
                display: "inline-block",
                backgroundColor: "#ADD8E6",
                width: "auto",
                borderRadius: "6px",
                padding: "0.2rem",
                color: "black",
                marginTop: "1rem",
              }}
              p={1}
            >
              {policy.category_display_name}
            </MDTypography>
            <MDTypography
              variant="p"
              style={{ display: "block", padding: "0" }}
              p={1}
              color="dark"
            >
              <ul className="tags" style={{ padding: 0, marginTop: "1rem" }}>
                {tags
                  ? tags.map((tag, i) => {
                      return (
                        <li key={i} className="tag tag-display">
                          {tag}
                        </li>
                      );
                    })
                  : ""}
              </ul>
            </MDTypography>
            {policy?.rmfs && policy?.rmfs?.length == 0 ? (
              ""
            ) : (
              <MDTypography
                variant="p"
                style={{
                  backgroundColor: "#eee",
                  display: "block",
                  borderRadius: "6px",
                  fontSize: "1rem",
                }}
                p={1}
                mt={1}
                color="dark"
              >
                {policy?.rmfs?.join(", ")}
              </MDTypography>
            )}

            {/* Related docs (policies) */}
            {policy?.related_docs?.length > 0 ? (
              <Accordion sx={{ marginTop: "1.5rem" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="icon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <MDTypography variant="p" color="dark">
                    <b> Related Documents</b>
                  </MDTypography>
                </AccordionSummary>
                <AccordionDetails>
                  {policy.related_docs.map((doc) => {
                    return (
                      <Link
                        to={`/kbs/open-document/${company_id}/${client_id}/${kbs_id}/${kbs_secret}/${doc.linked_policy_id}/${doc.policy_document_id}`}
                      >
                        <MDTypography
                          variant="p"
                          style={{ color: "#1f68e4", display: "block" }}
                        >
                          <BookmarkIcon sx={{ paddingTop: "0.2rem" }} />{" "}
                          {doc.linked_policy.title}
                        </MDTypography>
                      </Link>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ) : (
              ""
            )}
          </MDBox>
        </Card>
      </Grid>
    </>
  );
};

const KnowledgeBaseShare = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [allowedDomain, setAllowedDomain] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false); //if the user applies filter, we use a different pagination component with different params
  const [policies, setPolicies] = useState([]);
  const [client, setClient] = useState(null);
  const [logo, setLogo] = useState(brandDark);
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [cookies, setCookie] = useCookies(["isValidated"]);

  /**pagination without filter */
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  /**pagination with filter */
  const [currentFilterPage, setCurrentFilterPage] = useState(1);
  const [totalFilterPages, setTotalFilterPages] = useState(0);

  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState([]);

  const [rmfs, setRmfs] = useState([]);
  const [rmfName, setRmfName] = useState([]);

  const [tags, setTags] = useState([]);
  const [tagName, setTagName] = useState([]);

  const [title, setTitle] = useState("");

  let { company_id, client_id, kbs_id, kbs_secret } = useParams();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getKBWithIdAndSecret({
        company_id,
        client_id,
        kbs_id,
        kbs_secret,
        currentPage,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        if (
          originalPromiseResult.data.isPasswordProtected &&
          cookies.isValidated != client_id
        ) {
          navigate(`/validate-password/${company_id}/${client_id}`, {
            state: { routeDictionary },
          });
        }
        setPolicies(originalPromiseResult.data.policies.data);
        setClient(originalPromiseResult.data.client);
        setLogo(originalPromiseResult.data.logo);
        setCategories(originalPromiseResult.data.categories);
        setRmfs(originalPromiseResult.data.rmfs);
        setTags(originalPromiseResult.data.tags);
        setTotalPages(originalPromiseResult.data.policies.last_page);

        // console.log(originalPromiseResult.data.domains);
        var referrerUrl = document.referrer;
        // if (document.referrer.indexOf("otherdomain.com") != -1) {
        //   window.location = "yourdomain.com/error.html";
        // }
        // if (referrerUrl) {
        //   try {
        //     // Parse the domain from the referrer URL

        //     console.log("Domain embedding the iframe:", referrerDomain);
        //   } catch (error) {
        //     console.error("Error parsing referrer URL:", error.message);
        //   }
        // } else {
        //   console.warn("Referrer URL is empty.");
        // }
        if (window.parent != window) {
          let referrerDomain = new URL(referrerUrl).hostname;
          let allowedDomains = originalPromiseResult.data.domains;

          var parts = referrerDomain.split(".");

          // If there are more than two parts (e.g., sub.example.com), return the last two parts
          if (parts.length > 2) {
            referrerDomain = parts.slice(-2).join(".");
          }

          if (allowedDomains.some((item) => item.includes(referrerDomain))) {
            // console.log("allowed");
          } else {
            setAllowedDomain(false);
          }
          // Get the referrer URL
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setPolicies([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentPage]);

  //pagination without filter
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  //pagination with filter
  const handleFilterPageChange = (event, value) => {
    setCurrentFilterPage(value);
    applyFilter(value);
  };

  //handleCategory filter change
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setCategoryName(typeof value === "string" ? value.split(",") : value);
  };

  //handle rmf change
  const handleRmfChange = (event) => {
    const {
      target: { value },
    } = event;
    setRmfName(typeof value === "string" ? value.split(",") : value);
  };

  //handle tags change
  // const handleTagChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;

  //   setTagName(typeof value === "string" ? value.split(",") : value);
  // };
  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setTagName(typeof value === "string" ? value.split(",") : value);
  };

  const applyFilter = (value) => {
    const filterObject = {
      categories: categoryName,
      tags: tagName,
      rmfs: rmfName,
      title: title,
      sharedLinkId: kbs_secret,
    };
    setIsFiltering(true);
    setIsFilterLoading(true);

    dispatch(
      applySortingFilter({
        company_id,
        client_id,
        kbs_id,
        kbs_secret,
        filterObject,
        currentFilterPage: value == "resetToPageOne" ? 1 : value,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setPolicies(originalPromiseResult.data.policies.data);
        setTotalFilterPages(originalPromiseResult.data.policies.last_page);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setPolicies([]);
      })
      .finally(() => {
        setIsFilterLoading(false);
      });
  };

  return (
    <MDBox pt={2} mb={5}>
      {allowedDomain ? (
        <>
          <Container>
            <Grid>
              {/* logo and title */}
              <Grid item xs={12} lg={9}>
                <Card
                  display="flex"
                  sx={{
                    width: "100%",
                    padding: "0.7rem",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "3rem",
                    background: "transparent",
                    boxShadow: "none",
                    border: "none",
                  }}
                >
                  <MDBox sx={{ width: "20%" }}>
                    <img
                      src={logo}
                      style={{ maxWidth: "100%", height: "auto" }}
                      alt=""
                    />
                  </MDBox>
                  <MDTypography
                    variant="h2"
                    color="white"
                    sx={{
                      backgroundColor: "#4CAF50",
                      // color: "#4CAF50",
                      width: "80%",
                      textAlign: "center",
                      borderRadius: "12px",
                    }}
                    p={1}
                  >
                    {client ? client + "'s " : ""}Knowledge Base
                  </MDTypography>
                </Card>
              </Grid>

              {/* filters */}
              <Grid item xs={12} lg={9} mt={5}>
                <MDTypography variant="h5" color="dark" p={1}>
                  Sort, filter and search
                </MDTypography>
                <Card
                  display="flex"
                  sx={{
                    width: "100%",
                    padding: "0.7rem",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "3rem",
                  }}
                >
                  <MDBox sx={{ width: "20%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="Category">Category</InputLabel>
                      <Select
                        labelId="Category"
                        label="Category"
                        id="Category"
                        multiple
                        value={categoryName}
                        sx={{ height: "40px" }}
                        onChange={handleCategoryChange}
                      >
                        {categories.map((category) => {
                          return (
                            <MenuItem key={category.id} value={category}>
                              {category.display_name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox sx={{ width: "20%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="Tags">Tags</InputLabel>
                      <Select
                        labelId="Tags"
                        label="Tags"
                        id="Tags"
                        multiple
                        value={tagName}
                        sx={{ height: "40px" }}
                        onChange={handleTagChange}
                      >
                        {tags.map((tag, i) => {
                          return (
                            <MenuItem key={i} value={tag.name}>
                              {tag.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox sx={{ width: "20%" }}>
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="Risk Framework">Policy Pack</InputLabel>
                      <Select
                        labelId="Risk Framework"
                        label="Risk Framework"
                        id="Risk Framework"
                        multiple
                        value={rmfName}
                        sx={{ height: "40px" }}
                        onChange={handleRmfChange}
                      >
                        {rmfs.map((rmf) => {
                          return (
                            <MenuItem key={rmf.id} value={rmf}>
                              {rmf.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </MDBox>
                  <MDBox sx={{ width: "25%" }}>
                    <TextField
                      id="outlined-basic"
                      label="Search Title"
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </MDBox>
                  <MDBox sx={{ width: "15%" }}>
                    {isFilterLoading ? (
                      <LoadingSpinner />
                    ) : (
                      <MDButton
                        sx={{ padding: "0.7rem 1.75rem", width: "100%" }}
                        // variant="gradient"
                        color={"success"}
                        onClick={() => applyFilter("resetToPageOne")}
                      >
                        <Icon style={{ transform: "scale(1.3)" }}>
                          <FilterAlt />
                        </Icon>
                        <MDTypography
                          fontWeight="bold"
                          sx={{ marginLeft: "0.75rem" }}
                          color={"white"}
                          variant="button"
                        >
                          Filter
                        </MDTypography>
                      </MDButton>
                    )}
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </Container>

          {/* list of documents grid */}
          <Container>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12} lg={12} mt={5}>
                  <Card
                    sx={{
                      padding: "0.7rem",
                    }}
                  >
                    <MDBox sx={{ width: "100%" }}>
                      <MDTypography style={{ textAlign: "center" }} p={1}>
                        <LoadingSpinner />
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
              ) : (
                <>
                  {policies.length == 0 ? (
                    <Grid item xs={12} lg={12} mt={5}>
                      <Card
                        sx={{
                          padding: "0.7rem",
                        }}
                      >
                        <MDBox sx={{ width: "100%" }}>
                          <MDTypography style={{ textAlign: "center" }} p={1}>
                            No knowledgebase found
                          </MDTypography>
                        </MDBox>
                      </Card>
                    </Grid>
                  ) : (
                    policies.map((policy) => {
                      return (
                        <PolicyItem
                          policy={policy}
                          key={policy.policy_document_id}
                        />
                      );
                    })
                  )}
                </>
              )}

              {/* <Grid item xs={3} lg={3} mt={5}>
            <Card
              sx={{
                padding: "0.7rem",
              }}
            >
              <MDBox sx={{ width: "100%" }}>
                <MDTypography
                  variant="h5"
                  style={{ color: "#0b2e4a", textAlign: "center" }}
                  p={1}
                >
                  Document Title
                </MDTypography>
                <MDTypography
                  variant="p"
                  style={{ color: "#0b2e4a", display: "block" }}
                  p={1}
                >
                  Short description
                </MDTypography>
                <MDButton
                  sx={{ padding: "0.7rem 1.75rem", width: "100%" }}
                  variant="gradient"
                  color={"success"}
                >
                  <MDTypography
                    fontWeight="bold"
                    color={"white"}
                    variant="button"
                  >
                    Open Document
                  </MDTypography>
                </MDButton>
                <MDTypography
                  variant="p"
                  style={{ color: "#0b2e4a", display: "block" }}
                  p={1}
                >
                  Tags
                </MDTypography>
                <MDTypography
                  variant="p"
                  style={{ color: "#0b2e4a", display: "block" }}
                  p={1}
                >
                  Category
                </MDTypography>
                <MDTypography
                  variant="p"
                  style={{ color: "#0b2e4a", display: "block" }}
                  p={1}
                >
                  RMFs
                </MDTypography>
              </MDBox>
            </Card>
          </Grid> */}
            </Grid>
          </Container>

          <Container>
            <Grid container spacing="2">
              <Grid item xs={12} lg={12} mt={5}>
                <MDBox
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <MDTypography style={{ textAlign: "center" }} p={1}>
                    <Stack spacing={2}>
                      <Card>
                        {isFiltering ? (
                          <PaginationComponent
                            totalPages={totalFilterPages}
                            currentPage={currentFilterPage}
                            onPageChange={handleFilterPageChange}
                          />
                        ) : (
                          <PaginationComponent
                            totalPages={totalPages}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                          />
                        )}
                      </Card>
                    </Stack>
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Container>
        </>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <Card>
            <MDTypography variant="h5" color="dark" p={1}>
              Embedding Not Allowed
            </MDTypography>
          </Card>
        </MDBox>
      )}
    </MDBox>
  );
};

export default KnowledgeBaseShare;
