import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import AssessmentCharts from "views/components/GameCharts/AssessmentCharts";
import AssetTable from "views/components/GameCharts/AssetTable";
import PolicyCharts from "views/components/GameCharts/PolicyCharts";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import "./dashboard-styles.css";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import DashboardButton from "views/components/DashboardButtton";
import InsuranceCharts from "views/components/GameCharts/InsuranceCharts";
function PolygonDashboard({ objUser }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [cookies, setCookie] = useCookies(["hasSeenHolidayMsg"]);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const onClose = () => {
    setCookie("hasSeenHolidayMsg", "yes", {
      path: "/",
    });
    setOpen(false);
  };

  useEffect(() => {
    if (objUser.user.role.name === "peer_group_admin") {
      navigate(`/peer-group`);
    }
    if (objUser.user.role.name === "auditor") {
      navigate(`/auditor-reports`);
    }
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar objUser={objUser} />

      <Grid container>
        <Grid item xs={6}>
          <MDTypography
            sx={{
              marginBottom: "0.5rem",
            }}
            fontWeight="bold"
            variant="h4"
            color="dark"
          >
            Welcome to Compliance Scorecard!
          </MDTypography>
        </Grid>
        {!objUser.user?.company?.has_peer_group_subscription ||
          typeof objUser.user?.company?.has_peer_group_subscription ===
            "undefined" ||
          (objUser.user?.company?.has_peer_group_subscription === false && (
            <Grid item xs={6}>
              <MDBox
                textAlign="right"
                sx={{ marginTop: "-20px", marginBottom: "20px" }}
              >
                <DashboardButton
                  component={Link}
                  to={`/billing`}
                  btnIcon="add"
                  btnText="Signup for Peer Group"
                  textColor="white"
                  bgColor="dark"
                />
              </MDBox>
            </Grid>
          ))}
      </Grid>
      <Grid spacing={2} container>
        {/* gamification */}
        <Grid item xs={12} lg={12}>
          <Grid spacing={2} container>
            {/* Assessment Scorecard  */}
            <Grid item xs={12}>
              <AssessmentCharts objUser={objUser} />
            </Grid>

            {/* Asset Management Scorecard */}
            <Grid item xs={12}>
              <AssetTable objUser={objUser} />
            </Grid>

            {/* Policy Scorecard */}
            <Grid item xs={12}>
              <PolicyCharts objUser={objUser} darkMode={darkMode} />
            </Grid>

            {/* Insurance Scorecard  */}
            <Grid item xs={12}>
              <InsuranceCharts objUser={objUser} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default PolygonDashboard;
