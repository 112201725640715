import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import { CLIENTS_SERVER } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const storeAddCustomerClient = createAsyncThunk(
  "client/storeAddCustomerClient",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.post(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listCustomerClients = createAsyncThunk(
  "client/listCustomerClients",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${CLIENTS_SERVER}/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearCustomerClients = createAsyncThunk(
  "client/clearCustomerClients",
  async (values, thunkApi) => {
    try {
      const res = {
        data: {
          status: "success",
          data: {
            current_page: 1,
            data: [],
            first_page_url: "",
            from: null,
            last_page: 1,
            last_page_url: "",
            next_page_url: null,
            path: "",
            per_page: 10,
            prev_page_url: null,
            to: null,
            total: 0,
          },
          isAuth: true,
          error: false,
        },
        status: 200,
      };
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const listConnectWiseAllClients = createAsyncThunk(
  "client/listConnectWiseAllClients",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.get(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const clearConnectWiseAllClients = createAsyncThunk(
  "client/clearConnectWiseAllClients",
  async (values, thunkApi) => {
    try {
      const res = {
        data: {
          status: "success",
          data: {
            current_page: 1,
            data: [],
            first_page_url: "",
            from: null,
            last_page: 1,
            last_page_url: "",
            next_page_url: null,
            path: "",
            per_page: 10,
            prev_page_url: null,
            to: null,
            total: 0,
          },
          isAuth: true,
          error: false,
        },
        status: 200,
      };
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const activateConnectWiseClient = createAsyncThunk(
  "client/activateConnectWiseClient",
  async (values, thunkApi) => {
    try {
      const { id, objUser } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.post(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/${id}/activate`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateConnectWiseClient = createAsyncThunk(
  "client/updateConnectWiseClient",
  async (values, thunkApi) => {
    try {
      return values;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteConnectWiseClientfromStore = createAsyncThunk(
  "client/deleteConnectWiseClientfromStore",
  async (values, thunkApi) => {
    try {
      return values;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deActivateConnectWiseClient = createAsyncThunk(
  "client/deActivateConnectWiseClient",
  async (values, thunkApi) => {
    try {
      const { id, objUser } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.post(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/${id}/deactivate`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteConnectWiseClient = createAsyncThunk(
  "client/deleteConnectWiseClient",
  async (values, thunkApi) => {
    try {
      const res = await axios.delete(
        `${BACKEND_API_URL}${CLIENTS_SERVER}/client/delete/${values.id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateConnectWiseClientById = createAsyncThunk(
  "client/updateConnectWiseClientById",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.patch(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/${objPostData.objPostData.id}`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateClientFeatureAccess = createAsyncThunk(
  "client/updateClientFeatureAccess",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.patch(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/${objPostData.objPostData.id}/feature-access`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchConnectWiseClientById = createAsyncThunk(
  "company/fetchConnectWiseClientById",
  async (values, thunkApi) => {
    try {
      const { id, objUser } = values;
      const THIS_CLIENTS_SERVER = CLIENTS_SERVER.replace(
        "{companyId}",
        objUser.user.company_id
      );
      const res = await axios.get(
        `${BACKEND_API_URL}${THIS_CLIENTS_SERVER}/${id}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getNewVersionInfo = createAsyncThunk(
  "getNewVersionInfo",
  async (values, thunkApi) => {
    try {
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/new-version-info`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
