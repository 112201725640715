import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  BACKEND_API_URL,
  VERSION_SERVER,
} from "components/utils/constants/misc";

export const getSections = createAsyncThunk(
  "company/getBlockedDomains",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/policy-section-settings`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const storeSection = createAsyncThunk(
  "company/addBD",
  async (values, thunkApi) => {
    try {
      const { objPostData } = values;

      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/policy-section-settings`,
        objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveSectionChanges = createAsyncThunk(
  "company/saveBDChanges",
  async (values, thunkApi) => {
    try {
      const { sections } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/super-admin/policy-section-settings`,
        { sections }
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
