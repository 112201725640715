import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
import {
  COMPANIES_SERVER,
  VERSION_SERVER,
  CLIENTS_SERVER,
  BASE_ENDPOINT,
} from "components/utils/constants/misc";

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const saveComplianceTogglesChanges = createAsyncThunk(
  "company/saveComplianceTogglesChanges",
  async (values, thunkApi) => {
    try {
      const { currentToggles } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/compliance-toggles`,
        { currentToggles }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveDocumentsTogglesChanges = createAsyncThunk(
  "company/saveDocumentsTogglesChanges",
  async (values, thunkApi) => {
    try {
      const { currentToggles } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/document-toggles`,
        { currentToggles }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveOverviewChanges = createAsyncThunk(
  "company/saveOverviewChanges",
  async (values, thunkApi) => {
    try {
      const { overview } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/overview`,
        { overview }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const savePointOfContactChanges = createAsyncThunk(
  "company/savePointOfContactChanges",
  async (values, thunkApi) => {
    try {
      const { contactsToAdd } = values;

      const res = await axios.patch(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/points-of-contact`,
        { contactsToAdd }
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPublicData = createAsyncThunk(
  "get/getPublicData",
  async (values, thunkApi) => {
    try {
      const { clientId } = values;

      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/trust-center/${clientId}`
      );

      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
