import { toast } from "react-toastify";
export function showMsg(type, msg, autoClose = 8000) {
  if (type === "success") {
    toast.success(msg, { autoClose });
  } else if (type === "error") {
    toast.error(msg, { autoClose });
  } else if (type === "info") {
    toast.info(msg, { autoClose });
  }
}

export function standardDateFormat(date, config_ = "no_timestamp") {
  if (config_ == "no_timestamp") {
    let formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const [month, day, year] = formattedDate.split("/");

    return `${year}-${month}-${day}`;
  } else {
    let formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const [month, day, year_timestamp] = formattedDate.split("/");
    const [year, timestamp] = year_timestamp.split(",");

    return `${year}-${month}-${day}, ${timestamp}`;
  }
}

export function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }

  return randomString;
}
