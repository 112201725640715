import { useEffect, useState } from "react";
import Card from "@mui/material/Card";


// Data
import { unwrapResult } from "@reduxjs/toolkit";
import {
  clearCompanyUsersList,
  companyUsersList,
  updateUserStatus,
} from "features/company/companiesActions";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showMsg } from "utils/general";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import dataTableData from "../../components/UsersManagementListCard/UsersManagementListCardDataTableData";
import UsersManagementListCardTable from "../../components/UsersManagementListCard/UsersManagementListCardTable";
const UsersManagementListCard = (props) => {
  const { objUser } = props;
  const [isLoadingMoreCompanyUsers, setIsLoadingMoreCompanyUsers] =
    useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [objSelectedUser, setObjSelectedUser] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    loadMoreCompanyUsers(perPage, page);

    return () => {
      dispatch(clearCompanyUsersList());
    };
  }, []);

  /**
   * Load more company users
   * @param {*} _per_page
   * @param {*} _page
   */
  const loadMoreCompanyUsers = (_per_page, _page) => {
    setIsLoadingMoreCompanyUsers(true);
    dispatch(companyUsersList({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreCompanyUsers(false);
        const response = originalPromiseResult;
        const objRows = [];

        for (let index = 0; index < response.data.users.length; index++) {
          const element = response.data.users[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.user = element.name;
          objTr.lastName = element?.last_name;
          objTr.role = element.role;
          objTr.status = element.status;
          objTr.element = element;
          objTr.action = "";
          objRows.push(objTr);
        }

        const inviteTypes = {
          client_user: "Client User",
          company_user: "Company User",
        };

        for (let index = 0; index < response.data.invites.length; index++) {
          const element = response.data.invites[index];
          const objTr = {};
          objTr.id = element.id;
          objTr.user = element.first_name;
          objTr.lastName = element.last_name;
          objTr.role = inviteTypes[element.type];
          objTr.status = "Invited";
          objTr.element = element;
          objTr.action = "";
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreCompanyUsers(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          console.log("Unknown error", objErrorRsp);
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  /**
   * Something else
   */
  const { objCompanyUsersListData } = useSelector((state) => ({
    objCompanyUsersListData: state.company.companies.objCompanyUsersListData,
  }));

  /**
   * Delete user
   * @param {*} objUserData
   * @returns
   */
  const changeUserStatus = async (objUserData, newStatus) => {
    setIsDeletingUser(true);
    setObjSelectedUser(objUserData);

    if (objUserData.element.id == objUser.user.id) {
      showMsg("error", "You cannot change the status of your own account.");
      setIsDeletingUser(false);
      setObjSelectedUser({});
      return false;
    }

    const objPostData = {
      id: objUserData.element.id,
      newStatus: newStatus,
    };
    dispatch(updateUserStatus({ objPostData, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingUser(false);
        setObjSelectedUser({});
        const response = originalPromiseResult;
        if (response.status == 200) {
          console.log(originalPromiseResult);
          showMsg(
            "success",
            "You have successfully updated the user's status."
          );
          window.location.reload();
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingUser(false);
        setObjSelectedUser({});
        showMsg("error", "Something went wrong, please try again.");
      });
  };

  /**
   * Returned view component
   */
  return (
    <Card>
      {isLoadingMoreCompanyUsers === false &&
      objTableData.rows.length > 0 &&
      totalCount > 0 ? (
        <UsersManagementListCardTable
          table={objTableData}
          entriesPerPage={true}
          canSearch={true}
          totalCount={totalCount}
          loadMoreCompanyUsers={loadMoreCompanyUsers}
          perPage={perPage}
          setPerPage={setPerPage}
          _page={page}
          setPage={setPage}
          changeUserStatus={changeUserStatus}
          isDeletingUser={isDeletingUser}
          setIsDeletingUser={setIsDeletingUser}
          objSelectedUser={objSelectedUser}
          setObjSelectedUser={setObjSelectedUser}
          objUser={objUser}
        />
      ) : (
        <Fragment>
          {isLoadingMoreCompanyUsers ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <p className="empty-text-p">You don't have any users yet!</p>
          )}
        </Fragment>
      )}
    </Card>
  );
};

export default UsersManagementListCard;
