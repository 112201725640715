import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";
import { Checkbox } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { bulkAddToRiskRegisterRole } from "features/company/clientAudienceActions";

function AddToRiskRegisterUsersDialog({
  objUser,
  open,
  onClose,
  onSubmit,
  unAllowedUsers,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const dispatch = useDispatch();

  const toggleState = (id) => {
    if (usersToAdd.includes(id)) {
      setUsersToAdd(usersToAdd.filter((userId) => userId !== id));
    } else {
      setUsersToAdd([...usersToAdd, id]);
    }
  };
  const proceedToCloneTemplate = () => {
    // const objPostData = {
    //   usersToAdd,
    // };
    setIsLoading(true);
    dispatch(bulkAddToRiskRegisterRole({ objUser, usersToAdd }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);
        setUsersToAdd([]);
        showMsg("success", "User added successfully");
        onSubmit();
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        showMsg("error", "Something went wrong, please try again.");
        console.log(rejectedValueOrSerializedError);
      });
  };

  useEffect(() => {}, []);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle sx={{ pb: 0 }}>Add To Risk Register Users</DialogTitle>
      <DialogContent>
        {unAllowedUsers.map((user) => (
          <>
            <Checkbox
              checked={usersToAdd.includes(user.id)}
              onChange={(event) => {
                toggleState(user.id);
              }}
            />

            <MDTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              onClick={(event) => {
                toggleState(user.id);
              }}
            >
              &nbsp;&nbsp;{user.name} {user.last_name}&nbsp;
            </MDTypography>
            <br />
          </>
        ))}
        {unAllowedUsers.length == 0 && (
          <MDTypography mt={2}>All users added</MDTypography>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading === false ? (
          <MDButton
            onClick={proceedToCloneTemplate}
            // variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
            disabled={unAllowedUsers.length == 0}
          >
            Proceed
          </MDButton>
        ) : (
          <LoadingSpinner subClass="text-center" color="success" size="lg" />
        )}
        <MDButton
          type="button"
          variant="outlined"
          color="dark"
          sx={{ padding: "12px 12px" }}
          onClick={onClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default AddToRiskRegisterUsersDialog;
