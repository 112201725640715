import { useState, useEffect } from "react";

// react-router-dom components
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Authentication layout components
import IllustrationLayout from "views/containers/IllustrationLayout";

// Images
import bgImage from "assets/images/signBg.jpg";
import polygonLogo from "assets/images/icons/ct-logo-portrait.svg";
import AcceptUserCompanyInviteForm from "./components/AcceptUserCompanyInviteForm";
import { validateUserInvitationToCompany } from "features/company/companiesActions";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";

const AcceptUserCompanyInvite = (props) => {
  let { invite_token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVerifyingToken, setIsVerifyingToken] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [objValidateTokenErrors, setObjValidateTokenErrors] = useState({});

  useEffect(() => {
    const objPostData = { token: invite_token };
    setObjValidateTokenErrors({});
    setIsVerifyingToken(true);
    dispatch(validateUserInvitationToCompany(objPostData))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsVerifyingToken(false);
        // handle result here
        const response = originalPromiseResult;

        if (response.status === 201) {
          setIsSuccess(true);
        } else {
          setIsError(true);
          showMsg("error", "Invalid Url.");
          navigate("/sign-in");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsVerifyingToken(false);
        setIsError(true);
        showMsg("error", "Invalid Url.");
        navigate("/sign-in");
      });
  }, []);
  return (
    <IllustrationLayout illustration={bgImage} brand={polygonLogo}>
      {isVerifyingToken === false ? (
        <Card>
          <MDBox
            bgColor="#1a74bb"
            borderRadius="lg"
            mx={2}
            mt={-3}
            p={3}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              Accept Company Invitation
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Enter your first name, last name and password to accept the
              invitation
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <AcceptUserCompanyInviteForm invite_token={invite_token} />
          </MDBox>
        </Card>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </IllustrationLayout>
  );
};

export default AcceptUserCompanyInvite;
