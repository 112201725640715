import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useMediaQuery from "@mui/material/useMediaQuery";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import "./CreatePolicyDocumentWizard.css";
import CreatePolicyDocumentStepDraft from "../CreatePolicyDocumentStepDraft/CreatePolicyDocumentStepDraft";
import CreatePolicyDocumentStepReview from "../CreatePolicyDocumentStepReview/CreatePolicyDocumentStepReview";
import CreatePolicyDocumentStepAwaitingAuthorizations from "../CreatePolicyDocumentStepAwaitingAuthorizations/CreatePolicyDocumentStepAwaitingAuthorizations";
import CreatePolicyDocumentStepAuthorized from "../CreatePolicyDocumentStepAuthorized/CreatePolicyDocumentStepAuthorized";
import CreatePolicyDocumentStepAdoption from "../CreatePolicyDocumentStepAdoption/CreatePolicyDocumentStepAdoption";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchPolicyDocument } from "features/company/policiesActions";
import { useDispatch } from "react-redux";
import { showMsg } from "utils/general";

import usePreviewer from "../../usePreviewer";
import PreviewDocDialog from "../../PreviewDocDialog";

import usePolicyPDFDownloader from "../../../CreatePolicyDocument/usePolicyPDFDownloader";

function getSteps() {
  return [
    "DRAFT",
    "REVIEW",
    "AWAITING AUTHORIZATIONS",
    "AUTHORIZED",
    "ADOPTION",
  ];
}

function getStepContent(
  stepIndex,
  setActiveStep,
  objUser,
  objPolicyDocument,
  loadPolicyDocument,
  setNextDisabled
) {
  switch (stepIndex) {
    case 0:
      return (
        <CreatePolicyDocumentStepDraft
          loadPolicyDocument={loadPolicyDocument}
          objUser={objUser}
          objPolicyDocument={objPolicyDocument}
          setActiveStep={setActiveStep}
          setNextDisabled={setNextDisabled}
        />
      );
    case 1:
      return (
        <CreatePolicyDocumentStepReview
          loadPolicyDocument={loadPolicyDocument}
          objUser={objUser}
          objPolicyDocument={objPolicyDocument}
          setActiveStep={setActiveStep}
          setNextDisabled={setNextDisabled}
        />
      );
    case 2:
      return (
        <CreatePolicyDocumentStepAwaitingAuthorizations
          loadPolicyDocument={loadPolicyDocument}
          objUser={objUser}
          objPolicyDocument={objPolicyDocument}
          setActiveStep={setActiveStep}
          setNextDisabled={setNextDisabled}
        />
      );
    case 3:
      return (
        <CreatePolicyDocumentStepAuthorized
          loadPolicyDocument={loadPolicyDocument}
          objUser={objUser}
          objPolicyDocument={objPolicyDocument}
          setActiveStep={setActiveStep}
          setNextDisabled={setNextDisabled}
        />
      );
    case 4:
      return (
        <CreatePolicyDocumentStepAdoption
          loadPolicyDocument={loadPolicyDocument}
          objUser={objUser}
          objPolicyDocument={objPolicyDocument}
          setActiveStep={setActiveStep}
          setNextDisabled={setNextDisabled}
        />
      );
    default:
      return null;
  }
}

function CreatePolicyDocumentWizard(props) {
  const { objUser } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { clientId, policyId, version } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // if at any point you want to do server side rendering, remove the noSsr prop
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [objPolicyDocument, setObjPolicyDocument] = useState({});
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const [isNextDisabled, setNextDisabledState] = useState(true);

  const { previewDoc, isLoadingPreview, policyObj, setPolicyObj } =
    usePreviewer();

  const { downloadPDF, isLoadingPDF } = usePolicyPDFDownloader();

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const setNextDisabled = (value) => {
    setNextDisabledState(value);
  };

  useEffect(() => {
    loadPolicyDocument();
  }, []);

  const loadPolicyDocument = async () => {
    setIsLoading(true);
    dispatch(fetchPolicyDocument({ objUser, policyId, clientId, version }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoading(false);

        const response = originalPromiseResult;

        try {
          if (response.status != 200) {
            throw new Error("Get policy document request failed.");
          }

          if (
            typeof response.data === "undefined" ||
            typeof response.data.id === "undefined"
          ) {
            throw new Error("Policy data is not valid");
          }
          setObjPolicyDocument(response.data);

          switch (response.data.state) {
            case "Draft":
              setActiveStep(0);
              break;
            case "In Review":
              setActiveStep(1);
              break;
            case "Pending Approval":
              setActiveStep(2);
              break;
            case "Authorized":
              setActiveStep(3);
              break;
            case "Published":
              setActiveStep(4);
              break;
            default:
              setActiveStep(0);
          }
          // objPolicy = response.data;
        } catch (error) {
          showMsg("error", "Something went wrong.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.data.status === "failed"
        ) {
          showMsg("error", "Invalid Url");
          navigate(-1);
        }
      });
  };

  const onClosePreviewDialog = () => {
    setPolicyObj(null);
  };

  const btnClickDownloadPolicyPDF = () => {
    const params = {
      policy_id: policyId,
      document_id: objPolicyDocument?.id,
    };
    downloadPDF(objUser, params);
  };

  return (
    <div className="policy-wizard-container">
      {typeof objPolicyDocument.state !== "undefined" ? (
        <Card sx={{ padding: "0.5rem" }}>
          <PreviewDocDialog
            //open={openPreviewDialog}
            open={policyObj !== null}
            onClose={onClosePreviewDialog}
            objPolicy={policyObj}
          />
          <MDBox>
            <Stepper
              className="create-policy-wizard-stepper"
              activeStep={activeStep}
              alternativeLabel={smallScreen ? false : true}
              orientation={smallScreen ? "vertical" : "horizontal"}
              sx={{
                paddingLeft: { xs: "1em", sm: "0" },
                paddingTop: "18px",
                paddingBottom: "12px",
              }}
            >
              {steps.map((label) =>
                objPolicyDocument.policy.policy_type === "asset" &&
                label === "ADOPTION" ? (
                  false
                ) : (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                )
              )}
            </Stepper>
          </MDBox>
          <MDBox p={2}>
            <MDBox>
              {getStepContent(
                activeStep,
                setActiveStep,
                objUser,
                objPolicyDocument,
                loadPolicyDocument,
                setNextDisabled
              )}
              <MDBox
                mt={3}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                {activeStep === 0 ? (
                  <MDBox />
                ) : (
                  <MDBox>
                    <MDButton
                      variant="outlined"
                      color="dark"
                      onClick={handleBack}
                    >
                      back
                    </MDButton>
                    <MDButton
                      variant="outlined"
                      color="dark"
                      onClick={() =>
                        previewDoc(objUser, policyId, objPolicyDocument?.id)
                      }
                      sx={{ marginLeft: "12px" }}
                    >
                      preview document
                    </MDButton>
                    {activeStep == 4 ? (
                      <MDButton
                        variant="gradient"
                        color="info"
                        // onClick={() =>
                        //   previewDoc(objUser, policyId, objPolicyDocument?.id)
                        // }
                        disabled={objUser?.user?.role?.name == "client_user"}
                        onClick={() => btnClickDownloadPolicyPDF()}
                        sx={{ marginLeft: "12px" }}
                      >
                        Download PDF
                      </MDButton>
                    ) : (
                      ""
                    )}
                  </MDBox>
                )}
                <MDButton
                  disabled={isNextDisabled}
                  variant="gradient"
                  color="dark"
                  onClick={!isLastStep ? handleNext : undefined}
                >
                  {isLastStep ? "send" : "next"}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <LoadingSpinner subClass="text-center" color="success" size="lg" />
      )}
    </div>
  );
}

export default CreatePolicyDocumentWizard;
