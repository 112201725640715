import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPDF } from "features/ssp/sspActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { showMsg } from "utils/general";

function useWispPDFDownloader() {
  const dispatch = useDispatch();
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);

  const downloadPDF = (objUser = null, sspId) => {
    setIsLoadingPDF(true);
    dispatch(getPDF({ objUser, sspId }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingPDF(false);
        const response = originalPromiseResult;

        if (response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${sspId}.pdf`); //or any other extension
          document.body.appendChild(link);
          link.click();
        } else {
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingPDF(false);
        console.log(rejectedValueOrSerializedError);
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.message
        );
        showMsg(
          "error",
          rejectedValueOrSerializedError.response?.data?.error_code
        );
      });
  };

  return { downloadPDF, isLoadingPDF };
}

export default useWispPDFDownloader;
