import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardButton from "views/components/DashboardButtton";

// Data
import dataTableData from "views/pages/Dashboard/Companies/data/dataTableData";
import { useDispatch, useSelector } from "react-redux";
import { fetchClientsForCompany } from "features/company/superAdminActions";
import { auth } from "../../../../../features/user/userActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ManagementTable from "./components/ManagementTable/ManagementTable";
import { showMsg } from "utils/general";
import "./Page.css";
import MDTypography from "components/MDTypography";
import { useCookies } from "react-cookie";

const DashboardPage = (props) => {
  const { objUser } = props;
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { company_id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};
  const [cookies, setCookie] = useCookies(["isAuthenticated"]);

  useEffect(() => {
    if (objUser.user.is_super_admin != 1) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    if (!cookies.isAuthenticated) {
      navigate(`/confirm-password`, {
        state: { routeDictionary },
      });
    }
    fetchCompanies(perPage, page);
  }, []);

  const fetchCompanies = (_per_page, _page) => {
    setIsFetchingData(true);
    dispatch(fetchClientsForCompany({ _per_page, _page, objUser, company_id }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsFetchingData(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;
        const objRows = [];
        const objList = response.data.clients;

        for (let index = 0; index < objList.length; index++) {
          const element = objList[index];
          const objTr = {};
          objTr.customerName = element.name;
          objTr.numberOfPolicies = element.policies_count;
          objTr.address = element.address_1;
          objTr.city = element.city;
          objTr.state = element.state;
          objTr.assetsMappedCount = element.assets_mapped_count;
          objTr.numberOfContacts = element.no_m_s_p_contacts_count;
          objTr.numberOfUsers = element.users_count;
          objTr.clientType = element.type;
          objTr.zip = element.zip;
          objRows.push(objTr);
        }
        setTotalCount(response.data.total);
        setObjTableData({
          ...dataTableData,
          rows: objRows,
        });
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsFetchingData(false);

        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Card sx={{ marginTop: "16px" }}>
          {isFetchingData === false &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <ManagementTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              totalCount={totalCount}
              fetchCompanies={fetchCompanies}
              perPage={perPage}
              setPerPage={setPerPage}
              _page={page}
              setPage={setPage}
              setObjTableData={setObjTableData}
            />
          ) : (
            <Fragment>
              {isFetchingData ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>No clients data</p>
              )}
            </Fragment>
          )}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
};

export default DashboardPage;
