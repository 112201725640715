import { useEffect, useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
// @mui icons
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaveApiKeysForm from "./components/SaveApiKeysForm";

import borders from "assets/theme/base/borders";

const ConfigSharePointSiteId = (props) => {
  const { borderWidth, borderColor } = borders;
  const navigate = useNavigate();
  const { objUser } = props;
  const { id } = useParams();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {}, []);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <Card> */}
      <Grid
        my={3}
        container
        // justifyContent="center"
        // alignItems="center"
        width="100%"
        spacing={2}
      >
        <SaveApiKeysForm id={id} objUser={objUser} />
      </Grid>
      {/* </Card> */}
    </DashboardLayout>
  );
};

export default ConfigSharePointSiteId;
