import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import BasicInfoForm from "layouts/pages/account/AccountSettings/components/BasicInfo/BasicInfoForm";
import AccountInfoForm from "layouts/pages/account/AccountSettings/components/AccountInfo/AccountInfoForm";

const AccountSettings = (props) => {
  const { objUser } = props;
  return (
    <BaseLayout>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfoForm objUser={objUser} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <MDBox mb={3}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <AccountInfoForm objUser={objUser} />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
};

export default AccountSettings;
