import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_ENDPOINT } from "components/utils/constants/misc";

// export const sendToZappier = createAsyncThunk(
//   "company/sendToZappier",
//   async (values, thunkApi) => {
//     try {
//       const { objPostData, objUser } = values;
//       const formData = new FormData();
//       for (const [key, value] of Object.entries(objPostData)) {
//         if (key == "file_currentcyberpolicy" && value instanceof Array) {
//           for (let i = 0; i < value.length; i++) {
//             formData.append(key, value[i]);
//           }
//         } else {
//           formData.append(key, value);
//         }
//       }
//       const res = await axios.post(
//         `https://hooks.zapier.com/hooks/catch/13441415/3lgha17/`,
//         formData
//       );
//       // return res.data;

//       return res;
//       // return Promise.resolve(res.data);
//     } catch (error) {
//       return thunkApi.rejectWithValue(error);
//     }
//   }
// );

export const sendToZappier = createAsyncThunk(
  "company/sendToZappier",
  async (values, thunkApi) => {
    try {
      const { objPostData, objUser } = values;
      const formData = new FormData();
      for (const [key, value] of Object.entries(objPostData)) {
        if (key === "file_currentcyberpolicy" && value instanceof Array) {
          for (let i = 0; i < value.length; i++) {
            formData.append(key, value[i]);
          }
        } else {
          formData.append(key, value);
        }
      }

      const response = await fetch(
        `https://hooks.zapier.com/hooks/catch/13441415/3lgha17/`,
        {
          method: "POST",
          body: formData,
          mode: "no-cors",
        }
      );

      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }
      // console.log(response.json());
      const data = response;

      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveExtraInfo = createAsyncThunk(
  "fifthwall/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchExtraInfo = createAsyncThunk(
  "fifthwall/fetch",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateFifthWallMembership = createAsyncThunk(
  "fifthwall/updateFifthWallMembership",
  async (values, thunkApi) => {
    try {
      const { objUser, objPostData } = values;
      const res = await axios.patch(
        `${BASE_ENDPOINT}/${objUser.user.company_id}/fifthwall`,
        objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
