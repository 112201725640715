/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import ContactsListDialog from "./ContactsListDialog";
// @mui icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Fragment, useState } from "react";

const KnowledgeBaseSharesActionBtnTr = (props) => {
  const {
    value,
    // column,
    row,
  } = props;

  const [openContactsList, setOpenContactsList] = useState(false);


  const onClose = () => {
    setOpenContactsList(false);
  };

  return (
    <Fragment>
      <ContactsListDialog
        open={openContactsList}
        onClose={onClose}
        contacts={value}
      />

      <MDButton onClick={() => setOpenContactsList(true)}>
        <MDBox color="dark" display="flex" alignItems="center">
          <VisibilityIcon></VisibilityIcon>
          <MDTypography
            variant="p"
            fontWeight="medium"
            color="text.dark"
            sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
          >
            View
          </MDTypography>
        </MDBox>
      </MDButton>
    </Fragment>
  );
};

export default KnowledgeBaseSharesActionBtnTr;
