import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardActionButton from "views/components/DashboardActionButton";

// Data
import dataTableData from "./data/dataTableData";
import globalDataTableData from "./data/dataTableData";

import { useDispatch } from "react-redux";
import {
  get,
  _delete,
} from "../../../../../features/assessmentConfig/assessmentConfigActions";
import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ManagementTable from "./components/TypesTable/ManagementTable";

import { showMsg } from "utils/general";
import "./ClientConfig.css";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import CreateNewDialog from "./components/TypesTable/createNewDialog";
import EditDialog from "./components/TypesTable/EditDialog";

const AssessmentTypes = (props) => {
  const { objUser } = props;

  /**categories */
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [isDeletingData, setIsDeletingData] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [objGlobalTableData, setObjGlobalTableData] =
    useState(globalDataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [openNewDataDialog, setOpenNewDataDialog] = useState(false);
  const [openEditDataDialog, setOpenEditDataDialog] = useState(false);
  const [editDataDialogData, setEditDataDialogData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [globalPerPage, setGlobalPerPage] = useState(10);
  const [globalPage, setGlobalPage] = useState(1);
  const [globalTotalCount, setGlobalTotalCount] = useState(0);

  const isClientUser =
    objUser.user && objUser.user.role
      ? objUser.user.role.name == "client_user"
      : false;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  /**categories **/
  const btnOnClickNewData = () => {
    setOpenNewDataDialog(true);
  };

  const onCloseNewDataDialog = () => {
    setOpenNewDataDialog(false);
  };

  const onSubmitNewDataDialog = (response) => {
    populateDataTableData(response);
    setOpenNewDataDialog(false);
  };

  const editData = (dataFromActionBtn) => {
    setEditDataDialogData(dataFromActionBtn);
    setOpenEditDataDialog(true);
  };

  const onCloseEditDataDialog = () => {
    setOpenEditDataDialog(false);
  };

  const onSubmitEditDataDialog = (response) => {
    populateDataTableData(response);
    setOpenEditDataDialog(false);
  };

  const loadMoreData = (_per_page, _page) => {
    setIsLoadingMoreData(true);
    dispatch(get({ _per_page, _page, objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsLoadingMoreData(false);
        populateDataTableData(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsLoadingMoreData(false);

        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 401 &&
          objErrorRsp.statusText === "Unauthorized"
        ) {
          console.log("User is not logged in");
        } else {
          showMsg("error", objErrorRsp?.data?.message);
          showMsg("error", objErrorRsp?.data?.error_code);
        }
      });
  };

  const populateDataTableData = (response) => {
    const objRows = [];
    const objGlobalRows = [];
    const objDataList = response.data.assessment_types;
    for (let index = 0; index < objDataList.length; index++) {
      const element = objDataList[index];
      const objTr = {};
      objTr.assessmentTypeName = element.name;
      objTr.assessmentTypeId = element.id;
      objTr.companyId = element.company_id;

      if (objTr.companyId) {
        objRows.push(objTr);
      } else {
        objGlobalRows.push(objTr);
      }
    }
    setTotalCount(response.data.total);
    setObjTableData({
      ...dataTableData,
      rows: objRows,
    });

    setGlobalTotalCount(objGlobalRows.length); // There is no pagination on the backend, so that is ok
    setObjGlobalTableData({
      ...globalDataTableData,
      rows: objGlobalRows,
    });
  };

  const deleteData = async (dataFromActionBtn) => {
    setIsDeletingData(true);
    dispatch(_delete({ objUser, dataFromActionBtn }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setIsDeletingData(false);

        const response = originalPromiseResult;
        if (response.status == 200 || response.status == 201) {
          showMsg("success", "Assessment type deleted");
          populateDataTableData(response);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setIsDeletingData(false);
        console.log(rejectedValueOrSerializedError);
        if (rejectedValueOrSerializedError.response.data.errors) {
          rejectedValueOrSerializedError.response.data.errors.forEach(
            (error) => {
              showMsg("error", error);
            }
          );
        } else if (rejectedValueOrSerializedError.response.data.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Error deleting assessment type");
        }
      });
  };

  useEffect(() => {
    if (
      objUser.user &&
      objUser.user.role &&
      objUser.user.role.name != "admin" &&
      objUser.user.is_super_admin != 1 &&
      objUser.user.role.name != "company_user"
    ) {
      navigate(`/home`, {
        state: { routeDictionary },
      });
    }
    loadMoreData(perPage, page);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      {/* assessment types crud */}
      <CreateNewDialog
        open={openNewDataDialog}
        onClose={onCloseNewDataDialog}
        onSubmit={onSubmitNewDataDialog}
        objUser={props.objUser}
      />
      <EditDialog
        open={openEditDataDialog}
        onClose={onCloseEditDataDialog}
        onSubmit={onSubmitEditDataDialog}
        objUser={props.objUser}
        editDataDialogData={editDataDialogData}
      />

      <MDBox>
        {!isClientUser && (
          <Fragment>
            <DashboardActionButton
              action={btnOnClickNewData}
              btnText="Add Assessment Type"
              btnIcon="add"
              textColor="white"
              bgColor="success"
            />
          </Fragment>
        )}
        <Grid
          spacing={2}
          container
          width="100%"
          sx={{ alignItems: "flex-start" }}
        >
          {/* custom */}

          <Grid item xs={12} lg={6}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography variant="h5" fontWeight="bold">
                  Your Assessment Types
                </MDTypography>
              </MDBox>

              {isLoadingMoreData === false &&
              objTableData.rows.length > 0 &&
              totalCount > 0 &&
              isDeletingData == false ? (
                <ManagementTable
                  table={objTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={totalCount}
                  loadMoreData={loadMoreData}
                  perPage={perPage}
                  setPerPage={setPerPage}
                  _page={page}
                  setPage={setPage}
                  setObjTableData={setObjTableData}
                  deleteData={deleteData}
                  editData={editData}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreData || isDeletingData == true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p style={{ padding: "1rem" }}>
                      No custom assessment types added yet.
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>

          {/* global */}
          <Grid item xs={12} lg={6}>
            <Card sx={{ marginTop: "16px" }}>
              <MDBox p={2}>
                <MDTypography variant="h5" fontWeight="bold">
                  Global Assessment Types
                </MDTypography>
              </MDBox>

              {isLoadingMoreData === false &&
              objGlobalTableData.rows.length > 0 &&
              globalTotalCount > 0 &&
              isDeletingData == false ? (
                <ManagementTable
                  table={objGlobalTableData}
                  entriesPerPage={true}
                  canSearch
                  totalCount={globalTotalCount}
                  loadMoreData={loadMoreData}
                  perPage={perPage}
                  setPerPage={setGlobalPerPage}
                  _page={globalPage}
                  setPage={setGlobalPage}
                  setObjTableData={setObjGlobalTableData}
                  deleteData={deleteData}
                  editData={editData}
                />
              ) : (
                <Fragment>
                  {isLoadingMoreData || isDeletingData == true ? (
                    <LoadingSpinner
                      subClass="text-center"
                      color="success"
                      size="lg"
                    />
                  ) : (
                    <p style={{ padding: "1rem" }}>
                      No global assessment types at the moment.
                    </p>
                  )}
                </Fragment>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentTypes;
