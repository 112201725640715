/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ProductsList page components
import DefaultCell from "views/components/TableCells/DefaultCell";

import NameBtnTr from "./NameBtnTr";
import { Fragment } from "react";

let DataTableData = {
  columns: [
    {
      Header: "Name",
      accessor: "name",
      // Cell: ({ value, column, row }) => (
      //   <NameBtnTr value={value} column={column} row={row} />
      // ),
      Cell: ({ value, column, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Company",
      accessor: "company_name",
      Cell: ({ value, column, row }) => <DefaultCell value={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Submitted",
      accessor: "created_at",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],
  rows: [],
};

export default DataTableData;
