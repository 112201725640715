/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { useConfirm } from "material-ui-confirm";
import { Fragment } from "react";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

const AudienceActionBtnTr = (props) => {
  const {
    value,
    column,
    row,
    deleteTheAudience,
    isDeletingAudience,
    setIsDeletingAudience,
  } = props;

  const confirm = useConfirm();
  const btnOnClickDeleteAudience = (data) => {
    confirm({
      description: "Are you sure, You want to delete this audience?",
      confirmationButtonProps: {
        color: "success",
        variant: "contained",
        sx: {
          backgroundColor: "#4CAF50",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#1b5e20",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
      cancellationButtonProps: {
        color: "error",
        variant: "contained",
        sx: {
          backgroundColor: "#d32f2f",
          color: "#fff",
          "&:hover": {
            backgroundColor: "#d32f2f",
            color: "#fff",
            boxShadow:
              "0px 2px 4px -1pxrgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
          },
        },
      },
    })
      .then(() => {
        deleteTheAudience(data);
      })
      .catch(() => {
        /* ... */
      });
  };

  return (
    <Fragment>
      {row?.original?.type != "Reviewers" &&
      row?.original?.type != "Approvers" &&
      row?.original?.type != "Legal" ? (
        <>
          {isDeletingAudience ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <MDButton
              onClick={() => btnOnClickDeleteAudience(row.original)}
              variant="gradient"
              sx={{ marginLeft: "1rem" }}
            >
              <MDBox color="error" display="flex" alignItems="center">
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                <MDTypography
                  variant="p"
                  fontWeight="medium"
                  color="error"
                  sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                >
                  Delete
                </MDTypography>
              </MDBox>
            </MDButton>
          )}
        </>
      ) : (
        <>
          {isDeletingAudience ? (
            <LoadingSpinner subClass="text-center" color="success" size="lg" />
          ) : (
            <MDButton
              onClick={() => btnOnClickDeleteAudience(row.original)}
              variant="gradient"
              sx={{ marginLeft: "1rem" }}
              disabled={true}
            >
              <MDBox color="secondary" display="flex" alignItems="center">
                <Icon sx={{ fontWeight: "bold" }}>delete</Icon>
                <MDTypography
                  variant="p"
                  fontWeight="medium"
                  color="secondary"
                  sx={{ lineHeight: 0, marginLeft: "0.5rem" }}
                >
                  Cant Delete
                </MDTypography>
              </MDBox>
            </MDButton>
          )}
        </>
      )}
    </Fragment>
  );
};

export default AudienceActionBtnTr;
