import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React Examples
import MDBox from "components/MDBox";
import DashboardActionButton from "views/components/DashboardActionButton";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
// Data
import { unwrapResult } from "@reduxjs/toolkit";
import { getAssessmentEvents } from "features/company/assessmentsActions";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import dataTableData from "views/pages/Dashboard/Customers/CustomersManagement/data/dataTableData";
import AssessmentEventsTable from "./components/AssessmentEventsTable/AssessmentEventsTable";
import CreateNewEventDialog from "./components/AssessmentEventsTable/createNewEventDialog";
import ImportEventCSVDialog from "./components/AssessmentEventsTable/ImportEventCSVDialog";
import MDTypography from "components/MDTypography";

const AssessmentEvents = (props) => {
  const { objUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [openNewEventDialog, setOpenNewEventDialog] = useState(false);
  const [openImportEventDialogCSV, setOpenImportEventDialogCSV] =
    useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData);
  const [totalCount, setTotalCount] = useState(0);
  const [objImportedTableData, setObjImportedTableData] =
    useState(dataTableData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  useEffect(() => {
    loadMoreEvents();
  }, []);
  const loadMoreEvents = () => {
    setIsLoading(true);
    dispatch(getAssessmentEvents({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        // handle result here
        const response = originalPromiseResult;
        const objRows = response.data.events.map((objEvent) => {
          const objTr = {};
          objTr.version = objEvent.version ? objEvent.version + ".0" : "";
          objTr.name = objEvent.name;
          objTr.assessmentTemplateId = objEvent.assessment_template_id;
          objTr.status = objEvent.status;
          objTr.riskLevel = objEvent.risk_level?.name;
          objTr.createdBy = objEvent.created_by;
          objTr.isImported = objEvent.external_import_source ? true : false;
          objTr.createdFor = objEvent.client_name;
          objTr.startDate = new Date(objEvent.start_date).toLocaleDateString();
          objTr.completedOn = objEvent.end_date
            ? new Date(objEvent.end_date).toLocaleDateString()
            : "";
          objTr.id = objEvent.id;
          objTr.percent_complete = objEvent.percent_complete;
          return objTr;
        });

        let platformItems = objRows.filter((item) => {
          return !item.isImported;
        });
        setObjTableData({
          ...dataTableData,
          rows: platformItems,
        });

        let ImportedItems = objRows.filter((item) => {
          return item.isImported;
        });
        setObjImportedTableData({
          ...dataTableData,
          rows: ImportedItems,
        });
        setTotalCount(response.data.total);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(
          "rejectedValueOrSerializedError",
          rejectedValueOrSerializedError
        );

        const objErrorRsp = rejectedValueOrSerializedError.response;
        showMsg("error", objErrorRsp?.data?.message);
        showMsg("error", objErrorRsp?.data?.error_code);
        // if (
        //   objErrorRsp.status === 401 &&
        //   objErrorRsp.statusText === "Unauthorized"
        // ) {
        //   console.log("User is not logged in");
        // } else {
        //   // showMsg("error", "Something went wrong, please try again.");
        //   showMsg("error", objErrorRsp.data.message);
        //   showMsg("error", objErrorRsp.data.error_code);
        // }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const takeTheAssessment = (objEventData) => {
    navigate(
      `/assessments/events/${objEventData.assessmentTemplateId}/${objEventData.id}`,
      {
        state: { routeDictionary },
      }
    );
  };

  const viewAssessmentVersionsPage = (objEventData) => {
    routeDictionary[objEventData.assessmentTemplateId] = objEventData.name;
    navigate(`/assessments/events/${objEventData.assessmentTemplateId}`, {
      state: { routeDictionary },
    });
  };

  const openNewEventDialogHandler = () => {
    setOpenNewEventDialog(true);
  };

  {
    /* left for future */
  }
  // const btnOnClickImportEvent = () => {
  //   setOpenImportEventDialogCSV(true);
  // };

  const onSubmitImportEventDialogCSV = () => {
    setOpenImportEventDialogCSV(false);
    showMsg("success", "New Template added successfully");
    loadMoreEvents();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateNewEventDialog
        objUser={objUser}
        open={openNewEventDialog}
        onClose={() => setOpenNewEventDialog(false)}
        onSubmit={takeTheAssessment}
      />
      <ImportEventCSVDialog
        open={openImportEventDialogCSV}
        onClose={() => setOpenImportEventDialogCSV(false)}
        onSubmit={onSubmitImportEventDialogCSV}
        objUser={props.objUser}
      />
      <MDBox>
        <Card sx={{ marginTop: "16px", padding: "32px" }}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h3">Platform Events</MDTypography>
            <MDBox
              display="flex"
              gap=".6rem"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={0}
            >
              <DashboardActionButton
                action={openNewEventDialogHandler}
                btnText="Create Assessment Event"
                btnIcon={"add"}
                textColor="white"
                bgColor="success"
                className="dashboard-button"
              />

              {/* left for future */}
              {/* <DashboardActionButton
                action={btnOnClickImportEvent}
                btnText="Import Completed Assessment"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                sx={{ margin: "0" }}
              /> */}
            </MDBox>
          </MDBox>
          {isLoading === false &&
          objTableData !== undefined &&
          objTableData.rows !== undefined &&
          objTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <AssessmentEventsTable
              table={objTableData}
              entriesPerPage={true}
              canSearch
              objUser={objUser}
              totalCount={totalCount}
              takeTheAssessment={takeTheAssessment}
              viewAssessmentVersionsPage={viewAssessmentVersionsPage}
            />
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no Assessment Events</p>
              )}
            </Fragment>
          )}
        </Card>

        {/* left for future */}
        {/* <Card sx={{ marginTop: "16px", padding: "32px" }}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDTypography variant="h3">Imported Events</MDTypography>
            <MDBox
              display="flex"
              gap=".6rem"
              justifyContent="space-between"
              alignItems="flex-start"
              mb={0}
            >
              <DashboardActionButton
                action={btnOnClickImportEvent}
                btnText="Import Completed Assessment"
                btnIcon="add"
                textColor="white"
                bgColor="success"
                sx={{ margin: "0" }}
              />
            </MDBox>
          </MDBox>
          {isLoading === false &&
          objImportedTableData !== undefined &&
          objImportedTableData.rows !== undefined &&
          objImportedTableData.rows.length > 0 &&
          totalCount > 0 ? (
            <AssessmentEventsTable
              table={objImportedTableData}
              entriesPerPage={true}
              canSearch
              objUser={objUser}
              totalCount={totalCount}
              takeTheAssessment={takeTheAssessment}
              viewAssessmentVersionsPage={viewAssessmentVersionsPage}
            />
          ) : (
            <Fragment>
              {isLoading ? (
                <LoadingSpinner
                  subClass="text-center"
                  color="success"
                  size="lg"
                />
              ) : (
                <p>There are no Imported Assessment Events</p>
              )}
            </Fragment>
          )}
        </Card> */}
      </MDBox>
    </DashboardLayout>
  );
};

export default AssessmentEvents;
