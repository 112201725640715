import { useEffect, useState, Fragment } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import Card from "@mui/material/Card";
import DashboardActionButton from "views/components/DashboardActionButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import DashboardLayout from "views/containers/DashboardLayout";
import CreateNewPostDialog from "./components/CreateNewPostDialog";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { showMsg } from "utils/general";
import { fetchPeerGroupPostSuper } from "features/company/peerGroupActions";
import dataTableData from "./components/DataTableData";
import PostsDataTable from "./components/PostsDataTable";

function PeerGroupPosts(props) {
  const { objUser } = props;
  const [isOpenNewDialog, setIsOpenNewDialog] = useState(false);
  const [objSelectedPost, setObjSelectedPost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [objTableData, setObjTableData] = useState(dataTableData(() => {}));
  const [cookies] = useCookies(["isAuthenticated"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeDictionary = useLocation().state?.routeDictionary ?? {};

  const onEditPost = (objPost) => {
    setIsOpenNewDialog(true);
    setObjSelectedPost(objPost);
  };

  useEffect(() => {
    if (objUser.user.role.name !== "peer_group_admin") {
      if (!cookies.isAuthenticated) {
        navigate(`/confirm-password`, {
          state: { routeDictionary },
        });
      }
    }
  }, []);

  const fetchPosts = () => {
    setIsLoading(true);
    dispatch(fetchPeerGroupPostSuper())
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        if (response.status === 200) {
          const arrPosts = response.data;
          const arrRows = [];
          arrPosts.forEach((objPost) => {
            arrRows.push({
              id: objPost.id,
              title: objPost?.title,
              description:
                objPost.description.length > 50
                  ? objPost.description.substring(0, 50) + "..."
                  : objPost.description,
              status: objPost?.status,
              content_type: objPost?.content_type,
              video_url: objPost?.video_url,
              video_provider: objPost?.video_provider,
              created_by: objPost?.created_by?.name,
              element: objPost,
            });
          });
          setObjTableData({
            ...dataTableData(onEditPost),
            rows: arrRows,
          });
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        if (rejectedValueOrSerializedError?.response?.data?.message) {
          showMsg(
            "error",
            rejectedValueOrSerializedError.response.data.message
          );
        } else {
          showMsg("error", "Something went wrong, please try again.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const btnOnClickNewPost = () => {
    setObjSelectedPost(null);
    setIsOpenNewDialog(true);
  };

  const onCloseNewPostDialog = () => {
    setIsOpenNewDialog(false);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <CreateNewPostDialog
        open={isOpenNewDialog}
        onClose={onCloseNewPostDialog}
        post={objSelectedPost}
        fetchPosts={fetchPosts}
      />
      <Card position="relative" sx={{ padding: "32px", marginBottom: "1rem" }}>
        <MDBox
          mb={1}
          textAlign="left"
          sx={{
            display: "inline-flex",
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <MDTypography variant="h3">Peer Group Posts</MDTypography>
          <MDBox
            sx={{
              display: "inline-flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <DashboardActionButton
              action={btnOnClickNewPost}
              btnText="Create Post"
              btnIcon="add"
              textColor="white"
              bgColor="success"
              sx={{ margin: "0" }}
            />
          </MDBox>
        </MDBox>
        {isLoading === false && objTableData.rows.length > 0 ? (
          <PostsDataTable
            table={objTableData}
            entriesPerPage={true}
            canSearch
            totalCount={objTableData.rows.length}
            objUser={objUser}
          />
        ) : (
          <Fragment>
            {isLoading ? (
              <LoadingSpinner
                subClass="text-center"
                color="success"
                size="lg"
              />
            ) : (
              <p className="empty-text-p">
                There are no posts available at this time.
              </p>
            )}
          </Fragment>
        )}
      </Card>
    </DashboardLayout>
  );
}

export default PeerGroupPosts;
