import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { unwrapResult } from "@reduxjs/toolkit";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { fetchGamifycationStatsAssessments } from "features/company/companiesActions";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { useTheme } from "@mui/material/styles";
// PieChart configurations
import "./charts.css";
import configs from "./configs/assessment";
import AssessmentGamePieChart from "./AssessmentGamePieChart";

export default function AssessmentCharts({ objUser, trustCenterPage }) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [objGamifycationStats, setObjGamifycationStats] = useState([]);
  const dispatch = useDispatch();
  const getStats = () => {
    setIsLoading(true);
    dispatch(fetchGamifycationStatsAssessments({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setObjGamifycationStats(originalPromiseResult.data);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
        setObjGamifycationStats([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getStats();
  }, []);

  const charts = useMemo(() => {
    const pies = [];
    const colors = [];
    const labels = [];
    objGamifycationStats?.itemScores?.forEach((score) => {
      colors[score.code] = score.color;
      labels[score.code] = score.name;
    });

    /**Add another score lavel and color for unanswered questions */
    colors["6"] = "#cccccc";
    labels["6"] = "Questions Unanswered";

    let data, options;
    objGamifycationStats?.scores?.forEach((objStat) => {
      if (objStat.event_id === null) {
        const dataset = new Array(labels.length).fill(0);
        dataset.push(360);
        const tooltip = new Array(labels.length).fill("");
        tooltip.push("Assessment Not Started");
        ({ data, options } = configs(
          labels,
          colors,
          objStat.name,
          dataset,
          tooltip,
          theme.palette
        ));
      } else {
        const dataset = [
          objStat.not_applicable,
          objStat.unknown,
          objStat.at_risk,
          objStat.needs_attention,
          objStat.acceptable_risk,
          objStat.satisfactory,
          objStat.unanswered,
          0,
        ];

        const tooltip = [
          `Not Applicable: ${objStat.not_applicable} question${
            objStat.not_applicable > 1 ? "s" : ""
          }`,
          `Unknown: ${objStat.unknown} question${
            objStat.unknown > 1 ? "s" : ""
          }`,
          `At Risk: ${objStat.at_risk} question${
            objStat.at_risk > 1 ? "s" : ""
          }`,
          `Needs Attention: ${objStat.needs_attention} question${
            objStat.needs_attention > 1 ? "s" : ""
          }`,
          `Acceptable Risk: ${objStat.acceptable_risk} question${
            objStat.acceptable_risk > 1 ? "s" : ""
          }`,
          `Satisfactory: ${objStat.satisfactory} question${
            objStat.satisfactory > 1 ? "s" : ""
          }`,
          `Questions Unanswered: ${objStat.unanswered} question${
            objStat.unanswered > 1 ? "s" : ""
          }`,
          `Assessment Not Started`,
        ];
        ({ data, options } = configs(
          labels,
          colors,
          objStat.name,
          dataset,
          tooltip,
          theme.palette
        ));
      }

      pies.push(
        <AssessmentGamePieChart
          key={objStat.name}
          data={data}
          options={options}
          title={objStat.name}
          total={objStat.total}
          eventID={objStat.event_id}
          status={objStat.status}
          score={objStat.score}
          possible_score={objStat.possible_score}
          trustCenterPage={trustCenterPage}
        />
      );
    });
    if (pies.length === 0) {
      return (
        <Grid container spacing={2} justifyContent="center">
          <MDTypography
            variant="caption"
            color="text"
            align="center"
            sx={{ fontStyle: "italic" }}
          >
            No Assessment Templates Created or Imported
          </MDTypography>
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={2} justifyContent="space-evenly">
          {pies}
        </Grid>
      );
    }
  }, [objGamifycationStats, theme.palette]);

  return (
    <Card mb={2}>
      <MDBox py={2} pr={2} pl={2}>
        <MDBox display="flex" px={0} pt={0}>
          <MDBox mt={0}>
            <MDTypography
              sx={{ marginBottom: "0.5rem" }}
              fontWeight="bold"
              variant="h5"
              color="dark"
            >
              Assessment Scorecard
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          sx={{ height: trustCenterPage ? 530 : "auto", overflow: "auto" }}
        >
          {isLoading ? <LoadingSpinner /> : charts}
        </MDBox>
      </MDBox>
    </Card>
  );
}
