import React, { useEffect, useState } from "react";
import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import FifthWallLogo from "assets/images/logos/fifthwall-logo-horiz.png";
import SuccessLogo from "assets/images/success-logo.png";
import InfoForm from "./components/InfoForm";
import { fetchExtraInfo } from "features/company/insuranceActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { updateFifthWallMembership } from "features/company/insuranceActions";

const InsuranceScorecard = ({ objUser }) => {
  const dispatch = useDispatch();
  const [isFifthWallMember, setIsFifthWallMember] = useState(true);
  const [extraInfo, setExtraInfo] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchExtraInfoData = () => {
    setIsLoading(true);
    dispatch(fetchExtraInfo({ objUser }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setExtraInfo(response.data.info);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        setIsLoading(false);
      });
  };

  const updateMembership = () => {
    setIsLoading(true);
    let objPostData = {
      is_member: 0,
    };
    dispatch(updateFifthWallMembership({ objUser, objPostData }))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        const response = originalPromiseResult;
        setIsFifthWallMember(false);
        setIsLoading(false);
      })
      .catch((rejectedValueOrSerializedError) => {
        const objErrorRsp = rejectedValueOrSerializedError.response;
        console.log(rejectedValueOrSerializedError);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchExtraInfoData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {!submitted ? (
        <MDBox
          display={!showForm && "flex"}
          justifyContent="center"
          alignItems="center"
        >
          {isFifthWallMember == true ? (
            <Card>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {!showForm ? (
                    <MDBox p={3}>
                      <Grid>
                        <MDTypography variant="h4" fontWeight="bold">
                          Are you a Fifthwall Solutions member?
                        </MDTypography>
                        <MDBox mt={2.5} mb={2.5} textAlign="center">
                          <img src={FifthWallLogo} style={{ width: "250px" }} />
                        </MDBox>
                        <MDBox
                          display="flex"
                          gap=".6rem"
                          justifyContent="center"
                          alignItems="flex-start"
                        >
                          <MDButton
                            type="button"
                            color="dark"
                            sx={{ padding: "12px 12px" }}
                            onClick={() => {
                              setShowForm(true);
                            }}
                          >
                            Yes
                          </MDButton>
                          <MDButton
                            type="button"
                            variant="outlined"
                            color="error"
                            sx={{ padding: "12px 12px" }}
                            onClick={updateMembership}
                          >
                            No
                          </MDButton>
                        </MDBox>
                      </Grid>
                    </MDBox>
                  ) : (
                    <Card>
                      <MDBox textAlign="center" mt={4}>
                        <img src={FifthWallLogo} style={{ width: "350px" }} />
                      </MDBox>
                      <InfoForm
                        objUser={objUser}
                        extraInfo={extraInfo}
                        setSubmitted={setSubmitted}
                      />
                    </Card>
                  )}
                </>
              )}
            </Card>
          ) : (
            <Card>
              <MDBox p={2} px={8}>
                <MDTypography color="text" fontWeight="bold" variant="p">
                  Go to{" "}
                  <Link
                    to={"http://fifthwallsolutions.com/msp"}
                    target="_blank"
                  >
                    http://fifthwallsolutions.com/msp
                  </Link>{" "}
                  to become a member.
                </MDTypography>
              </MDBox>
            </Card>
          )}
        </MDBox>
      ) : (
        <MDBox
          display={"flex"}
          justifyContent="center"
          alignItems="center"
          mt={10}
        >
          <Card>
            <MDBox py={4} px={10}>
              <Grid>
                <MDBox
                  display="flex"
                  gap=".6rem"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  <img
                    src={SuccessLogo}
                    style={{
                      width: "100px",
                      position: "relative",
                      top: "-80px",
                    }}
                  />
                </MDBox>
                <MDTypography
                  textAlign="center"
                  variant="h3"
                  mt={-7}
                  fontWeight="bold"
                >
                  Success!
                </MDTypography>

                <MDTypography variant="body2" textAlign="center">
                  Your submission has been made successfully. <br /> Thank you.
                </MDTypography>
              </Grid>
            </MDBox>
          </Card>
        </MDBox>
      )}
    </DashboardLayout>
  );
};

export default InsuranceScorecard;
