// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

import MDAlert from "components/MDAlert";

import DashboardLayout from "views/containers/DashboardLayout";
import DashboardNavbar from "views/components/Navbars/DashboardNavbar";
import ConnectWiseConfirmationSection from "./components/ConnectWiseConfirmationSection/ConnectWiseConfirmationSection";

const StripeSubscriptionCheckOutSuccess = (props) => {
  const { objUser } = props;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
        my={3}
        container
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Grid item xs={12} lg={12}>
          <Card>
            <p></p>
            <MDAlert color="success" dismissible>
              <MDTypography
                fontWeight="regular"
                color="white"
                textAlign="center"
              >
                Your stripe card was charged successfully.
              </MDTypography>
            </MDAlert>
            <ConnectWiseConfirmationSection objUser={objUser} />
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default StripeSubscriptionCheckOutSuccess;
