import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { showMsg } from "utils/general";
import { useDispatch } from "react-redux";
import {
  getPolicyDocumentWithContactIdAndAdoptionSecrete,
  adoptPolicy,
} from "features/company/campaignActions";

import { unwrapResult } from "@reduxjs/toolkit";
import LoadingSpinner from "utils/Helpers/Loading/LoadingSpinner";

import { useNavigate, useParams } from "react-router-dom";
import { Checkbox, FormLabel } from "@mui/material";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const PolicyAdoptionRequest = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [policyDocument, setPolicyDocument] = useState("");
  const [documentSections, setDocumentSections] = useState([]);
  const [docType, setDocType] = useState("oscal");
  const [policyAdopted, setPolicyAdopted] = useState(false);
  const [policyDocumentLoading, setPolicyDocumentLoading] = useState(true);
  const [adoptionLoading, setAdoptionLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSignature, setOpenSignature] = useState(false);
  const [checked, setChecked] = useState(false);
  const [signature, setSignature] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  //const { objUser } = props;
  let { company_id, client_id, contact_id, campaign_id, adoption_secret } =
    useParams();

  useEffect(() => {
    getPolicyDetails();
  }, []);

  async function getPolicyDetails() {
    dispatch(
      getPolicyDocumentWithContactIdAndAdoptionSecrete({
        //objUser,
        companyId: company_id,
        clientId: client_id,
        contactId: contact_id,
        campaignId: campaign_id,
        adoptionSecret: adoption_secret,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setPolicyDocumentLoading(false);
        // handle result here
        // const data = response.data;
        const response = originalPromiseResult;

        if (response.status === 201) {
          //  showMsg("success", "Your contacts have been successfully imported.");
          setPolicyDocument(response.data.policy_document.document);
          setDocumentSections(
            response.data.document_sections.sort(
              (a, b) =>
                a.owning_global_section.order - b.owning_global_section.order
            )
          );
          setDocType(response.data.policy_document.policy_doc_type);
          setPolicyAdopted(
            response.data.campaign_adoption_status.is_adopted == 1
              ? true
              : false
          );
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setPolicyDocumentLoading(false);
        //const objErrors = rejectedValueOrSerializedError.response.data.errors;
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.statusText === "Not Found"
        ) {
          showMsg("error", "Invalid Url");
          navigate(`/home`);
        } else {
          //setObjCreateClientContactByClientIdFormErrors(objErrors);
          showMsg("error", "Please correct the errors.");
        }
        // handle result here
      });
  }

  async function adopt() {
    if (signature == "") {
      showMsg("error", "Please sign the document");
      return;
    }
    setOpenSignature(false);
    setAdoptionLoading(true);
    dispatch(
      adoptPolicy({
        //  objUser,
        companyId: company_id,
        clientId: client_id,
        contactId: contact_id,
        campaignId: campaign_id,
        adoptionSecret: adoption_secret,
      })
    )
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setAdoptionLoading(false);

        const response = originalPromiseResult;

        if (response.status === 201) {
          showMsg("success", "Policy successfully adopted");
          navigate(-1);
        } else {
          showMsg("error", "Something went wrong, please try again");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setAdoptionLoading(false);
        const objErrorRsp = rejectedValueOrSerializedError.response;
        if (
          objErrorRsp.status === 404 &&
          objErrorRsp.statusText === "Not Found"
        ) {
          showMsg("error", "Invalid Url");
        } else {
          showMsg("error", "Please correct the errors.");
        }
      });
  }

  return (
    <MDBox sx={{ margin: "0 auto", padding: "1em" }}>
      <Dialog onClose={handleClose} open={open} fullWidth={true}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          ELECTRONIC RECORD AND SIGNATURE DISCLOSURE
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <MDTypography sx={{ fontSize: "1rem", padding: "1em" }}>
            Please read the following information carefully. By clicking the 'I
            confirm' checkbox, you agree that you have reviewed the documents
            and consent to transact business electronically using our platform’s
            electronic signature system. If you do not agree to these terms, do
            not click the 'I confirm' checkbox.
          </MDTypography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => setOpenSignature(false)}
        open={openSignature}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Please enter you name to digitally sign
          <IconButton
            aria-label="close"
            onClick={() => setOpenSignature(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <MDInput
            pt={0}
            type="text"
            label="Signature"
            name="signature"
            placeholder={""}
            variant="standard"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
            fullWidth
            error={signature.length === 0}
            success={signature.length > 0}
          />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="button"
            // variant="gradient"
            color="success"
            sx={{ padding: "12px 12px" }}
            onClick={adopt}
          >
            Adopt
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox display="flex" gap=".6rem" alignItems="flex-start" mb={2}>
        <Card sx={{ margin: "0 auto", width: "90%" }}>
          <MDBox sx={{ padding: "1.2em" }}>
            <MDTypography textAlign="left" variant="h4" fontWeight="bold">
              Review Policy
            </MDTypography>
          </MDBox>

          {/* loading sections */}
          <MDBox sx={{ padding: "1.2em" }}>
            {docType == "single_page" ? (
              <MDTypography textAlign="left" variant="h6" fontWeight="bold">
                {policyDocumentLoading ? (
                  <LoadingSpinner color="success" size="lg" />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{ __html: policyDocument }}
                  ></span>
                )}
              </MDTypography>
            ) : (
              <>
                {documentSections.map((section) => {
                  return (
                    <>
                      {section.owning_global_section.title !== "Policy" &&
                      (section.section_content == "" ||
                        section.section_content == null) ? (
                        ""
                      ) : (
                        <div key={section.id}>
                          <MDTypography
                            sx={{ marginBottom: "10px", marginTop: "20px" }}
                            variant="body2"
                            fontWeight="bold"
                          >
                            <u>{section.owning_global_section.title}</u>
                          </MDTypography>
                          <MDTypography textAlign="left" variant="p">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: section.section_content,
                              }}
                            ></span>
                          </MDTypography>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </MDBox>
          <MDBox sx={{ padding: "0.8em", paddingBottom: "0" }}>
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              icon={
                <CheckBoxOutlineBlankOutlinedIcon
                  style={{ borderColor: "#0b2e4a" }}
                />
              }
            />
            <FormLabel sx={{ fontSize: "1rem" }}>
              I confirm that I have read and understood the{" "}
              <u style={{ cursor: "pointer" }} onClick={handleClickOpen}>
                "Electronic Record and Signature Disclosure"
              </u>{" "}
              and consent to use electronic records and signatures.
            </FormLabel>
          </MDBox>
          <MDBox sx={{ padding: "1.2em", paddingTop: "0" }}>
            {adoptionLoading ? (
              <MDBox>
                <LoadingSpinner color="success" size="lg" />
              </MDBox>
            ) : (
              <MDButton
                onClick={() => setOpenSignature(true)}
                type="button"
                variant="gradient"
                disabled={policyAdopted || !checked}
                color={policyAdopted ? "success" : "dark"}
                sx={{ marginTop: "1rem", padding: "5px 15px" }}
              >
                <MDTypography fontWeight="bold" color="white" variant="button">
                  {policyAdopted ? "Policy already adopted" : "Adopt"}
                </MDTypography>
              </MDButton>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
};

export default PolicyAdoptionRequest;
