import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  BACKEND_API_URL,
  BASE_ENDPOINT,
} from "components/utils/constants/misc";
import { KBS_SERVER } from "components/utils/constants/misc";

const createKBSURL = (company_id, client_id) => {
  return KBS_SERVER.replace("{companyId}", company_id).replace(
    "{clientId}",
    client_id
  );
};

const getCompanyID = (objUser) => {
  return objUser.user.company_id;
};

export const shareKnowledgeBase = createAsyncThunk(
  "kbs/share",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs/`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createKnowledgeBase = createAsyncThunk(
  "kbs/create",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs/create-only`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getKBWithIdAndSecret = createAsyncThunk(
  "kbs/getKBWithIdAndSecret",
  async (values, thunkApi) => {
    try {
      const { company_id, client_id, kbs_id, kbs_secret, currentPage } = values;
      const this_kbs_server = createKBSURL(company_id, client_id);
      const res = await axios.get(
        `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}?sharedLinkId=${kbs_secret}&page=${currentPage}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

//old method for getting documet as pdf
export const getPolicyDocumentKB = createAsyncThunk(
  "kbs/getPolicyDocumentKB",
  async (values, thunkApi) => {
    try {
      const { company_id, client_id, kbs_id, kbs_secret, document_id } = values;
      const this_kbs_server = createKBSURL(company_id, client_id);
      const res = await axios.get(
        `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}/policy-document/${document_id}?sharedLinkId=${kbs_secret}`,
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const applySortingFilter = createAsyncThunk(
  "kbs/applySortingFilter",
  async (values, thunkApi) => {
    try {
      const {
        company_id,
        client_id,
        kbs_id,
        kbs_secret,
        filterObject,
        currentFilterPage,
      } = values;
      const this_kbs_server = createKBSURL(company_id, client_id);
      const res = await axios.post(
        // `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}?sharedLinkId=${kbs_secret}`,
        `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}/filter?page=${currentFilterPage}`,
        { filterObject: filterObject }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

//documents
export const getKBDocumentWithIdAndSecret = createAsyncThunk(
  "kbs/ getKBDocumentWithIdAndSecret",
  async (values, thunkApi) => {
    try {
      const {
        company_id,
        client_id,
        kbs_id,
        policy_id,
        policy_document_id,
        kbs_secret,
      } = values;
      const this_kbs_server = createKBSURL(company_id, client_id);
      const res = await axios.get(
        `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}/${policy_id}/${policy_document_id}/?sharedLinkId=${kbs_secret}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getListOfAdoptersDocumentPDF = createAsyncThunk(
  "policy/get-data-to-review-pdf",
  async (values, thunkApi) => {
    try {
      const {
        company_id,
        client_id,
        kbs_id,
        policy_id,
        policy_document_id,
        kbs_secret,
        policyDocument,
      } = values;
      const this_kbs_server = createKBSURL(company_id, client_id);
      const res = await axios.post(
        `${BACKEND_API_URL}${this_kbs_server}/${kbs_id}/${policy_id}/${policy_document_id}/get-adopters-pdf/?sharedLinkId=${kbs_secret}`,
        { policyDocument },
        { responseType: "blob" }
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

//knowlege base
export const getActiveKnowledgeBaseShares = createAsyncThunk(
  "company/getActiveKnowledgeBaseShares",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/kbs/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getNonExpiryKB = createAsyncThunk(
  "kbs/getNonExpiryActive",
  async (values, thunkApi) => {
    try {
      const { objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs/non-expiry`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateKnowledgeBaseExpiry = createAsyncThunk(
  "kbs/updateExpiry",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs/expiry-date`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const shareKnowledgeBaseToContacts = createAsyncThunk(
  "kbs/shareLatestKB",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;
      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs/share-latest-kb`,
        objPostData.objPostData
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// allowed domains

/**categories */
export const getAllowedDomains = createAsyncThunk(
  "company/getAllowedDomains",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BASE_ENDPOINT}/${getCompanyID(
          objUser
        )}/kbs-domains/?per_page=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const addAllowedDomain = createAsyncThunk(
  "company/addAllowedDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.post(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs-domains`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteAllowedDomainData = createAsyncThunk(
  "company/deleteAllowedDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, dataFromActionBtn } = values;
      const res = await axios.delete(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs-domains/${
          dataFromActionBtn.domainId
        }`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const saveAllowedDomainChanges = createAsyncThunk(
  "company/saveAllowedDomain",
  async (values, thunkApi) => {
    try {
      const { objUser, ...objPostData } = values;

      const res = await axios.patch(
        `${BASE_ENDPOINT}/${getCompanyID(objUser)}/kbs-domains/${
          objPostData.objPostData.id
        }`,
        objPostData.objPostData
      );
      // return res.data;
      return res;
      // return Promise.resolve(res.data);
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);
