import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { BACKEND_API_URL } from "components/utils/constants/misc";
// import { COMPANIES_SERVER } from "components/utils/constants/misc";
import { VERSION_SERVER } from "components/utils/constants/misc";

export const getApiKeys = createAsyncThunk(
  "getApiKeys",
  async (values, thunkApi) => {
    try {
      const { _per_page, _page, objUser } = values;
      const res = await axios.get(
        `${BACKEND_API_URL}${VERSION_SERVER}/api-keys/?per_pages=${_per_page}&page=${_page}`
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createApiKeys = createAsyncThunk(
  "createApiKeys",
  async (values, thunkApi) => {
    try {
      const res = await axios.post(
        `${BACKEND_API_URL}${VERSION_SERVER}/api-keys`,
        values
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteApiKey = createAsyncThunk(
  "company/deleteApiKeys",
  async (values, thunkApi) => {
    try {
      const { objUser, keyId } = values;
      const res = await axios.delete(
        `${BACKEND_API_URL}${VERSION_SERVER}/api-keys/${keyId}`,
        {}
      );
      return res;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);